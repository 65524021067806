import React from 'react';
import { connect } from 'react-redux';
import { CurrencyFormatter } from '../lib';
import { RadioButton, Checkbox } from './';

class ModifierList extends React.Component {

    constructor(props) {
        super(props);
        var modifiers = this.props.list.Modifiers.map((m) => {
            var label = m.Name;
            var detail = null;
            if (m.Price) {
                 detail = "+ " + CurrencyFormatter.format(m.Price, this.props.currencyCode);
            }

            return {
                key: m.Id,
                label: label,
                detail: detail,
                checked: false,
                disabled: false
            };
        });
        this.state = {
            modifiers: modifiers,
            expanded: false
        };

        var initial = this.props.initial;
        var overrides = this.props.overrides;
        if (initial) {
            if (initial.length) { 
                this.state.expanded = true;
            }            
            for(var i=0;i<initial.length;i++) {
                var value = initial[i];
                this.onSelect(value, true, false, true);
            }
        } else if (overrides) {
            if (overrides.length) { 
                this.state.expanded = true;
            }
            for(var i=0;i<overrides.length;i++) {
                var override = overrides[i];
                if (override.OnByDefault) {
                    this.onSelect(override.Id, true, true, true);
                }
            }
        }
    }

    onSelect(value, selected, announce, init) {
        // single
        if (this.isRadio()) {
            var a = this.state.modifiers.slice();
            for(var i=0;i<a.length;i++) {
                var n = a[i];
                if (n.key == value) {
                    n.checked = true;
                } else {
                    n.checked = false;
                }
            }
            if (init) {
                this.state.modifiers = a;
            } else {
                this.setState({modifiers: a});
            }
            if (announce) {
                if (this.props.onChange) {
                    this.props.onChange(a.filter((v) => v.checked).map((v) => v.key));
                }
            }
        } else {
            // multiple
            var a = this.state.modifiers.slice();
            for(var i=0;i<a.length;i++) {
                var n = a[i];
                if (n.key == value) {
                    n.checked = selected;
                    break;
                }
            }

            var totalSelected = a.reduce(function(count, value) {
                if (value.checked) {
                    return count + 1;
                }
                    return count;
            }, 0);

            if (this.props.max && this.props.max > 0) {
                var disabled = totalSelected >= this.props.max;
                for (var i=0;i<a.length;i++) {
                    var n = a[i];
                    if (n.checked) {
                        continue;
                    }
                    n.disabled = disabled;
                }
            }
            if (init) {
                this.state.modifiers = a;
            } else {
                this.setState({modifiers: a});            
            }
            if (announce) {
                if (this.props.onChange) {
                    this.props.onChange(a.filter((v) => v.checked).map((v) => v.key));
                }
            }
        }
    }

    isFlaggedSingle() {
        return this.props.list.SelectionType == 0 || this.props.list.SelectionType == 'Single';
    }

    isFlaggedMulti() {
        return this.props.list.SelectionType == 1 || this.props.list.SelectionType == 'Multiple';
    }

    getModifierListSettings(selectionType, min, max, count) {
        var isFlaggedSingle = selectionType == 0 || selectionType == 'Single';
        if (isFlaggedSingle)
            {
                if (min == null && max == null || min == -1 && max == -1 || min == null && max == -1 || min == -1 && max == null)
                {
                    min = 1;
                    max = 1;
                }
                else if (min == -1 && max > 0 || min == null && max > 0)
                {
                    min = 0;
                }
                else if (min == 0 && max == -1 || min == 0 && max == null) {
                    max = count;
                }
                else
                {
                    if (min == null || min == -1)
                        min = 1;
                    if (max == null || max == -1)
                        max = count;
                }
            }
            else
            {
                if (min == null || min == -1)
                    min = 0;

                if (max == null || max == -1)
                    max = count;
            }
        return {
            min: min,
            max: max
        };
    }    

    isRequired() {
        var settings = this.getModifierListSettings(this.props.list.SelectionType, this.props.min, this.props.max, this.state.modifiers.length);
        return settings.min > 0;
    }

    isRadio() {
        var settings = this.getModifierListSettings(this.props.list.SelectionType, this.props.min, this.props.max, this.state.modifiers.length);
        return settings.min == 1 && settings.max == 1;
    }
    
    renderOption(m) {
        if (this.isRadio()) {
            return (<RadioButton right label={m.label} disabled={m.disabled} detail={m.detail} value={m.key} checked={m.checked} onSelect={(value) => { this.onSelect(m.key, true, true, false) }} />);
        }
        return (<Checkbox right label={m.label} disabled={m.disabled} detail={m.detail} value={m.key} checked={m.checked} onCheck={(value) => { this.onSelect(m.key, value, true, false) }} />);
    }

    toggleExpanded() {
        this.setState({expanded: !this.state.expanded});
    }

    render() {
        var modifiers = this.state.modifiers.map((m) => {
            return (
                <li key={m.key}>
                    {this.renderOption(m)}
                </li>
            );
        });

        var requiredLabel = null;
        if (this.isRequired()) {
            requiredLabel = (<span className="req">*</span>);
        }

        var expandIcon = this.state.expanded ? 'expand_less' : 'expand_more';

        var modifierList = this.state.expanded ? (<ul>{modifiers}</ul>) : null;

        return (
            <div>
                <h3>
                    <span>{this.props.list.Name}{requiredLabel}</span>
                    <i onClick={() => this.toggleExpanded()} className='material-icons md-36'>{expandIcon}</i>
                </h3>
                {modifierList}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currencyCode: state.location.currentLocation.Currency
    }
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifierList);