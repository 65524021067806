/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import Location from './Location';





/**
* The Catalog model module.
* @module model/Catalog
* @version 1.0.0
*/
export default class Catalog {
    /**
    * Constructs a new <code>Catalog</code>.
    * @alias module:model/Catalog
    * @class
    * @param hasTextNotifications {Boolean} 
    * @param sortByOrdinal {Boolean} 
    */

    constructor(hasTextNotifications, sortByOrdinal) {
        

        
        

        this['HasTextNotifications'] = hasTextNotifications;this['SortByOrdinal'] = sortByOrdinal;

        
    }

    /**
    * Constructs a <code>Catalog</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Catalog} obj Optional instance to populate.
    * @return {module:model/Catalog} The populated <code>Catalog</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Catalog();

            
            
            

            if (data.hasOwnProperty('Name')) {
                obj['Name'] = ApiClient.convertToType(data['Name'], 'String');
            }
            if (data.hasOwnProperty('AboutContent')) {
                obj['AboutContent'] = ApiClient.convertToType(data['AboutContent'], 'String');
            }
            if (data.hasOwnProperty('HomeHeadline')) {
                obj['HomeHeadline'] = ApiClient.convertToType(data['HomeHeadline'], 'String');
            }
            if (data.hasOwnProperty('HomeSubHeadline')) {
                obj['HomeSubHeadline'] = ApiClient.convertToType(data['HomeSubHeadline'], 'String');
            }
            if (data.hasOwnProperty('ConfirmationNote')) {
                obj['ConfirmationNote'] = ApiClient.convertToType(data['ConfirmationNote'], 'String');
            }
            if (data.hasOwnProperty('WebHomeHeadline')) {
                obj['WebHomeHeadline'] = ApiClient.convertToType(data['WebHomeHeadline'], 'String');
            }
            if (data.hasOwnProperty('HasTextNotifications')) {
                obj['HasTextNotifications'] = ApiClient.convertToType(data['HasTextNotifications'], 'Boolean');
            }
            if (data.hasOwnProperty('SortByOrdinal')) {
                obj['SortByOrdinal'] = ApiClient.convertToType(data['SortByOrdinal'], 'Boolean');
            }
            if (data.hasOwnProperty('PrimaryColorCode')) {
                obj['PrimaryColorCode'] = ApiClient.convertToType(data['PrimaryColorCode'], 'String');
            }
            if (data.hasOwnProperty('Locations')) {
                obj['Locations'] = ApiClient.convertToType(data['Locations'], [Location]);
            }
        }
        return obj;
    }

    /**
    * @member {String} Name
    */
    Name = undefined;
    /**
    * @member {String} AboutContent
    */
    AboutContent = undefined;
    /**
    * @member {String} HomeHeadline
    */
    HomeHeadline = undefined;
    /**
    * @member {String} HomeSubHeadline
    */
    HomeSubHeadline = undefined;
    /**
    * @member {String} ConfirmationNote
    */
    ConfirmationNote = undefined;
    /**
    * @member {String} WebHomeHeadline
    */
    WebHomeHeadline = undefined;
    /**
    * @member {Boolean} HasTextNotifications
    */
    HasTextNotifications = undefined;
    /**
    * @member {Boolean} SortByOrdinal
    */
    SortByOrdinal = undefined;
    /**
    * @member {String} PrimaryColorCode
    */
    PrimaryColorCode = undefined;
    /**
    * @member {Array.<module:model/Location>} Locations
    */
    Locations = undefined;








}


