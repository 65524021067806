/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import OrderLineItemResultModel from './OrderLineItemResultModel';





/**
* The OrderResultModel model module.
* @module model/OrderResultModel
* @version 1.0.0
*/
export default class OrderResultModel {
    /**
    * Constructs a new <code>OrderResultModel</code>.
    * @alias module:model/OrderResultModel
    * @class
    * @param id {String} 
    * @param chargeDateUtc {Date} 
    * @param pickupAtUtc {Date} 
    * @param locationId {String} 
    * @param grandTotal {Number} 
    * @param totalTaxes {Number} 
    * @param totalDiscounts {Number} 
    * @param totalTips {Number} 
    * @param subTotal {Number} 
    */

    constructor(id, chargeDateUtc, pickupAtUtc, locationId, grandTotal, totalTaxes, totalDiscounts, totalTips, subTotal) {
        

        
        

        this['Id'] = id;this['ChargeDateUtc'] = chargeDateUtc;this['PickupAtUtc'] = pickupAtUtc;this['LocationId'] = locationId;this['GrandTotal'] = grandTotal;this['TotalTaxes'] = totalTaxes;this['TotalDiscounts'] = totalDiscounts;this['TotalTips'] = totalTips;this['SubTotal'] = subTotal;

        
    }

    /**
    * Constructs a <code>OrderResultModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/OrderResultModel} obj Optional instance to populate.
    * @return {module:model/OrderResultModel} The populated <code>OrderResultModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderResultModel();

            
            
            

            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('ChargeDateUtc')) {
                obj['ChargeDateUtc'] = ApiClient.convertToType(data['ChargeDateUtc'], 'Date');
            }
            if (data.hasOwnProperty('PickupAtUtc')) {
                obj['PickupAtUtc'] = ApiClient.convertToType(data['PickupAtUtc'], 'Date');
            }
            if (data.hasOwnProperty('LocationId')) {
                obj['LocationId'] = ApiClient.convertToType(data['LocationId'], 'String');
            }
            if (data.hasOwnProperty('FulfillmentType')) {
                obj['FulfillmentType'] = ApiClient.convertToType(data['FulfillmentType'], 'String');
            }
            if (data.hasOwnProperty('GrandTotal')) {
                obj['GrandTotal'] = ApiClient.convertToType(data['GrandTotal'], 'Number');
            }
            if (data.hasOwnProperty('TotalTaxes')) {
                obj['TotalTaxes'] = ApiClient.convertToType(data['TotalTaxes'], 'Number');
            }
            if (data.hasOwnProperty('TotalDiscounts')) {
                obj['TotalDiscounts'] = ApiClient.convertToType(data['TotalDiscounts'], 'Number');
            }
            if (data.hasOwnProperty('TotalTips')) {
                obj['TotalTips'] = ApiClient.convertToType(data['TotalTips'], 'Number');
            }
            if (data.hasOwnProperty('SubTotal')) {
                obj['SubTotal'] = ApiClient.convertToType(data['SubTotal'], 'Number');
            }
            if (data.hasOwnProperty('LineItems')) {
                obj['LineItems'] = ApiClient.convertToType(data['LineItems'], [OrderLineItemResultModel]);
            }
        }
        return obj;
    }

    /**
    * @member {String} Id
    */
    Id = undefined;
    /**
    * @member {Date} ChargeDateUtc
    */
    ChargeDateUtc = undefined;
    /**
    * @member {Date} PickupAtUtc
    */
    PickupAtUtc = undefined;
    /**
    * @member {String} LocationId
    */
    LocationId = undefined;
    /**
    * @member {String} FulfillmentType
    */
    FulfillmentType = undefined;
    /**
    * @member {Number} GrandTotal
    */
    GrandTotal = undefined;
    /**
    * @member {Number} TotalTaxes
    */
    TotalTaxes = undefined;
    /**
    * @member {Number} TotalDiscounts
    */
    TotalDiscounts = undefined;
    /**
    * @member {Number} TotalTips
    */
    TotalTips = undefined;
    /**
    * @member {Number} SubTotal
    */
    SubTotal = undefined;
    /**
    * @member {Array.<module:model/OrderLineItemResultModel>} LineItems
    */
    LineItems = undefined;








}


