import moment from 'moment-timezone';

const initialState = {
    orders: [],
    lastTick: null
};

function formatNewOrder(action) {

    var historyItems = [];
    action.items.forEach((entry) => {
        historyItems.push(entry.item);
    });

    return { 
        Key: action.orderId, 
        Location: action.location.Id,
        Name: action.date.format("MMMM Do h:mm a"), 
        Items: historyItems 
    };
}

export default function history(state, action) {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'ORDER_PLACED':
            var orders = [...state.orders];
            orders.unshift(action.data);
            return {
                ...state,
                orders: orders,
                lastTick: moment()
            };
        case 'ORDERS_INITIALIZED':
            return {
                ...state,
                orders: action.data.Orders,
                lastTick: moment()
            }
        case 'CART_TIMER_TICK':
            return {
                ...state,
                lastTick: moment()
            }
        default:
            return state;
    }  
}