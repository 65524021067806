import React from 'react';
import { connect } from 'react-redux';
import { CurrencyFormatter, Internationalization, SortService } from '../lib';
import { ModifierList } from './';
import { default as uuid } from 'uuid/v4';

class ItemSidebarModal extends React.Component {
    onCloseClicked() {
        this.props.clearSelectedItem();
    }

    onPlusPressed() {
        var quantity = this.props.quantity + 1;
        this.props.setQuantity(quantity);
    }

    onMinusPressed() {
        var quantity = this.props.quantity - 1;
        if (this.props.isEditMode) {
            if (quantity < 0)
                quantity = 0;
        } else {
            if (quantity <= 0)
                quantity = 1;
        }
        this.props.setQuantity(quantity);
    }    

    onNoteChanged(e) {
        this.props.setNote(e.target.value);
    }

    onModifierListChange(modifierList, selectedModifiers) {
        this.props.selectModifiers(modifierList, selectedModifiers);
    }

    onVariationChange(variationModel, selectedVariations) {
        var selectedVariation = selectedVariations && selectedVariations.length > 0 ? selectedVariations[0] : null;
        this.props.selectVariation(selectedVariation);
    }

    getSortedItemModifierLists(item) {
        var map = this.props.modifierListMap;
        return SortService.instance.sortItemModifierLists(item.ModifierLists, map, this.props.catalog);
    }

    renderModifierLists() {
        var item = this.props.itemDetail;

        var variationSelection = null;
        if (item.Variations && item.Variations.length > 1) {
            var variationModel = {
                Name: Internationalization.strings('item_sidebar_modal.select_variation_label'),
                SelectionType: 0,
                Modifiers: []
            };

            var variations = SortService.instance.sortVariations(item.Variations, this.props.catalog);

            for(var i=0;i<variations.length;i++){
                var variation = variations[i];
                variationModel.Modifiers.push({
                    Id: variation.Id,
                    Name: variation.Name,
                    Price: variation.Price
                });
            }
            variationSelection = (
                <ModifierList 
                    list={variationModel}
                    min={1} 
                    max={1} 
                    initial={[this.props.initial.selectedVariation]}
                    onChange={(selected => this.onVariationChange(variationModel, selected))} />
            );
        }

        var map = this.props.modifierListMap;
        var initialMap = this.props.initial.selectedModifiers;

        var modifierListViews = this.getSortedItemModifierLists(item).map((itemModifierList) => {
            var modifierList = map.get(itemModifierList.Id);
            var initialValues = initialMap[modifierList.Id];

            modifierList.Modifiers = SortService.instance.sortModifiers(modifierList.Modifiers, this.props.catalog);
            
            return (
                <ModifierList 
                    key={modifierList.Id}
                    list={modifierList}
                    min={itemModifierList.MinSelectedModifiers} 
                    max={itemModifierList.MaxSelectedModifiers} 
                    overrides={itemModifierList.Modifiers}
                    initial={initialValues}
                    onChange={(selected => this.onModifierListChange(modifierList, selected))} />
            );
        });

        return (
            <div>
                {variationSelection}
                {modifierListViews}
            </div>
        )
    }

    getModifierListSettings(selectionType, min, max, count) {
        var isFlaggedSingle = selectionType == 0 || selectionType == 'Single';
        if (isFlaggedSingle)
            {
                if (min == null && max == null || min == -1 && max == -1 || min == null && max == -1 || min == -1 && max == null)
                {
                    min = 1;
                    max = 1;
                }
                else if (min == -1 && max > 0 || min == null && max > 0)
                {
                    min = 0;
                }
                else if (min == 0 && max == -1 || min == 0 && max == null) {
                    max = count;
                }
                else
                {
                    if (min == null || min == -1)
                        min = 1;
                    if (max == null || max == -1)
                        max = count;
                }
            }
            else
            {
                if (min == null || min == -1)
                    min = 0;

                if (max == null || max == -1)
                    max = count;
            }
        return {
            min: min,
            max: max
        };
    }

    validate() {
        var item = this.props.itemDetail;
        // must have a selected variation
        // each modifier list that has a selectionType of 0 must have a value (radio)
        // each modifier list that has a selectionType of 1 and a min selected modifiers must have at least that many selected (checkbox)
        // each modifier list that has a selectionType of 1 and a max selected modifiers must have less then that many selected (checkbox)

        if (!this.props.selectedVariation) {
            return Internationalization.strings('item_sidebar_modal.variation_required_error');
        }

        var map = this.props.modifierListMap;

        var itemModifierLists = this.getSortedItemModifierLists(item);
        for(var i=0;i<itemModifierLists.length;i++) {
            var itemModifierList = itemModifierLists[i];

            var modifierList = map.get(itemModifierList.Id);

            var selectedModifiers = this.props.selectedModifiers[modifierList.Id] || [];

            var min = itemModifierList.MinSelectedModifiers;
            var max = itemModifierList.MaxSelectedModifiers;            

            var actual = selectedModifiers.length;

            var settings = this.getModifierListSettings(modifierList.SelectionType, min, max, modifierList.Modifiers.length);
            if (settings.min == 1 && settings.max == 1 && actual == 0) {
                return Internationalization.strings('item_sidebar_modal.modifier_list_is_required_error', {name: modifierList.Name });
            } else if (settings.min > 0 && actual < settings.min) {
                return Internationalization.strings('item_sidebar_modal.modifier_list_minimum_error', {count: min, name: modifierList.Name});
            } else if (settings.max > 0 && actual > settings.max) {
                return Internationalization.strings('item_sidebar_modal.modifier_list_minimum_error', {count: max, name: modifierList.Name});
            }
        }

        return null;
    }

    isValid() {
        var errorMessage = this.validate();
        if (errorMessage) {
            return false;
        }
        return true;
    }

    _updateCart() {
        var errorMessage = this.validate();
        if (errorMessage) {
            this.props.toast.error(errorMessage);
            return;
        }
        
        var item = this.props.itemDetail;
        
        // update the cart
        var cartItem = {
            key: this.props.initial.key,
            item: item.Id,
            quantity: this.props.quantity,
            variation: this.props.selectedVariation,
            modifiers: Object.assign({}, this.props.selectedModifiers),
            note: this.props.note
        };

        this.props.update(cartItem);
        this.props.clearSelectedItem();
    }    

    _addToCart() {
        var errorMessage = this.validate();
        if (errorMessage) {
            this.props.toast.error(errorMessage);
            return;
        }
        var item = this.props.itemDetail;

        var cartItem = {
            key: uuid(),
            item: item.Id,
            quantity: this.props.quantity,
            variation: this.props.selectedVariation,
            modifiers: Object.assign({}, this.props.selectedModifiers),
            note: this.props.note
        };
        this.props.add(cartItem);    
        this.props.clearSelectedItem();
        this.props.toast.success(Internationalization.strings('item_sidebar_modal.added_to_cart'));
    }    

    renderActionButton() {
        var isDisabled = false;
        if (!this.isValid())
            isDisabled = true;

        var actionButtonLabel = null;
        var actionButtonPress = null;
        var actionButtonClasses = ['btn', 'submit-item-to-bag'];
        if (this.props.isEditMode) {
            actionButtonLabel = Internationalization.strings('item_sidebar_modal.action_button.update');
            if (this.props.quantity == 0) {
                actionButtonLabel = Internationalization.strings('item_sidebar_modal.action_button.remove');
                if (!isDisabled) {
                actionButtonClasses.push('btn-danger');
                }
            } else {
                if (!isDisabled) {
                    actionButtonClasses.push('btn-primary');
                }
            }
            actionButtonPress = () => this._updateCart();
        } else {
            actionButtonLabel = Internationalization.strings('item_sidebar_modal.action_button.add');
            if (!isDisabled) {
                actionButtonClasses.push('btn-primary');
            }
            actionButtonPress = () => this._addToCart();
        }

        if (isDisabled) {
            actionButtonPress = () => {};
            actionButtonClasses.push('btn-inactive');
        }

        return (
            <div className="floating-button">
                <button onClick={actionButtonPress} type="submit" className={actionButtonClasses.join(' ')}>{actionButtonLabel}</button>
            </div>
        );
    }

    render() {
        var item = this.props.itemDetail;

        if (!item || !item.Id) {
            return (<div></div>);
        }

        var imageSource = null;
        if (item.ImageUrl) {
            imageSource = item.ImageUrl;
        } else {
            imageSource = global.imagesPath + 'item-placeholder.jpg';
        }        

        var priceLabel = this.props.estimatedCost; 

        return (
        <div className="product-details-sidebar-modal" style={{display: 'inherit'}}>
            <div className="inner">
                <a href="javascript://" onClick={() => this.onCloseClicked()} className="close"><i className="material-icons md-36">close</i></a>
                <div className="title-and-price">
                    <h2>{item.Name}</h2>
                    <div className="price">{priceLabel}</div>
                </div>
                <div className="photo"><img src={imageSource} alt={item.Name} /></div>
                <div className="description"><p>{item.Description}</p></div>
                <div className="disclaimer">Sections marked with an asterisk are required.</div>
                <div className="extras">
                    {this.renderModifierLists()}
                </div>
                <section className="notes">
                    <h3>{Internationalization.strings('item_sidebar_modal.additional_note_placeholder')}</h3>
                    <textarea maxLength={50} value={this.props.note || ''} onChange={(text) => this.onNoteChanged(text)} placeholder="Please type any extra notes you may have..."></textarea>
                </section>
                <section className="qty">
                    <h3>Quantity</h3>
                    <div className="actions">
                        <a href="javascript://" onClick={() => this.onMinusPressed()} className="subtract"><i className="material-icons md-24">remove</i></a>
                        <div className="qty-num">{this.props.quantity}</div>
                        <a href="javascript://" onClick={() => this.onPlusPressed()} className="add"><i className="material-icons md-24">add</i></a>
                    </div>
                </section>
                <section className="total">{priceLabel}</section>
                {this.renderActionButton()}
            </div>
        </div>        
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modifierListMap: state.selectedItem.modifierListMap,
        modifierMap: state.selectedItem.modifierMap,
        variationMap: state.selectedItem.variationMap,
        minVariation: state.selectedItem.minVariation,
        maxVariation: state.selectedItem.maxVariation,
        initial: state.selectedItem.initial,
        itemDetail: state.selectedItem.detail,
        selectedVariation: state.selectedItem.selectedVariation,
        selectedModifiers: state.selectedItem.selectedModifiers,
        quantity: state.selectedItem.quantity,
        note: state.selectedItem.note,
        estimatedCost: state.selectedItem.estimatedCost,
        isEditMode: state.selectedItem.isEditMode,

        catalog: state.catalog.data,
    }
};

const mapDispatchToProps = (dispatch) => ({
    clearSelectedItem: () => {
        dispatch({type: 'CATALOG_SELECT_ITEM', item: {
            Id: null,
            Variations: [],
        }});
    },

    selectModifiers: (modifierList, selectedModifiers) => {
        dispatch({type:'CATALOG_SELECT_MODIFIERS', modifierList: modifierList, selectedModifiers: selectedModifiers});
    },
    selectVariation: (selectedVariation) => {
        dispatch({type:'CATALOG_SELECT_VARIATION', selectedVariation: selectedVariation });
    },
    setQuantity: (quantity) => {
        dispatch({type:'CATALOG_SET_QUANTITY', quantity: quantity});
    },
    setNote: (note) => {
        dispatch({type:'CATALOG_SET_NOTE', note: note});
    },
    update: (cartItem) => {
        dispatch({type: 'CART_UPDATE', cartItem: cartItem});
    },
    add: (cartItem) => {
        dispatch({type: 'CART_ADD', cartItem: cartItem});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemSidebarModal);