const initialState = {
    enrolled: false,
    stars: 0,
    required: 0,
    balance: 0,
    coupons: [],
    rewards: [],
    available: false,
    terminology: {
        one: "Point",
        other: "Points"
    }
};
  
function parseTerminology(remote) {
    if (!remote) {
        return {
            one: "Point",
            other: "Points"
        };
    }

    return {
        one: remote.One,
        other: remote.Other
    };
}

export default function loyalty(state, action) {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'LOYALTY_INITIALIZED':
            return {
                enrolled: action.data.Enrolled || false,
                stars: action.data.Balance || 0,
                required: action.data.Balance || 0,
                balance: action.data.Balance || 0,
                coupons: action.data.Coupons || [],
                rewards: action.data.Rewards || [],
                available: action.data.Available || false,
                terminology: parseTerminology(action.data.Terminology),
            };
        case 'LOYALTY_VERIFIED':
            return {
                ...state,
                enrolled: true,
                stars: action.data.Balance || 0,
                required: action.data.Balance || 0,
                balance: action.data.Balance || 0,
                coupons: action.data.Coupons || [],
                rewards: action.data.Rewards || [],
                terminology: parseTerminology(action.data.Terminology),
            };
        default:
            return state;
    }  
}