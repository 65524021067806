/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from './ApiClient';
import Address from './model/Address';
import AddressModel from './model/AddressModel';
import BaseOrderPlaceBindingModel from './model/BaseOrderPlaceBindingModel';
import CalculationPhase from './model/CalculationPhase';
import CardBindingModel from './model/CardBindingModel';
import CardResultModel from './model/CardResultModel';
import Catalog from './model/Catalog';
import CatalogIndexBindingModel from './model/CatalogIndexBindingModel';
import Category from './model/Category';
import ChangePasswordBindingModel from './model/ChangePasswordBindingModel';
import CreateFavoriteBindingModel from './model/CreateFavoriteBindingModel';
import CurbsideFulfillmentOrderSettings from './model/CurbsideFulfillmentOrderSettings';
import CurbsideSettings from './model/CurbsideSettings';
import CurbsideVehicleDetails from './model/CurbsideVehicleDetails';
import DeliveryAddressCreateModel from './model/DeliveryAddressCreateModel';
import DeliveryAddressCreateResultModel from './model/DeliveryAddressCreateResultModel';
import DeliveryAddressDeleteModel from './model/DeliveryAddressDeleteModel';
import DeliveryAddressDeleteResultModel from './model/DeliveryAddressDeleteResultModel';
import DeliveryAddressListModel from './model/DeliveryAddressListModel';
import DeliveryAddressListResultModel from './model/DeliveryAddressListResultModel';
import DeliveryAddressModel from './model/DeliveryAddressModel';
import DeliveryAddressUpdateModel from './model/DeliveryAddressUpdateModel';
import DeliveryAddressUpdateResultModel from './model/DeliveryAddressUpdateResultModel';
import DeliveryAddressValidateModel from './model/DeliveryAddressValidateModel';
import DeliveryAddressValidateResultModel from './model/DeliveryAddressValidateResultModel';
import DeliveryAddressValidationResult from './model/DeliveryAddressValidationResult';
import DeliveryFulfillmentOrderSettings from './model/DeliveryFulfillmentOrderSettings';
import DeliveryQuoteModel from './model/DeliveryQuoteModel';
import DeliveryQuoteResultModel from './model/DeliveryQuoteResultModel';
import DeliverySettings from './model/DeliverySettings';
import ForgotPasswordModel from './model/ForgotPasswordModel';
import FulfillmentSettings from './model/FulfillmentSettings';
import GuestBindingModel from './model/GuestBindingModel';
import ImHerePresenceBindingModel from './model/ImHerePresenceBindingModel';
import ImHerePresenceResultModel from './model/ImHerePresenceResultModel';
import InclusionType from './model/InclusionType';
import InlineResponse200 from './model/InlineResponse200';
import InlineResponse400 from './model/InlineResponse400';
import Item from './model/Item';
import ItemModifier from './model/ItemModifier';
import ItemModifierList from './model/ItemModifierList';
import LineItemBindingModel from './model/LineItemBindingModel';
import ListCardBindingModel from './model/ListCardBindingModel';
import ListCardResultModel from './model/ListCardResultModel';
import ListFavoriteBindingModel from './model/ListFavoriteBindingModel';
import ListFavoriteResultModel from './model/ListFavoriteResultModel';
import Location from './model/Location';
import LocationContact from './model/LocationContact';
import LocationHours from './model/LocationHours';
import LoyaltyCheckAvailabilityResultModel from './model/LoyaltyCheckAvailabilityResultModel';
import LoyaltyCouponModel from './model/LoyaltyCouponModel';
import LoyaltyCouponType from './model/LoyaltyCouponType';
import LoyaltyEnrollBindingModel from './model/LoyaltyEnrollBindingModel';
import LoyaltyEnrollResultModel from './model/LoyaltyEnrollResultModel';
import LoyaltyRewardModel from './model/LoyaltyRewardModel';
import LoyaltyRewardType from './model/LoyaltyRewardType';
import LoyaltyStatusBindingModel from './model/LoyaltyStatusBindingModel';
import LoyaltyStatusResultModel from './model/LoyaltyStatusResultModel';
import LoyaltyTerminologyModel from './model/LoyaltyTerminologyModel';
import LoyaltyVerifyBindingModel from './model/LoyaltyVerifyBindingModel';
import LoyaltyVerifyResultModel from './model/LoyaltyVerifyResultModel';
import MergeCardBindingModel from './model/MergeCardBindingModel';
import MergeFavoriteBindingModel from './model/MergeFavoriteBindingModel';
import MergeFavoriteResultModel from './model/MergeFavoriteResultModel';
import ModelStateError from './model/ModelStateError';
import ModelStateErrorCollection from './model/ModelStateErrorCollection';
import Modifier from './model/Modifier';
import ModifierList from './model/ModifierList';
import OrderGuestPlaceResultModel from './model/OrderGuestPlaceResultModel';
import OrderGuestQueueResultModel from './model/OrderGuestQueueResultModel';
import OrderGuestStatusBindingModel from './model/OrderGuestStatusBindingModel';
import OrderLineItemModifierResultModel from './model/OrderLineItemModifierResultModel';
import OrderLineItemResultModel from './model/OrderLineItemResultModel';
import OrderListBindingModel from './model/OrderListBindingModel';
import OrderListResultModel from './model/OrderListResultModel';
import OrderPlaceResultModel from './model/OrderPlaceResultModel';
import OrderQueueResultModel from './model/OrderQueueResultModel';
import OrderResultModel from './model/OrderResultModel';
import OrderStatusBindingModel from './model/OrderStatusBindingModel';
import OrderStatusErrorModel from './model/OrderStatusErrorModel';
import OrderStatusResultModel from './model/OrderStatusResultModel';
import PaymentSettings from './model/PaymentSettings';
import PickupFulfillmentOrderSettings from './model/PickupFulfillmentOrderSettings';
import PickupSettings from './model/PickupSettings';
import RegisterBindingModel from './model/RegisterBindingModel';
import RemoveCardBindingModel from './model/RemoveCardBindingModel';
import RemoveFavoriteBindingModel from './model/RemoveFavoriteBindingModel';
import ResendEnrollCodeBindingModel from './model/ResendEnrollCodeBindingModel';
import ResendRegisterCodeBindingModel from './model/ResendRegisterCodeBindingModel';
import ResetPasswordModel from './model/ResetPasswordModel';
import SaveCardBindingModel from './model/SaveCardBindingModel';
import SaveCardResultModel from './model/SaveCardResultModel';
import SelectionType from './model/SelectionType';
import StatusBindingModel from './model/StatusBindingModel';
import StatusResultModel from './model/StatusResultModel';
import Tax from './model/Tax';
import TipSettings from './model/TipSettings';
import UpdateBindingModel from './model/UpdateBindingModel';
import Variation from './model/Variation';
import VerifyRegisterModel from './model/VerifyRegisterModel';
import WebHookSquareBindingModel from './model/WebHookSquareBindingModel';
import BaseOrderGuestPlaceBindingModel from './model/BaseOrderGuestPlaceBindingModel';
import OrderPlaceBindingModel from './model/OrderPlaceBindingModel';
import OrderQueueBindingModel from './model/OrderQueueBindingModel';
import OrderGuestPlaceBindingModel from './model/OrderGuestPlaceBindingModel';
import OrderGuestQueueBindingModel from './model/OrderGuestQueueBindingModel';
import AccountApi from './api/AccountApi';
import CardApi from './api/CardApi';
import CatalogApi from './api/CatalogApi';
import DeliveryApi from './api/DeliveryApi';
import FavoriteApi from './api/FavoriteApi';
import LoyaltyApi from './api/LoyaltyApi';
import OrderApi from './api/OrderApi';
import PresenceApi from './api/PresenceApi';
import WebHookApi from './api/WebHookApi';


/**
* ERROR_UNKNOWN.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var PoppinPayApi = require('index'); // See note below*.
* var xxxSvc = new PoppinPayApi.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new PoppinPayApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new PoppinPayApi.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new PoppinPayApi.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version 1.0.0
*/
export {
    /**
     * The ApiClient constructor.
     * @property {module:ApiClient}
     */
    ApiClient,

    /**
     * The Address model constructor.
     * @property {module:model/Address}
     */
    Address,

    /**
     * The AddressModel model constructor.
     * @property {module:model/AddressModel}
     */
    AddressModel,

    /**
     * The BaseOrderPlaceBindingModel model constructor.
     * @property {module:model/BaseOrderPlaceBindingModel}
     */
    BaseOrderPlaceBindingModel,

    /**
     * The CalculationPhase model constructor.
     * @property {module:model/CalculationPhase}
     */
    CalculationPhase,

    /**
     * The CardBindingModel model constructor.
     * @property {module:model/CardBindingModel}
     */
    CardBindingModel,

    /**
     * The CardResultModel model constructor.
     * @property {module:model/CardResultModel}
     */
    CardResultModel,

    /**
     * The Catalog model constructor.
     * @property {module:model/Catalog}
     */
    Catalog,

    /**
     * The CatalogIndexBindingModel model constructor.
     * @property {module:model/CatalogIndexBindingModel}
     */
    CatalogIndexBindingModel,

    /**
     * The Category model constructor.
     * @property {module:model/Category}
     */
    Category,

    /**
     * The ChangePasswordBindingModel model constructor.
     * @property {module:model/ChangePasswordBindingModel}
     */
    ChangePasswordBindingModel,

    /**
     * The CreateFavoriteBindingModel model constructor.
     * @property {module:model/CreateFavoriteBindingModel}
     */
    CreateFavoriteBindingModel,

    /**
     * The CurbsideFulfillmentOrderSettings model constructor.
     * @property {module:model/CurbsideFulfillmentOrderSettings}
     */
    CurbsideFulfillmentOrderSettings,

    /**
     * The CurbsideSettings model constructor.
     * @property {module:model/CurbsideSettings}
     */
    CurbsideSettings,

    /**
     * The CurbsideVehicleDetails model constructor.
     * @property {module:model/CurbsideVehicleDetails}
     */
    CurbsideVehicleDetails,

    /**
     * The DeliveryAddressCreateModel model constructor.
     * @property {module:model/DeliveryAddressCreateModel}
     */
    DeliveryAddressCreateModel,

    /**
     * The DeliveryAddressCreateResultModel model constructor.
     * @property {module:model/DeliveryAddressCreateResultModel}
     */
    DeliveryAddressCreateResultModel,

    /**
     * The DeliveryAddressDeleteModel model constructor.
     * @property {module:model/DeliveryAddressDeleteModel}
     */
    DeliveryAddressDeleteModel,

    /**
     * The DeliveryAddressDeleteResultModel model constructor.
     * @property {module:model/DeliveryAddressDeleteResultModel}
     */
    DeliveryAddressDeleteResultModel,

    /**
     * The DeliveryAddressListModel model constructor.
     * @property {module:model/DeliveryAddressListModel}
     */
    DeliveryAddressListModel,

    /**
     * The DeliveryAddressListResultModel model constructor.
     * @property {module:model/DeliveryAddressListResultModel}
     */
    DeliveryAddressListResultModel,

    /**
     * The DeliveryAddressModel model constructor.
     * @property {module:model/DeliveryAddressModel}
     */
    DeliveryAddressModel,

    /**
     * The DeliveryAddressUpdateModel model constructor.
     * @property {module:model/DeliveryAddressUpdateModel}
     */
    DeliveryAddressUpdateModel,

    /**
     * The DeliveryAddressUpdateResultModel model constructor.
     * @property {module:model/DeliveryAddressUpdateResultModel}
     */
    DeliveryAddressUpdateResultModel,

    /**
     * The DeliveryAddressValidateModel model constructor.
     * @property {module:model/DeliveryAddressValidateModel}
     */
    DeliveryAddressValidateModel,

    /**
     * The DeliveryAddressValidateResultModel model constructor.
     * @property {module:model/DeliveryAddressValidateResultModel}
     */
    DeliveryAddressValidateResultModel,

    /**
     * The DeliveryAddressValidationResult model constructor.
     * @property {module:model/DeliveryAddressValidationResult}
     */
    DeliveryAddressValidationResult,

    /**
     * The DeliveryFulfillmentOrderSettings model constructor.
     * @property {module:model/DeliveryFulfillmentOrderSettings}
     */
    DeliveryFulfillmentOrderSettings,

    /**
     * The DeliveryQuoteModel model constructor.
     * @property {module:model/DeliveryQuoteModel}
     */
    DeliveryQuoteModel,

    /**
     * The DeliveryQuoteResultModel model constructor.
     * @property {module:model/DeliveryQuoteResultModel}
     */
    DeliveryQuoteResultModel,

    /**
     * The DeliverySettings model constructor.
     * @property {module:model/DeliverySettings}
     */
    DeliverySettings,

    /**
     * The ForgotPasswordModel model constructor.
     * @property {module:model/ForgotPasswordModel}
     */
    ForgotPasswordModel,

    /**
     * The FulfillmentSettings model constructor.
     * @property {module:model/FulfillmentSettings}
     */
    FulfillmentSettings,

    /**
     * The GuestBindingModel model constructor.
     * @property {module:model/GuestBindingModel}
     */
    GuestBindingModel,

    /**
     * The ImHerePresenceBindingModel model constructor.
     * @property {module:model/ImHerePresenceBindingModel}
     */
    ImHerePresenceBindingModel,

    /**
     * The ImHerePresenceResultModel model constructor.
     * @property {module:model/ImHerePresenceResultModel}
     */
    ImHerePresenceResultModel,

    /**
     * The InclusionType model constructor.
     * @property {module:model/InclusionType}
     */
    InclusionType,

    /**
     * The InlineResponse200 model constructor.
     * @property {module:model/InlineResponse200}
     */
    InlineResponse200,

    /**
     * The InlineResponse400 model constructor.
     * @property {module:model/InlineResponse400}
     */
    InlineResponse400,

    /**
     * The Item model constructor.
     * @property {module:model/Item}
     */
    Item,

    /**
     * The ItemModifier model constructor.
     * @property {module:model/ItemModifier}
     */
    ItemModifier,

    /**
     * The ItemModifierList model constructor.
     * @property {module:model/ItemModifierList}
     */
    ItemModifierList,

    /**
     * The LineItemBindingModel model constructor.
     * @property {module:model/LineItemBindingModel}
     */
    LineItemBindingModel,

    /**
     * The ListCardBindingModel model constructor.
     * @property {module:model/ListCardBindingModel}
     */
    ListCardBindingModel,

    /**
     * The ListCardResultModel model constructor.
     * @property {module:model/ListCardResultModel}
     */
    ListCardResultModel,

    /**
     * The ListFavoriteBindingModel model constructor.
     * @property {module:model/ListFavoriteBindingModel}
     */
    ListFavoriteBindingModel,

    /**
     * The ListFavoriteResultModel model constructor.
     * @property {module:model/ListFavoriteResultModel}
     */
    ListFavoriteResultModel,

    /**
     * The Location model constructor.
     * @property {module:model/Location}
     */
    Location,

    /**
     * The LocationContact model constructor.
     * @property {module:model/LocationContact}
     */
    LocationContact,

    /**
     * The LocationHours model constructor.
     * @property {module:model/LocationHours}
     */
    LocationHours,

    /**
     * The LoyaltyCheckAvailabilityResultModel model constructor.
     * @property {module:model/LoyaltyCheckAvailabilityResultModel}
     */
    LoyaltyCheckAvailabilityResultModel,

    /**
     * The LoyaltyCouponModel model constructor.
     * @property {module:model/LoyaltyCouponModel}
     */
    LoyaltyCouponModel,

    /**
     * The LoyaltyCouponType model constructor.
     * @property {module:model/LoyaltyCouponType}
     */
    LoyaltyCouponType,

    /**
     * The LoyaltyEnrollBindingModel model constructor.
     * @property {module:model/LoyaltyEnrollBindingModel}
     */
    LoyaltyEnrollBindingModel,

    /**
     * The LoyaltyEnrollResultModel model constructor.
     * @property {module:model/LoyaltyEnrollResultModel}
     */
    LoyaltyEnrollResultModel,

    /**
     * The LoyaltyRewardModel model constructor.
     * @property {module:model/LoyaltyRewardModel}
     */
    LoyaltyRewardModel,

    /**
     * The LoyaltyRewardType model constructor.
     * @property {module:model/LoyaltyRewardType}
     */
    LoyaltyRewardType,

    /**
     * The LoyaltyStatusBindingModel model constructor.
     * @property {module:model/LoyaltyStatusBindingModel}
     */
    LoyaltyStatusBindingModel,

    /**
     * The LoyaltyStatusResultModel model constructor.
     * @property {module:model/LoyaltyStatusResultModel}
     */
    LoyaltyStatusResultModel,

    /**
     * The LoyaltyTerminologyModel model constructor.
     * @property {module:model/LoyaltyTerminologyModel}
     */
    LoyaltyTerminologyModel,

    /**
     * The LoyaltyVerifyBindingModel model constructor.
     * @property {module:model/LoyaltyVerifyBindingModel}
     */
    LoyaltyVerifyBindingModel,

    /**
     * The LoyaltyVerifyResultModel model constructor.
     * @property {module:model/LoyaltyVerifyResultModel}
     */
    LoyaltyVerifyResultModel,

    /**
     * The MergeCardBindingModel model constructor.
     * @property {module:model/MergeCardBindingModel}
     */
    MergeCardBindingModel,

    /**
     * The MergeFavoriteBindingModel model constructor.
     * @property {module:model/MergeFavoriteBindingModel}
     */
    MergeFavoriteBindingModel,

    /**
     * The MergeFavoriteResultModel model constructor.
     * @property {module:model/MergeFavoriteResultModel}
     */
    MergeFavoriteResultModel,

    /**
     * The ModelStateError model constructor.
     * @property {module:model/ModelStateError}
     */
    ModelStateError,

    /**
     * The ModelStateErrorCollection model constructor.
     * @property {module:model/ModelStateErrorCollection}
     */
    ModelStateErrorCollection,

    /**
     * The Modifier model constructor.
     * @property {module:model/Modifier}
     */
    Modifier,

    /**
     * The ModifierList model constructor.
     * @property {module:model/ModifierList}
     */
    ModifierList,

    /**
     * The OrderGuestPlaceResultModel model constructor.
     * @property {module:model/OrderGuestPlaceResultModel}
     */
    OrderGuestPlaceResultModel,

    /**
     * The OrderGuestQueueResultModel model constructor.
     * @property {module:model/OrderGuestQueueResultModel}
     */
    OrderGuestQueueResultModel,

    /**
     * The OrderGuestStatusBindingModel model constructor.
     * @property {module:model/OrderGuestStatusBindingModel}
     */
    OrderGuestStatusBindingModel,

    /**
     * The OrderLineItemModifierResultModel model constructor.
     * @property {module:model/OrderLineItemModifierResultModel}
     */
    OrderLineItemModifierResultModel,

    /**
     * The OrderLineItemResultModel model constructor.
     * @property {module:model/OrderLineItemResultModel}
     */
    OrderLineItemResultModel,

    /**
     * The OrderListBindingModel model constructor.
     * @property {module:model/OrderListBindingModel}
     */
    OrderListBindingModel,

    /**
     * The OrderListResultModel model constructor.
     * @property {module:model/OrderListResultModel}
     */
    OrderListResultModel,

    /**
     * The OrderPlaceResultModel model constructor.
     * @property {module:model/OrderPlaceResultModel}
     */
    OrderPlaceResultModel,

    /**
     * The OrderQueueResultModel model constructor.
     * @property {module:model/OrderQueueResultModel}
     */
    OrderQueueResultModel,

    /**
     * The OrderResultModel model constructor.
     * @property {module:model/OrderResultModel}
     */
    OrderResultModel,

    /**
     * The OrderStatusBindingModel model constructor.
     * @property {module:model/OrderStatusBindingModel}
     */
    OrderStatusBindingModel,

    /**
     * The OrderStatusErrorModel model constructor.
     * @property {module:model/OrderStatusErrorModel}
     */
    OrderStatusErrorModel,

    /**
     * The OrderStatusResultModel model constructor.
     * @property {module:model/OrderStatusResultModel}
     */
    OrderStatusResultModel,

    /**
     * The PaymentSettings model constructor.
     * @property {module:model/PaymentSettings}
     */
    PaymentSettings,

    /**
     * The PickupFulfillmentOrderSettings model constructor.
     * @property {module:model/PickupFulfillmentOrderSettings}
     */
    PickupFulfillmentOrderSettings,

    /**
     * The PickupSettings model constructor.
     * @property {module:model/PickupSettings}
     */
    PickupSettings,

    /**
     * The RegisterBindingModel model constructor.
     * @property {module:model/RegisterBindingModel}
     */
    RegisterBindingModel,

    /**
     * The RemoveCardBindingModel model constructor.
     * @property {module:model/RemoveCardBindingModel}
     */
    RemoveCardBindingModel,

    /**
     * The RemoveFavoriteBindingModel model constructor.
     * @property {module:model/RemoveFavoriteBindingModel}
     */
    RemoveFavoriteBindingModel,

    /**
     * The ResendEnrollCodeBindingModel model constructor.
     * @property {module:model/ResendEnrollCodeBindingModel}
     */
    ResendEnrollCodeBindingModel,

    /**
     * The ResendRegisterCodeBindingModel model constructor.
     * @property {module:model/ResendRegisterCodeBindingModel}
     */
    ResendRegisterCodeBindingModel,

    /**
     * The ResetPasswordModel model constructor.
     * @property {module:model/ResetPasswordModel}
     */
    ResetPasswordModel,

    /**
     * The SaveCardBindingModel model constructor.
     * @property {module:model/SaveCardBindingModel}
     */
    SaveCardBindingModel,

    /**
     * The SaveCardResultModel model constructor.
     * @property {module:model/SaveCardResultModel}
     */
    SaveCardResultModel,

    /**
     * The SelectionType model constructor.
     * @property {module:model/SelectionType}
     */
    SelectionType,

    /**
     * The StatusBindingModel model constructor.
     * @property {module:model/StatusBindingModel}
     */
    StatusBindingModel,

    /**
     * The StatusResultModel model constructor.
     * @property {module:model/StatusResultModel}
     */
    StatusResultModel,

    /**
     * The Tax model constructor.
     * @property {module:model/Tax}
     */
    Tax,

    /**
     * The TipSettings model constructor.
     * @property {module:model/TipSettings}
     */
    TipSettings,

    /**
     * The UpdateBindingModel model constructor.
     * @property {module:model/UpdateBindingModel}
     */
    UpdateBindingModel,

    /**
     * The Variation model constructor.
     * @property {module:model/Variation}
     */
    Variation,

    /**
     * The VerifyRegisterModel model constructor.
     * @property {module:model/VerifyRegisterModel}
     */
    VerifyRegisterModel,

    /**
     * The WebHookSquareBindingModel model constructor.
     * @property {module:model/WebHookSquareBindingModel}
     */
    WebHookSquareBindingModel,

    /**
     * The BaseOrderGuestPlaceBindingModel model constructor.
     * @property {module:model/BaseOrderGuestPlaceBindingModel}
     */
    BaseOrderGuestPlaceBindingModel,

    /**
     * The OrderPlaceBindingModel model constructor.
     * @property {module:model/OrderPlaceBindingModel}
     */
    OrderPlaceBindingModel,

    /**
     * The OrderQueueBindingModel model constructor.
     * @property {module:model/OrderQueueBindingModel}
     */
    OrderQueueBindingModel,

    /**
     * The OrderGuestPlaceBindingModel model constructor.
     * @property {module:model/OrderGuestPlaceBindingModel}
     */
    OrderGuestPlaceBindingModel,

    /**
     * The OrderGuestQueueBindingModel model constructor.
     * @property {module:model/OrderGuestQueueBindingModel}
     */
    OrderGuestQueueBindingModel,

    /**
    * The AccountApi service constructor.
    * @property {module:api/AccountApi}
    */
    AccountApi,

    /**
    * The CardApi service constructor.
    * @property {module:api/CardApi}
    */
    CardApi,

    /**
    * The CatalogApi service constructor.
    * @property {module:api/CatalogApi}
    */
    CatalogApi,

    /**
    * The DeliveryApi service constructor.
    * @property {module:api/DeliveryApi}
    */
    DeliveryApi,

    /**
    * The FavoriteApi service constructor.
    * @property {module:api/FavoriteApi}
    */
    FavoriteApi,

    /**
    * The LoyaltyApi service constructor.
    * @property {module:api/LoyaltyApi}
    */
    LoyaltyApi,

    /**
    * The OrderApi service constructor.
    * @property {module:api/OrderApi}
    */
    OrderApi,

    /**
    * The PresenceApi service constructor.
    * @property {module:api/PresenceApi}
    */
    PresenceApi,

    /**
    * The WebHookApi service constructor.
    * @property {module:api/WebHookApi}
    */
    WebHookApi
};
