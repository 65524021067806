import React from 'react';
import { connect } from 'react-redux';
import async from 'async';
import { TextField } from '../components';
import { Validation, ApiService, CardService } from '../lib';

const ValidationRules = [
    {
        input: 'code',
        output: 'codeErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: 'Code is required'
            }
        ]
    }
];

class VerifyAccountModal extends React.Component {    

    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            codeErrorMessage: null,
            code: null,
            verifyPending: false
        };
    }

    onNoPressed() {
        this.setState({step: 1, code: null, codeErrorMessage: null});
        this.props.signOut().then(() => {

        }).catch(() => {            

        });
    }

    onContinueButton() {
        this.setState({step: 2});
        this.props.sendCode().then(() => {
            
        }).catch(() => {            
            this.props.toast.error('Unable to resend code!');
        });
    }

    onResendCodePressed() {
        this.props.sendCode().then(() => {
            this.props.toast.success('Code was sent!');
        }).catch(() => {            
            this.props.toast.error('Unable to resend code!');
        });
    }

    onVerifyButton() {
        var isValid = Validation.isValid(this.state, ValidationRules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }

        this.props.verify(this.state.code)
            .then(() => {
                this.setState({step: 1, code: null, codeErrorMessage: null});
                this.props.toast.success('Account verified!');
            }).catch(() => {
                this.props.toast.error('Unable to verify account!');
            });
    }

    onTextChanged(e, fieldName) {
        this.setState({[fieldName]: e.target.value});
    }

    renderStep1() {
        if (this.state.step != 1) {
            return null;
        }

        return (
            <div>
                <div>
                To help protect your account, we will need to verify your email address.
                </div>
                <div>
                    <button className="btn btn-secondary" onClick={() => this.onNoPressed()}>No, Sign Out</button>
                    <button className="btn btn-primary" onClick={() => this.onContinueButton()}>Continue</button>
                </div>
            </div>
        );
    }

    renderStep2() {
        if (this.state.step != 2) {
            return null;
        }

        return (
            <div className="form">
                <div>Please check your email for your verification code.</div>
                <div className="input full-size">
                  <TextField 
                    name="code"
                    placeholder="Code"
                    onChange={(e) => this.onTextChanged(e, 'code')} 
                    onEnterPress={()=>this.onVerifyButton()}
                    errorMessage={this.state.codeErrorMessage} />
                    <div className="additional right">Didn't get it? <a href="javascript://" onClick={() => this.onResendCodePressed()}>Resend Code</a></div>
                </div>
                <div className="button full-size">
                    <button className="btn btn-secondary" onClick={() => this.onNoPressed()}>No, Sign Out</button>
                    <button className="btn btn-primary" onClick={() => this.onVerifyButton()}>Verify</button>
                </div>                
            </div>
        );        
    }

    renderPending() {
        if (!this.state.verifyPending) {
            return null;
        }

        return (
            <div className="form col-half center-block text-center">
                <i className="fas fa-spinner fa-pulse fa-5x"></i>
            </div>
        );
    }    

    render() {
        var modalVisible = false;

        if (!this.props.loginPending) {
            modalVisible = this.props.isLoggedIn && !this.props.isVerified;
        }

        if (!modalVisible) {
            return null;
        }
        
        return (
            <div className="modal-overlay">
                <div className="small-verify-account-modal verify-account-modal modal" style={{display: 'inherit'}}>
                    <div className="wrap">
                        <div className="inner">
                            <div className="cards">
                                <h2>Verification Required</h2>
                                {this.renderStep1()}
                                {this.renderStep2()}
                                {this.renderPending()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        isVerified: state.auth.isVerified,
        loginPending: (state.api.initializing) || (state.auth.login && state.auth.login.pending) || (state.auth.autoLogin && state.auth.autoLogin.initializing),
    }
};

const mapDispatchToProps = (dispatch) => ({
    sendCode: () => {
        return new Promise((resolve, reject) => {
            ApiService.instance.getProfile((profileError, profile, profileResponse) => {
                if (!profile || !profile.email) {
                    reject('Not Logged In');
                    return;
                }

                ApiService.instance.accountResendRegisterCode({
                    email: profile.email,
                }, (error, data, response) => {
                    if (error) {
                        reject(error);
                        return;
                    }
                    resolve(true);
                });
            });
        });
    },
    verify: (code) => {
        return new Promise((resolve, reject) => {
            ApiService.instance.getProfile((profileError, profile, profileResponse) => {
                if (!profile || !profile.email) {
                    reject('Not Logged In');
                    return;
                }

                ApiService.instance.accountVerify({
                    email: profile.email,
                    code: code
                }, (error, data, response) => {
                    if (error) {
                        reject(error);
                        return;
                    }
                    ApiService.instance.accountStatus({}, (e, data, response) => {
                        dispatch({type: 'AUTH_STATUS', data: data });
                        resolve(true);
                    });
                });        
            });
        });
    },
    signOut: () => {
        return new Promise((resolve, reject) => {
            ApiService.instance.logout();
            async.parallel({
                loyalty: (cb) => ApiService.instance.loyaltyStatus({}, (e, data, response) => {
                    dispatch({type: 'LOYALTY_INITIALIZED', data: data })
                    cb(null, true);
                }),
                cards: (cb) => {
                    dispatch({type: 'CARDS_INITIALIZED', cards: [], defaultCard: null });
                    cb(null, true);
                },
            }, (err, results) => {
                dispatch({type: 'AUTH_SIGNED_OUT'});
                resolve(true);
            });      
        })
    }    
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccountModal);