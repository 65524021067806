import React from 'react';
import { default as InputError } from './inputError';

export default class PaymentForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            cardNumberError: null,
            expirationDateError: null,
            cvvError: null,
            postalCodeError: null
        };
    }

    goBack() {
      if (this.props.onBack) {
          this.props.onBack();
      }
    }

    requestCardNonce() {
        this.paymentForm.requestCardNonce();
    }

    onNonce(nonce, cardData) {
        if (this.props.onNonce) {
            this.props.onNonce(nonce, cardData);
        }
    }

    componentDidMount() {
        const config = {
          applicationId: this.props.applicationId,
          locationId: this.props.locationId,
          inputClass: "sq-input",
          autoBuild: false,
          inputStyles: [
            {
              fontSize: "16px",
              padding: "10px 15px",
              boxSizing: "border-box",
              color: "black",
              backgroundColor: "white",
              placeholderColor: "gray",
              _webkitFontSmoothing: "antialiased",
              _mozOsxFontSmoothing: "grayscale"
            }
          ],
          cardNumber: {
            elementId: "sq-card-number",
            placeholder: "Card Number"
          },
          cvv: {
            elementId: "sq-cvv",
            placeholder: "CVV"
          },
          expirationDate: {
            elementId: "sq-expiration-date",
            placeholder: "MM/YY"
          },
          postalCode: {
            elementId: "sq-postal-code",
            placeholder: "Postal code"
          },
          callbacks: {
            methodsSupported: (methods) => {
            },
            createPaymentRequest: () => {
              return null;
            },
            cardNonceResponseReceived: (errors, nonce, cardData) => {
              if (errors) {
                  var newState = {
                      cardNumberError: null,
                      expirationDateError: null,
                      cvvError: null,
                      postalCodeError: null
                  };
                for(var i=0;i<errors.length;i++) {
                    var error = errors[i];
                    newState[error.field + 'Error'] = error.message;
                }
                this.setState(newState);
                return;
              }
              this.onNonce(nonce, cardData);
            },
            unsupportedBrowserDetected: () => {
            },
            inputEventReceived: (inputEvent) => {
            },
            paymentFormLoaded: function() {
            }
          }
        };
        this.paymentForm = new window.SqPaymentForm(config);
        this.paymentForm.build();
      }    

    render() {
        return (
            <section key="creditCardForm">
            <h3>Card Info</h3>
            <div className="input full-size">
                <div id="sq-card-number"></div>
                <InputError className="framed" errorMessage={this.state.cardNumberError} />
            </div>
            <div className="fields-side-by-side">
                <div className="input full-size">
                    <div id="sq-expiration-date"></div>
                    <InputError className="framed" errorMessage={this.state.expirationDateError} />
                </div>
                <div className="input full-size">
                    <div id="sq-cvv"></div>
                    <InputError className="framed" errorMessage={this.state.cvvError} />
                </div>
                <div className="input full-size">
                    <div id="sq-postal-code"></div>
                    <InputError className="framed" errorMessage={this.state.postalCodeError} />
                </div>
            </div>
            <div className="fields-side-by-side">
                {this.props.showBack && (<button className="btn btn-primary" onClick={() => this.goBack()}>Back</button>)}
                <button className="btn btn-primary" onClick={() => this.requestCardNonce()}>Add New Card</button>
            </div>
        </section>
        );
    }
}