import React from 'react';
import { connect } from 'react-redux';
import { default as LocationSelectPartial } from './locationSelectPartial';

class LargeLocationModal extends React.Component {
    onCloseClicked() {
        this.props.setLocation(this.props.location);
    }

    render() {
        if(!this.props.modalVisible) {
            return null;
        }
        
        return (
            <div className="modal-overlay">
                <div className="large-locations-modal locations-modal modal" style={{display: 'inherit'}}>
                    <a href="javascript://" onClick={() => this.onCloseClicked()} className="close"><i className="material-icons md-36">close</i></a>
                    <LocationSelectPartial visible={this.props.modalVisible} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modalVisible: state.location.modalVisible,
        location: state.location.currentLocation,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setLocation: (location) => {
        dispatch({type:'LOCATION_SELECTED', location: location});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(LargeLocationModal);