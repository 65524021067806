/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CreateFavoriteBindingModel from '../model/CreateFavoriteBindingModel';
import ListFavoriteBindingModel from '../model/ListFavoriteBindingModel';
import ListFavoriteResultModel from '../model/ListFavoriteResultModel';
import MergeFavoriteBindingModel from '../model/MergeFavoriteBindingModel';
import MergeFavoriteResultModel from '../model/MergeFavoriteResultModel';
import ModelStateErrorCollection from '../model/ModelStateErrorCollection';
import RemoveFavoriteBindingModel from '../model/RemoveFavoriteBindingModel';

/**
* Favorite service.
* @module api/FavoriteApi
* @version 1.0.0
*/
export default class FavoriteApi {

    /**
    * Constructs a new FavoriteApi. 
    * @alias module:api/FavoriteApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the favoriteCreate operation.
     * @callback module:api/FavoriteApi~favoriteCreateCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/CreateFavoriteBindingModel} model 
     * @param {module:api/FavoriteApi~favoriteCreateCallback} callback The callback function, accepting three arguments: error, data, response
     */
    favoriteCreate(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling favoriteCreate");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Favorite/Create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the favoriteList operation.
     * @callback module:api/FavoriteApi~favoriteListCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ListFavoriteResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/ListFavoriteBindingModel} model 
     * @param {module:api/FavoriteApi~favoriteListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ListFavoriteResultModel}
     */
    favoriteList(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling favoriteList");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ListFavoriteResultModel;

      return this.apiClient.callApi(
        '/api/Favorite/List', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the favoriteMerge operation.
     * @callback module:api/FavoriteApi~favoriteMergeCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MergeFavoriteResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/MergeFavoriteBindingModel} model 
     * @param {module:api/FavoriteApi~favoriteMergeCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MergeFavoriteResultModel}
     */
    favoriteMerge(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling favoriteMerge");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = MergeFavoriteResultModel;

      return this.apiClient.callApi(
        '/api/Favorite/Merge', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the favoriteRemove operation.
     * @callback module:api/FavoriteApi~favoriteRemoveCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/RemoveFavoriteBindingModel} model 
     * @param {module:api/FavoriteApi~favoriteRemoveCallback} callback The callback function, accepting three arguments: error, data, response
     */
    favoriteRemove(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling favoriteRemove");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Favorite/Remove', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }


}
