import React from 'react';
import { connect } from 'react-redux';
import Slider from "react-slick";
import { default as CategorySlide } from './categorySlide';

class PreviousArrow extends React.Component {
    render() {
        const { className, style, onClick } = this.props;
         return (<button className={className}style={style} onClick={onClick}>
             <i className="material-icons md-24">arrow_back_ios</i>
             <span className="sr-only">Previous</span>
        </button>);
    }
}

class NextArrow extends React.Component {
    render() {
        const { className, style, onClick } = this.props;
        return (<button className={className}style={style} onClick={onClick}>
            <i className="material-icons md-24">arrow_forward_ios</i>
            <span className="sr-only">Next</span>
            </button>);
    }
}

class CategoriesSlider extends React.Component {
    render() {
        var categories = this.props.categories;
        var maxSlides = Math.min(categories.length, 5);
        var responsiveMaxSlides = Math.min(categories.length, 2);
        const settings = {
            infinite: true,
            slidesToShow: maxSlides,
            slidesToScroll: maxSlides,
            prevArrow: (<PreviousArrow />),
            nextArrow: (<NextArrow />),
            responsive: [
                {
                    breakpoint: 426,
                    settings: {
                        slidesToShow: responsiveMaxSlides,
                        slidesToScroll: responsiveMaxSlides
                    }
                }
            ]
        };

        var slides = [];

        
        for(var i=0;i<categories.length;i++){
            var category = categories[i];
            var isSelected = category.Id == this.props.selectedCategoryId;

            var mode = this.props.scroll ? "scroll" : "link";

            slides.push(<CategorySlide key={category.Id} category={category} selected={isSelected} mode={mode} />);
        }

        return (
            <div className="select-category">
                <Slider className="categories-menu" {...settings}>
                    {slides}
                </Slider>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        categories: state.catalog.categories,
        selectedCategoryId: state.catalog.selectedCategoryId
    };
};

const mapDispatchToProps = (dispatch) => ({
    select: (category) => {
        dispatch({type: 'CATALOG_SELECT_CATEGORY', category: category});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesSlider);