import React from 'react';

export default class RadioButton extends React.Component {
    onClick() {
        var newValue = !this.props.checked;
        if (this.props.onSelect) {
            this.props.onSelect(newValue);
        }
    }

    onKeyDown(e) {
        if (e.keyCode == 32) {
            this.onClick();
            e.stopPropagation();
            e.preventDefault();
        } else if (e.keyCode == 13) {
            if (this.props.onEnterPress) {
                this.props.onEnterPress(e);
            }
        }
    }    

    render() {
        var icon = this.props.checked ? 'far fa-dot-circle icon fa-2x fa-fw' : 'far fa-circle icon fa-2x fa-fw';

        var detail = null;
        if (this.props.detail) {
            detail = (<span>{this.props.detail}</span>);
        }

        if (this.props.right) {
            return (
                <div className="checkbox right">
                    <label onClick={() => this.onClick()}>{this.props.label}</label>
                    {detail}
                    <a href="javascript://" onKeyDown={(e) => this.onKeyDown(e)}>
                        <i className={icon} onClick={() => this.onClick()}></i>
                    </a>
                </div>
            );
        } else {
            return (
                <div className="checkbox left">
                    <a href="javascript://" onKeyDown={(e) => this.onKeyDown(e)}>
                        <i className={icon} onClick={() => this.onClick()}></i>
                    </a>
                    <label onClick={() => this.onClick()}>{this.props.label}</label>
                    {detail}
                </div>
            );
        }
    }
}