/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import AddressModel from './AddressModel';





/**
* The CardResultModel model module.
* @module model/CardResultModel
* @version 1.0.0
*/
export default class CardResultModel {
    /**
    * Constructs a new <code>CardResultModel</code>.
    * @alias module:model/CardResultModel
    * @class
    * @param expirationMonth {Number} 
    * @param expirationYear {Number} 
    * @param id {String} 
    */

    constructor(expirationMonth, expirationYear, id) {
        

        
        

        this['ExpirationMonth'] = expirationMonth;this['ExpirationYear'] = expirationYear;this['Id'] = id;

        
    }

    /**
    * Constructs a <code>CardResultModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/CardResultModel} obj Optional instance to populate.
    * @return {module:model/CardResultModel} The populated <code>CardResultModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CardResultModel();

            
            
            

            if (data.hasOwnProperty('Brand')) {
                obj['Brand'] = ApiClient.convertToType(data['Brand'], 'String');
            }
            if (data.hasOwnProperty('Last4')) {
                obj['Last4'] = ApiClient.convertToType(data['Last4'], 'String');
            }
            if (data.hasOwnProperty('ExpirationMonth')) {
                obj['ExpirationMonth'] = ApiClient.convertToType(data['ExpirationMonth'], 'Number');
            }
            if (data.hasOwnProperty('ExpirationYear')) {
                obj['ExpirationYear'] = ApiClient.convertToType(data['ExpirationYear'], 'Number');
            }
            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('Address')) {
                obj['Address'] = AddressModel.constructFromObject(data['Address']);
            }
        }
        return obj;
    }

    /**
    * @member {String} Brand
    */
    Brand = undefined;
    /**
    * @member {String} Last4
    */
    Last4 = undefined;
    /**
    * @member {Number} ExpirationMonth
    */
    ExpirationMonth = undefined;
    /**
    * @member {Number} ExpirationYear
    */
    ExpirationYear = undefined;
    /**
    * @member {String} Id
    */
    Id = undefined;
    /**
    * @member {module:model/AddressModel} Address
    */
    Address = undefined;








}


