export default class GeoHelper {

    constructor() {
        this.watchID = null;        
        this.position = null;
    }

    watch() {
        if (this.watchID) {
            return;
        }

        this.watchID = navigator.geolocation.watchPosition((position) => {
            const myLastPosition = this.position;
            const myPosition = position.coords;
            if (!this.arePositionsEqual(myPosition, myLastPosition)) {
                this.position = myPosition;
            }
        }, null, { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 });        
    }

    clearWatch() {
        if (this.watchID) {
            navigator.geolocation.clearWatch(this.watchID);
            this.watchID = null;
        }        
    }

    getPosition() {
        return this.position;
    }

    arePositionsEqual(newPosition, oldPosition) {
        if (!newPosition && oldPosition) {
            return false;
        }

        if (newPosition && !oldPosition) {
            return false;
        }

        if (!newPosition && !oldPosition) {
            return true;
        }

        if (newPosition.latitude == oldPosition.latitude) {
            return newPosition.longitude == oldPosition.longitude;
        }

        return false;
    }
}

GeoHelper.instance = new GeoHelper();