import React from 'react';
import { connect } from 'react-redux';
import { Hero, OrderSummary, GuestProfileForm, CreditCardForm, PickupDateTimeForm, FulfillmentForm } from '../components';

class CheckoutScreen extends React.Component {
    renderOrderSummary() {
        return (<OrderSummary toast={this.props.toast} history={this.props.history} />);
    }

    renderGuestProfileForm() {
        return (<GuestProfileForm />);
    }

    renderCreditCardForm() {
        return (<CreditCardForm toast={this.props.toast} />);
    }

    renderPickupDateTimeForm() {
        return (<PickupDateTimeForm />);
    }
    renderFulfillmentForm() {
        return (<FulfillmentForm />);
    }

    render() {
        return (
            <div className="checkout">
                <Hero message="Checkout" />
                <div className="body">
                    <div className="container">
                        <div className="col-half information">
                            <div className="form">
                                {this.renderFulfillmentForm()}
                                {this.renderGuestProfileForm()}
                                {this.renderCreditCardForm()}
                                {this.renderPickupDateTimeForm()}
                            </div>
                        </div>
                        <div className="col-third cart-summary follow-scroll">
                            {this.renderOrderSummary()}
                        </div>
                    </div>
                </div>            
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        
    }
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutScreen);