import React from 'react';
import classNames from 'classnames';

export default class InputError extends React.Component {
    render() {
        const {className, ...props } = this.props;
        return (
        <div>
            {this.props.errorMessage && <i className={classNames('fas','fa-exclamation-triangle','icon-right','text-danger', className)}></i>}
            {this.props.errorMessage && <span className="error text-danger">{this.props.errorMessage}</span>}
        </div>
        );
    }
}