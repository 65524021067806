import Internationalization from './i18n';

function getDivisor(currency) {
    // TODO: Some currencies are in cents (USD) others are in whole units (JPY)
    return 100.0;
}

function getDecimalPlaces(currency) {
    return 2;
}

export default class CurrencyFormatter {
    // the smallest denomination of the currency indicated by currency. For example, when currency is USD, amount is in cents.
    static format(amount, currency = 'USD') {
        return Internationalization.formatNumber(amount / getDivisor(currency), {
            style: 'currency',
            currency: currency || 'USD',
            currencyDisplay: 'symbol'
        });
    };

    static formatInput(amount, currency = 'USD') {
        return Internationalization.formatNumber(amount / getDivisor(currency), {
            useGrouping: false,
            minimumFractionDigits: getDecimalPlaces(currency),
            maximumFractionDigits: getDecimalPlaces(currency)
        });
    }

    static parseInput(text, currency = 'USD') {
        // users enter values in more familiar formats. 
        // Ex: In USD users enter 1.23 but we work in whole cents so we convert it to 123
        var num = new Number(text);
        return Math.floor(num * getDivisor(currency));
    }

    static oldFormat(amount, decimalPlaces = 2, decimalPlaceHolder = '.', thousandsPlaceHolder = ',') {
        var inCents = amount / 100.0;
        decimalPlaces = isNaN(decimalPlaces = Math.abs(decimalPlaces)) ? 2 : decimalPlaces; 
        decimalPlaceHolder = decimalPlaceHolder == undefined ? "." : decimalPlaceHolder; 
        thousandsPlaceHolder = thousandsPlaceHolder == undefined ? "," : thousandsPlaceHolder; 
        let absValue = inCents < 0 ? "-" : ""; 
        let parsed = String(parseInt(inCents = Math.abs(Number(inCents) || 0).toFixed(decimalPlaces))); 
        let parsedWithThousands = (parsedWithThousands = parsed.length) > 3 ? parsedWithThousands % 3 : 0;
        return '$' + absValue + (parsedWithThousands ? parsed.substr(0, parsedWithThousands) + thousandsPlaceHolder : "") + parsed.substr(parsedWithThousands).replace(/(\d{3})(?=\d)/g, "$1" + thousandsPlaceHolder) + (decimalPlaces ? decimalPlaceHolder + Math.abs(inCents - parsed).toFixed(decimalPlaces).slice(2) : "");        
    }
}