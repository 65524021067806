import ApiService from './apiService';
import { default as CustomStorage } from './customStorage';

export default class CardService {
    constructor() {
        this.cards = [];
        this.default = null;
    }

    initialize(callback) {
        ApiService.instance.getProfile((profileError, profile, profileResponse) => {
            if (profile && profile.id) {
                CustomStorage.getPrivate('storedCreditCards')  
                    .then((parsedResponse) => { 
                        var cards = [];
                        var defaultCard = null;
                        if (parsedResponse) {
                            if (parsedResponse.default)
                                defaultCard = parsedResponse.default;
                            if (parsedResponse.cards)
                                cards = parsedResponse.cards;
                        } 

                        this.cards = cards;
                        this.default = defaultCard;

                        callback(cards, defaultCard);
                    });
            } else {
                callback([], null);
            }
        });
    }

    mergeInit(callback) {
        this.initialize((localCards, localDefault) => {           
            // pick out the local card ids and merge them on the server
            // the response will be a finalized list of verifed cards
            // we return the response and possibly a new transformed 
            // default if the localDefault id is actually valid

            var model = {
                Cards: []
            };

            if (localCards && localCards.length) {
                for(var i=0;i<localCards.length;i++) {
                    var localCard = localCards[i];
                    if (localCard.id) {
                        model.Cards.push(localCard.id);
                    }
                }
            }

            if (localDefault && localDefault.id) {
                model.Cards.push(localDefault.id);
            }

            ApiService.instance.cardMerge(model, (err, data, response) => {
                if (err) {
                    callback([], null);
                    return;
                }

                var finalDefault = null;
                var finalCards = [];

                for(var i=0;i<data.Cards.length;i++) {
                    var remoteCard = data.Cards[i];
                    var finalCard = this.transformRemoteCard(remoteCard);
                    if (localDefault && localDefault.id && localDefault.id == remoteCard.Id) {
                        finalDefault = finalCard;
                    }

                    var isDupe = false;
                    
                    // make sure we don't have any duplicates for any reason
                    for(var j=0;j<finalCards.length;j++) {
                        var existingCard = finalCards[j];
                        if (existingCard.id == finalCard.id) {
                            isDupe = true;
                            break;
                        }
                    }

                    if (!isDupe) {
                        finalCards.push(finalCard);
                    }
                }                

                this.cards = finalCards;
                this.default = finalDefault;     
                this.save();

                callback(data, finalDefault);
            });
        });
    }

    transformRemoteCard(remoteCard) {
        return {
            nonce: null,
            id: remoteCard.Id,
            brand: remoteCard.Brand,
            last4: remoteCard.Last4,
            expMonth: remoteCard.ExpirationMonth,
            expYear: remoteCard.ExpirationYear,
            address: {
                address1: remoteCard.Address.AddressLine1,
                address2: remoteCard.Address.AddressLine2,
                city: remoteCard.Address.Locality,
                state: remoteCard.Address.AdministrativeDistrictLevel1,
                postalCode: remoteCard.Address.PostalCode,
                country: remoteCard.Address.Country
            }
        };        
    }

    save() {
        var storage = {
            default: this.default,
            cards: this.cards
        };
        CustomStorage.setPrivate('storedCreditCards', storage);
    }

    select(card) {
        if (card.id) {
            this.default = card;
            this.save();
        }
    }

    remove(card) {
        var cards = this.cards.filter((listItem)=>{ return listItem.id != card.id });
        var defaultCard = this.defaultCard;

        if (defaultCard && card.id && defaultCard.id == card.id)
            defaultCard = cards.length > 0 ? cards[0] : null;

        this.cards = cards;
        this.default = defaultCard;
        this.save();
    }

    registerCard(model, callback) {
        var card = {
            nonce: null,
            id: null,
            brand: model.cardData.card_brand,
            last4: model.cardData.last_4,
            expMonth: model.cardData.exp_month,
            expYear: model.cardData.exp_year,
            address: model.address
        };

        if (model.remember) {
            ApiService.instance.getProfile((profileError, profile, profileResponse) => {
                // register this with the API, store it locally
                ApiService.instance.cardCreate({
                    nonce: model.nonce,
                    name: model.name,    
                    address1: model.address.address1,
                    address2: model.address.address2,
                    city: model.address.city,
                    state: model.address.state,
                    postalCode: model.address.postalCode,
                    country: model.address.countryCode
                }, (error, data, response) => {
                    if (!error) {
                        card.id = data.Id;
                        this.cards.push(card);
                        this.default = card;
                        this.save();
                    }
                    callback(error, card, response);
                });
            });
        } else {
            // this is the new transient card
            card.nonce = model.nonce;
            callback(null, card, null);
        }
    }
  }


  CardService.instance = new CardService();