import React from 'react';
import { connect } from 'react-redux'; 
import { EmailField, TextField } from '../components';
import { Validation } from '../lib';
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';

const ValidationRules = [
    {
        input: 'firstName',
        output: 'firstNameErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: 'First Name is required'
            }      
        ]
    },
    {
        input: 'lastName',
        output: 'lastNameErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: 'Last Name is required'
            }      
        ]
    },
    {
        input: 'email',
        output: 'emailErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: 'Email Address is required'
            },
            {
                rule: Validation.rules.email,
                message: 'Email Address is not valid'
            }
        ]
    },
    {
        input: 'phone',
        output: 'phoneErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: 'Phone Number is required'
            },
            {
                rule: Validation.rules.phoneNumber,
                message: 'Phone Number is not valid'
            }
        ]
    },    
];

class GuestProfileForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'edit',

            firstName: null,
            lastName: null,
            email: null,
            phone: null,

            firstNameErrorMessage: null,
            lastNameErrorMessage: null,
            emailErrorMessage: null,
            phoneErrorMessage: null,

            initial: {
                firstName: null,
                lastName: null,
                email: null,
                phone: null,                
            }
        };
    }

    componentDidMount() {
        var guest = this.props.guest;
        if (guest) {
            this.setState({
                firstName: guest.firstName,
                lastName: guest.lastName,
                email: guest.email,
                phone: guest.phone,
                initial: {
                    firstName: guest.firstName,
                    lastName: guest.lastName,
                    email: guest.email,
                    phone: guest.phone
                }
            });
        }
    }

    onTextChanged(e, fieldName) {
        this.setState({[fieldName]: e.target.value});
    }

    onUpdatePressed() {
        var isValid = Validation.isValid(this.state, ValidationRules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }

        var model = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
        }

        this.props.setGuest(model);

        this.setState({
            mode: 'display',
            initial: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phone,                
            }
        });
    }

    renderEditMode() {
        return (
            <section>
                <h3>Personal Info</h3>
                <div className="input full-size">
                    <TextField 
                        name="firstName"
                        placeholder="First Name" 
                        defaultValue={this.state.initial.firstName}
                        onChange={(e) => this.onTextChanged(e, 'firstName')} 
                        onEnterPress={()=>this.onUpdatePressed()}
                        errorMessage={this.state.firstNameErrorMessage} />
                </div>
                <div className="input full-size">
                    <TextField 
                        name="lastName"
                        placeholder="Last Name" 
                        defaultValue={this.state.initial.lastName}
                        onChange={(e) => this.onTextChanged(e, 'lastName')} 
                        onEnterPress={()=>this.onUpdatePressed()}
                        errorMessage={this.state.lastNameErrorMessage} />
                </div>
                <div className="input full-size">
                    <EmailField 
                        name="email"
                        placeholder="Email Address" 
                        defaultValue={this.state.initial.email}
                        onChange={(e) => this.onTextChanged(e, 'email')}
                        onEnterPress={()=>this.onUpdatePressed()} 
                        errorMessage={this.state.emailErrorMessage} />
                </div>
                <div className="input full-size">
                    <TextField 
                        name="phone"
                        placeholder="Phone Number" 
                        defaultValue={this.state.initial.phone}
                        onChange={(e) => this.onTextChanged(e, 'phone')} 
                        onEnterPress={()=>this.onUpdatePressed()}
                        errorMessage={this.state.phoneErrorMessage} />
                </div>
                <div className="button full-size">
                    <button className="btn btn-primary" onClick={()=>this.onUpdatePressed()}>Update</button>
                </div>                
            </section>
        );
    }

    onChangeClicked() {
        this.setState({mode:'edit'});
    }

    renderDisplayMode() {
        var phone = this.props.guest.phone;
        var pnu = PhoneNumberUtil.getInstance();
        var phoneUrl = 'tel:' + phone.replace(/\D/g,'');
        var phoneText = pnu.format(pnu.parse(phone, 'US'), PhoneNumberFormat.NATIONAL);

        return (
            <section>
                <h3>Personal Info</h3>
                <div className="guest-profile">
                    <div className="detail">
                        <p>{this.props.guest.firstName} {this.props.guest.lastName}</p>
                        <p className="email">
                            <a href={'mailto:' + this.props.guest.email}>{this.props.guest.email}</a>
                        </p>
                        <p className="phone">
                            <a href={phoneUrl}>{phoneText}</a>
                        </p>       
                    </div>
                    <div><a href="javascript://" className="btn btn-inline btn-primary change-it-btn" onClick={() =>{this.onChangeClicked()}}><span>Change it</span></a></div>
                </div>
            </section>
        );
    }

    render() {
        if (this.props.isLoggedIn) {
            return null;
        }

        if (this.state.mode == 'edit' || !this.props.guest) {
            return this.renderEditMode();
        } else {
            return this.renderDisplayMode();
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        guest: state.cart.guest
    }
};

const mapDispatchToProps = (dispatch) => ({
    setGuest: (model) => {
        dispatch({type:'CART_UPDATE_GUEST', guest: model});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(GuestProfileForm);