import React from 'react';
import { connect } from 'react-redux';
import { Hero, EmailField } from '../components';
import { Validation, ApiService } from '../lib';
import queryString from 'query-string';

const ValidationRules = [
    {
        input: 'email',
        output: 'emailErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: "Email Address is required"
            },
            {
                rule: Validation.rules.email,
                message: "Email Address is not valid"
            }
        ]
    } 
];

class ForgotPasswordScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            emailErrorMessage: null,
            resetPending: false
        };
    }     

    onContinueClicked() {
        var isValid = Validation.isValid(this.state, ValidationRules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }

        this.setState({resetPending: true});

        this.props.forgotPassword(this.state.email)
            .then(() => {
                var q = queryString.stringify({email: this.state.email});
                this.setState({resetPending: false});
                this.props.history.push('/reset-password?' + q);
            })
            .catch((reason) => {
                this.props.toast.error('Invalid email address!');
                this.setState({resetPending: false});
            });
    }

    onEmailTextChanged(e) {
        this.setState({email: e.target.value});
    }

    render() {
        return (
            <div className="forgot-password">
                <Hero message="Forgot Password" />
                <div className="body">
                    <div className="container">
                        {!this.state.resetPending && this.renderForm()}
                        {this.state.resetPending && this.renderPending()}
                    </div>
                </div>
            </div>
        );
    }

    renderForm() {
        return (
            <div className="form col-half center-block">
                <p className="center">Enter an email address associated with your account below so we can send you a verification email.</p>
                <div className="input full-size">
                <EmailField 
                        name="email"
                        placeholder="Email Address" 
                        onChange={(e) => this.onEmailTextChanged(e)} 
                        errorMessage={this.state.emailErrorMessage} />
                </div>
                <div className="button full-size">
                    <button className="btn btn-primary" onClick={()=>this.onContinueClicked()}>Continue</button>
                </div>
            </div>
        );
    }

    renderPending() {
        return (
            <div className="form col-half center-block text-center">
                <i className="fas fa-spinner fa-pulse fa-5x"></i>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => ({
    forgotPassword: (email) => {
        return new Promise((resolve, reject) => {
            ApiService.instance.forgotPassword({
                email: email
            }, (error, data, response) => {
                if (error) {
                    reject(error);
                    return;
                } else {
                    resolve(true);
                }
            });            
        });


    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScreen);