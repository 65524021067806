import { default as uuid } from 'uuid/v4';

export default class ReorderService
{
    constructor(currentLocation, itemsById) {
        this._currentLocation = currentLocation;
        this._itemsById = itemsById;
    }

    findItem(id) {
        if (!id) {
            return null;
        }

        var item = this._itemsById[id];

        return item;
    }

    findVariation(item, id) {
        if (!id) {
            return null;
        }

        var variations = item.Variations;
        for(var v=0;v<variations.length;v++) {
            var variation = variations[v];
            if (variation.Id == id) {
                return variation;
            }
        }
        return null;
    }

    findModifierList(id) {
        if (!id) {
            return null;
        }

        var modifierLists = this._currentLocation.ModifierLists;
        for(var i=0;i<modifierLists.length;i++) {
            var modifierList = modifierLists[i];
            if (modifierList.Id == id) {
                return modifierList;
            }
        }
        return null;
    }

    getModifierListSettings(selectionType, min, max, count) {
        var isFlaggedSingle = selectionType == 0 || selectionType == 'Single';
        if (isFlaggedSingle)
            {
                if (min == null && max == null || min == -1 && max == -1 || min == null && max == -1 || min == -1 && max == null)
                {
                    min = 1;
                    max = 1;
                }
                else if (min == -1 && max > 0 || min == null && max > 0)
                {
                    min = 0;
                }
                else if (min == 0 && max == -1 || min == 0 && max == null) {
                    max = count;
                }
                else
                {
                    if (min == null || min == -1)
                        min = 1;
                    if (max == null || max == -1)
                        max = count;
                }
            }
            else
            {
                if (min == null || min == -1)
                    min = 0;
    
                if (max == null || max == -1)
                    max = count;
            }
        return {
            min: min,
            max: max
        };
    }

    invalid(reason) {
        return {
            valid: false,
            reason: reason
        };
    }

    valid(reason) {
        return {
            valid: true,
            reason: reason
        };        
    }

    accumulateModifiers(possibleModifiers, allSelectedModifiers) {
        var matchingModifiers = [];
        
        for(var i=0;i<possibleModifiers.length;i++){
            var possible = possibleModifiers[i];

            for(var j=0;j<allSelectedModifiers.length;j++) {
                var actual = allSelectedModifiers[j];
                if (actual.Id == possible.Id) {
                    matchingModifiers.push(possible);
                    break;
                }
            }
        }

        return matchingModifiers;
    }

    getLineItemStatus(orderLineItem) {
        if (!orderLineItem) {
            return this.invalid("ORDERLINEITEM_INVALID");
        }

        var item = this.findItem(orderLineItem.ItemId);
        if (!item) {
            return this.invalid("ITEM_IS_REQUIRED");
        }

        var variation = this.findVariation(item, orderLineItem.VariationId);
        if (!variation) {
            return this.invalid("VARIATION_IS_REQUIRED");
        }

        var allPossibleModifiers = [];

        var itemModifierLists = item.ModifierLists;
        for(var i=0;i<itemModifierLists.length;i++) {
            var itemModifierList = itemModifierLists[i];

            var modifierList = this.findModifierList(itemModifierList.Id);

            var min = itemModifierList.MinSelectedModifiers;
            var max = itemModifierList.MaxSelectedModifiers;            

            for(var j=0;j<modifierList.Modifiers.length;j++) {
                allPossibleModifiers.push(modifierList.Modifiers[j]);
            }

            var selectedModifiers = this.accumulateModifiers(modifierList.Modifiers, orderLineItem.Modifiers);
            
            var settings = this.getModifierListSettings(modifierList.SelectionType, min, max, modifierList.Modifiers.length);

            var actual = selectedModifiers.length;

            if (settings.min == 1 && settings.max == 1 && actual == 0) {
                return this.invalid("MODIFIERLIST_IS_REQUIRED");
            } else if (settings.min > 0 && actual < settings.min) {
                return this.invalid("MODIFIERLIST_MIN_NOT_MET");
            } else if (settings.max > 0 && actual > settings.max) {
                return this.invalid("MODIFIERLIST_MAX_EXCEEDED");
            }
        }
        
        for(var i=0;i<orderLineItem.Modifiers.length;i++){

            var matches = false;
            var selected = orderLineItem.Modifiers[i];

            for(var j=0;j<allPossibleModifiers.length;j++) {
                var possible = allPossibleModifiers[j];
                if (possible.Id == selected.Id) {
                    matches = true;
                    break;
                }
            }

            if (!matches) {
                return this.valid("MODIFIER_MISSING_OPTIONAL");
            }
        }

        return this.valid(null);
    }

    getLineItemCartEntry(orderLineItem) {

        var item = this.findItem(orderLineItem.ItemId);


        var modifiers = {};

        var modifierToListIdMap = {};

        var itemModifierLists = item.ModifierLists;
        for(var i=0;i<itemModifierLists.length;i++) {
            var itemModifierList = itemModifierLists[i];

            var modifierList = this.findModifierList(itemModifierList.Id);

            for(var j=0;j<modifierList.Modifiers.length;j++) {
                modifierToListIdMap[modifierList.Modifiers[j].Id] = itemModifierList.Id;
            }
        }

        for(var i=0;i<orderLineItem.Modifiers.length;i++) {
            var selected = orderLineItem.Modifiers[i];
            var listId = modifierToListIdMap[selected.Id];
            if (listId) {
                var list = modifiers[listId] = modifiers[listId] || [];
                list.push(selected.Id);
            }
        }

        return {
            key: uuid(),
            item: orderLineItem.ItemId,
            quantity: orderLineItem.Quantity,
            variation: orderLineItem.VariationId,
            modifiers: modifiers,
            note: orderLineItem.Note
        }
    }
}