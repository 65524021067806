/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import Address from './Address';
import Category from './Category';
import FulfillmentSettings from './FulfillmentSettings';
import LocationContact from './LocationContact';
import LocationHours from './LocationHours';
import ModifierList from './ModifierList';
import PaymentSettings from './PaymentSettings';
import Tax from './Tax';
import TipSettings from './TipSettings';





/**
* The Location model module.
* @module model/Location
* @version 1.0.0
*/
export default class Location {
    /**
    * Constructs a new <code>Location</code>.
    * @alias module:model/Location
    * @class
    * @param id {String} 
    * @param prepTime {String} 
    * @param showAsSoonAsPossibleOrders {Boolean} 
    * @param showScheduledOrders {Boolean} 
    * @param showTipping {Boolean} 
    * @param isSecured {Boolean} 
    */

    constructor(id, prepTime, showAsSoonAsPossibleOrders, showScheduledOrders, showTipping, isSecured) {
        

        
        

        this['Id'] = id;this['PrepTime'] = prepTime;this['ShowAsSoonAsPossibleOrders'] = showAsSoonAsPossibleOrders;this['ShowScheduledOrders'] = showScheduledOrders;this['ShowTipping'] = showTipping;this['IsSecured'] = isSecured;

        
    }

    /**
    * Constructs a <code>Location</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Location} obj Optional instance to populate.
    * @return {module:model/Location} The populated <code>Location</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Location();

            
            
            

            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('Name')) {
                obj['Name'] = ApiClient.convertToType(data['Name'], 'String');
            }
            if (data.hasOwnProperty('Timezone')) {
                obj['Timezone'] = ApiClient.convertToType(data['Timezone'], 'String');
            }
            if (data.hasOwnProperty('Address')) {
                obj['Address'] = Address.constructFromObject(data['Address']);
            }
            if (data.hasOwnProperty('PrepTime')) {
                obj['PrepTime'] = ApiClient.convertToType(data['PrepTime'], 'String');
            }
            if (data.hasOwnProperty('Country')) {
                obj['Country'] = ApiClient.convertToType(data['Country'], 'String');
            }
            if (data.hasOwnProperty('Currency')) {
                obj['Currency'] = ApiClient.convertToType(data['Currency'], 'String');
            }
            if (data.hasOwnProperty('LanguageCode')) {
                obj['LanguageCode'] = ApiClient.convertToType(data['LanguageCode'], 'String');
            }
            if (data.hasOwnProperty('Payment')) {
                obj['Payment'] = PaymentSettings.constructFromObject(data['Payment']);
            }
            if (data.hasOwnProperty('Taxes')) {
                obj['Taxes'] = ApiClient.convertToType(data['Taxes'], [Tax]);
            }
            if (data.hasOwnProperty('ModifierLists')) {
                obj['ModifierLists'] = ApiClient.convertToType(data['ModifierLists'], [ModifierList]);
            }
            if (data.hasOwnProperty('Categories')) {
                obj['Categories'] = ApiClient.convertToType(data['Categories'], [Category]);
            }
            if (data.hasOwnProperty('Hours')) {
                obj['Hours'] = ApiClient.convertToType(data['Hours'], [LocationHours]);
            }
            if (data.hasOwnProperty('Contacts')) {
                obj['Contacts'] = ApiClient.convertToType(data['Contacts'], [LocationContact]);
            }
            if (data.hasOwnProperty('ShowAsSoonAsPossibleOrders')) {
                obj['ShowAsSoonAsPossibleOrders'] = ApiClient.convertToType(data['ShowAsSoonAsPossibleOrders'], 'Boolean');
            }
            if (data.hasOwnProperty('ShowScheduledOrders')) {
                obj['ShowScheduledOrders'] = ApiClient.convertToType(data['ShowScheduledOrders'], 'Boolean');
            }
            if (data.hasOwnProperty('ShowTipping')) {
                obj['ShowTipping'] = ApiClient.convertToType(data['ShowTipping'], 'Boolean');
            }
            if (data.hasOwnProperty('Tipping')) {
                obj['Tipping'] = TipSettings.constructFromObject(data['Tipping']);
            }
            if (data.hasOwnProperty('Fulfillments')) {
                obj['Fulfillments'] = FulfillmentSettings.constructFromObject(data['Fulfillments']);
            }
            if (data.hasOwnProperty('IsSecured')) {
                obj['IsSecured'] = ApiClient.convertToType(data['IsSecured'], 'Boolean');
            }
        }
        return obj;
    }

    /**
    * @member {String} Id
    */
    Id = undefined;
    /**
    * @member {String} Name
    */
    Name = undefined;
    /**
    * @member {String} Timezone
    */
    Timezone = undefined;
    /**
    * @member {module:model/Address} Address
    */
    Address = undefined;
    /**
    * @member {String} PrepTime
    */
    PrepTime = undefined;
    /**
    * @member {String} Country
    */
    Country = undefined;
    /**
    * @member {String} Currency
    */
    Currency = undefined;
    /**
    * @member {String} LanguageCode
    */
    LanguageCode = undefined;
    /**
    * @member {module:model/PaymentSettings} Payment
    */
    Payment = undefined;
    /**
    * @member {Array.<module:model/Tax>} Taxes
    */
    Taxes = undefined;
    /**
    * @member {Array.<module:model/ModifierList>} ModifierLists
    */
    ModifierLists = undefined;
    /**
    * @member {Array.<module:model/Category>} Categories
    */
    Categories = undefined;
    /**
    * @member {Array.<module:model/LocationHours>} Hours
    */
    Hours = undefined;
    /**
    * @member {Array.<module:model/LocationContact>} Contacts
    */
    Contacts = undefined;
    /**
    * @member {Boolean} ShowAsSoonAsPossibleOrders
    */
    ShowAsSoonAsPossibleOrders = undefined;
    /**
    * @member {Boolean} ShowScheduledOrders
    */
    ShowScheduledOrders = undefined;
    /**
    * @member {Boolean} ShowTipping
    */
    ShowTipping = undefined;
    /**
    * @member {module:model/TipSettings} Tipping
    */
    Tipping = undefined;
    /**
    * @member {module:model/FulfillmentSettings} Fulfillments
    */
    Fulfillments = undefined;
    /**
    * @member {Boolean} IsSecured
    */
    IsSecured = undefined;








}


