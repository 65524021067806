import { default as OrderCalculator } from '../orderCalculator';
import moment from 'moment-timezone';
import Internationalization from '../i18n';
import { default as uuid } from 'uuid/v4';

function generateIdempotencyKey() {
    console.log('generated new idempotency key');
    return uuid();
}

const initialState = {
    items: [],
    coupons: [],
    rewards: [],
    count: 0,
    tipAmount: 0,
    modifyTip: false,
    location: null,
    date: null,
    time: null,
    validDates: [],
    validTimes: [],
    isOpen: true,
    guest: null,
    datePickerVisible: false,
    timePickerVisible: false,
    confirmDate: false,
    mustConfirmDate: false,
    userSelectedDateTime: false,
    removedCount: 0,
    selectedCardKey: null,
    idempotencyKey: generateIdempotencyKey(),
    fulfillmentType: null,
    pickupSettings: null,
    curbsideSettings: null,
    deliverySettings: null,
    preview: {
        grandTotal: 0,
        totalTaxes: 0,
        totalDiscounts: 0,
        subTotal: 0,
        lineItems: {}
    }
};

function getCardKey(card) {
    if (card == null) {
        return null;
    }
    return card.id || card.nonce;
}

function getItemCount(items, coupons, rewards) {
    var total = 0;
    for(var i=0;i<items.length;i++) {
        total += items[i].quantity;
    }
    if (!coupons) {
        coupons = [];
    }
    if (!rewards) {
        rewards = [];
    }
    // only one valid coupon or reward is allowed at this time
    if(rewards.length > 0) {
        total += 1;
    } else if (coupons.length > 0) {
        total += 1;
    }
    return total;
}

function calculateOrder(location, items, coupons, rewards, tipAmount) {
    items = items || [];
    coupons = coupons || [];
    rewards = rewards || [];

    var totalDiscounts = 0;
    var totalTaxes = 0;
    var totalAdditiveTaxes = 0;
    var totalInclusiveTaxes = 0;
    var grandTotal = 0;
    var subTotal = 0;
    var grossTotal = 0;
    var lineItems = {};

    var taxMap = {};
    var modifierMap = {};
    var variationMap = {};

    location.Taxes.forEach((tax) => {
        taxMap[tax.Id] = tax;
    });

    location.ModifierLists.forEach((list)=> {
        list.Modifiers.forEach((modifier) => {
            modifierMap[modifier.Id] = modifier;
        });
    });

    location.Categories.forEach((category) => {
        category.Items.forEach((item) => {
            item.Variations.forEach((variation) => {
                variationMap[variation.Id] = {
                    item: item,
                    variation: variation,
                    category: category
                };
            });
        });
    });

    var rawItems = [];

    items.forEach((entry) => {

        var tuple = variationMap[entry.variation];
        var item = tuple.item;
        var variation = tuple.variation;
        var category = tuple.category;

        var taxes = [];
        item.Taxes.forEach((taxId) => {
            taxes.push(taxMap[taxId]);
        });

        var modifiers = [];
        var entryModifiers = [].concat.apply([],Object.values(entry.modifiers));
        entryModifiers.forEach((modifierId) => {
            var modifier = modifierMap[modifierId];
            if (modifier)
                modifiers.push(modifier);
        });

        var modifiersTotal = 0;
        modifiers.forEach((modifier) => {
            modifiersTotal += (modifier.Price || 0);
        });

        var baseAmount = (variation.Price || 0) + modifiersTotal;

        rawItems.push({
            key: entry.key,
            quantity: entry.quantity,
            baseAmount: baseAmount,                
            taxes: taxes,
            
            categoryId: category.Id,
            itemId: item.Id,
            variationId: variation.Id
        });
    });

    // we only honor the 1st coupon or reward in the cart
    var autoDiscount = null;
    if (rewards && rewards.length > 0) {
        autoDiscount = rewards[0];
    } else if (coupons && coupons.length > 0) {
        autoDiscount = coupons[0];
    }

    var calculator = new OrderCalculator(rawItems, autoDiscount, Math.round);

    items.forEach((entry) => {
        var calculation = calculator.getItemCalculation(entry.key);

        var lineItem = {
            key: entry.key,
            totalDiscounts: calculation.totalDiscounts,
            total: calculation.adjustedTotal,
            totalTaxes: calculation.totalTaxes,
            totalAdditiveTaxes: calculation.additiveTaxes,
            totalInclusiveTaxes: calculation.inclusiveTaxes,
            grossSales: calculation.grossAmount,
            basePrice: calculation.baseAmount,
            subTotal: calculation.subTotalAmount
        };

        lineItems[entry.key] = lineItem;

        grandTotal += calculation.adjustedTotal;
        totalDiscounts += calculation.totalDiscounts;
        totalTaxes += calculation.totalTaxes;
        totalAdditiveTaxes += calculation.additiveTaxes;
        totalInclusiveTaxes += calculation.inclusiveTaxes;
        subTotal += calculation.subTotalAmount;
        grossTotal += calculation.grossAmount;
    })

    grandTotal += tipAmount;
          
    return {
        grandTotal: grandTotal,
        totalTaxes: totalTaxes,
        totalAdditiveTaxes: totalAdditiveTaxes,
        totalInclusiveTaxes: totalInclusiveTaxes,
        totalDiscounts: totalDiscounts,
        totalTips: tipAmount,
        subTotal: subTotal,
        grossTotal: grossTotal,
        lineItems: lineItems
    };
}

function getModifierListSettings(selectionType, min, max, count) {
    var isFlaggedSingle = selectionType == 0 || selectionType == 'Single';
    if (isFlaggedSingle)
        {
            if (min == null && max == null || min == -1 && max == -1 || min == null && max == -1 || min == -1 && max == null)
            {
                min = 1;
                max = 1;
            }
            else if (min == -1 && max > 0 || min == null && max > 0)
            {
                min = 0;
            }
            else if (min == 0 && max == -1 || min == 0 && max == null) {
                max = count;
            }
            else
            {
                if (min == null || min == -1)
                    min = 1;
                if (max == null || max == -1)
                    max = count;
            }
        }
        else
        {
            if (min == null || min == -1)
                min = 0;

            if (max == null || max == -1)
                max = count;
        }
    return {
        min: min,
        max: max
    };
}

function isEntryValid(entry, ctx) {
    var itemVariationPair = ctx.variationMap[entry.variation];
    if (!itemVariationPair) {
        console.log('variation not valid: ' + entry.variation);
        return false;
    }

    var item = itemVariationPair.item;
    
    var itemModifierLists = item.ModifierLists;

    for(var i=0;i<itemModifierLists.length;i++) {
        var itemModifierList = itemModifierLists[i];

        var modifierList = ctx.modifierListMap.get(itemModifierList.Id);

        var selectedModifiers = entry.modifiers[modifierList.Id] || [];

        var min = itemModifierList.MinSelectedModifiers;
        var max = itemModifierList.MaxSelectedModifiers;            

        var actual = selectedModifiers.length;

        var settings = getModifierListSettings(modifierList.SelectionType, min, max, modifierList.Modifiers.length);

        if (settings.min == 1 && settings.max == 1 && actual == 0) {
            console.log('modifier list is required: ' + modifierList.Name);
            return false;
        } else if (settings.min > 0 && actual < settings.min) {
            console.log('modifier list minimum not met: ' + modifierList.Name + ' minimum: ' + min);
            return false;
        } else if (settings.max > 0 && actual > settings.max) {
            console.log('modifier list maximum exceeded: ' + modifierList.Name + ' maximum: ' + max);
            return false;
        }
    }

    var entryModifiers = [].concat.apply([],Object.values(entry.modifiers));
    for(var i=0;i<entryModifiers.length;i++) {
        var modifierId = entryModifiers[i];

        var modifier = ctx.modifierMap[modifierId];
        if (!modifier) {
            console.log('modifier not valid: ' + modifierId);
            return false;
        }
    }

    return true;
}

function createLocationContext(location) {
    var modifierListMap = new Map(location.ModifierLists.map((x) => [x.Id, x]));    
    var variationMap = {};

    location.Categories.forEach((category) => {
        category.Items.forEach((item) => {
            item.Variations.forEach((variation) => {
                variationMap[variation.Id] = {
                    item: item,
                    variation: variation
                };
            });
        });
    });

    var modifierMap = {};
    location.ModifierLists.forEach((list)=> {
        list.Modifiers.forEach((modifier) => {
            modifierMap[modifier.Id] = modifier;
        });
    });        

    return {
        modifierListMap: modifierListMap,
        variationMap: variationMap,
        modifierMap: modifierMap
    };
}

function adjustItemsForLocation(items, location) {
    var finalItems = [];

    var ctx = createLocationContext(location);

    items.forEach((entry) => {
        if (isEntryValid(entry, ctx))
            finalItems.push(entry);
    });

    return finalItems;
}

function _datesAreEqual(first, second) {
    if (first == null && second == null) {
        return true;
    }

    if (first == null && second != null) {
        return false;
    }

    if (first != null && second == null) {
        return false;
    }

    return first.isSame(second);
}

function datesAreEqual(first, second) {
    var result = _datesAreEqual(first, second);
    console.log('datesAreEqual(', first, ',', second, ') = ', result);
    return result;
}

function isDateValid(validDates, test) {
    if (test == null) {
        return false;
    }

    for(var i=0;i<validDates.length;i++) {
        var validDate = validDates[i];
        if (validDate.isSame(test)) {
            return true;
        }
    }

    return false;
}

function _timesAreEqual(first, second) {
    if (first == null && second == null) {
        return true;
    }

    if (first == null && second != null) {
        return false;
    }

    if (first != null && second == null) {
        return false;
    }

    if (first.isASAP && second.isASAP) {
        return true;
    }

    if (first.isASAP && !second.isASAP) {
        return false;
    }

    if (!first.isASAP && second.isASAP) {
        return false;
    }    

    var firstTime = first.value.clone().dayOfYear(1);
    var secondTime = second.value.clone().dayOfYear(1);

    return firstTime.isSame(secondTime);
}

function timesAreEqual(first, second) {
    var result = _timesAreEqual(first, second);
    console.log('timesAreEqual(', first, ',', second, ') = ', result);
    return result;
}

function isTimeValid(validTimes, test) {
    if (test == null) {
        return false;
    }

    var isASAP = false;
    var testTime = null;
    if (test.isASAP) {
        isASAP = true;
    } else {
        testTime = test.value.clone().dayOfYear(1);
    }

    for(var i=0;i<validTimes.length;i++) {
        var validTime = validTimes[i];
        if (validTime.isASAP || isASAP) {
            if (validTime.isASAP && isASAP) {
                return test.value.isSame(validTime.value);
            }
            continue;
        }
        validTime = validTime.value.clone().dayOfYear(1);
        if (validTime.isSame(testTime)) {
            return true;
        }
    }

    return false;
}

function getValidDates(location) {
    if (location == null)
        return [];

    const locationTimeZone = location.Timezone;

    const currentTime = moment().tz(locationTimeZone);

    const startOfToday = currentTime.clone().startOf('day');
    
    var validDates = [];

    for(var i=0;i<7;i++) {
        var testDate = startOfToday.clone().add(i, 'days');
        var validTimes = getValidTimes(location, testDate);
        var isValid = validTimes.length > 0;

        if (isValid) {
            validDates.push(testDate);
        }
    }

    return validDates;
} 

function isSelectedDateToday(date, location) {
    if (date == null)
        return false;

    const locationTimeZone = location.Timezone;
    const currentTime = moment().tz(locationTimeZone);
    return date.isSame(currentTime, 'day');
    // return date.format('dddd') == currentTime.format('dddd');
}

function isSameDayOfWeek(hours, dayOfWeek) {
    var nameToNumber = {
        "Sunday": 0,
        "Monday": 1,
        "Tuesday" : 2,
        "Wednesday": 3,
        "Thursday": 4,
        "Friday": 5,
        "Saturday": 6
    };
    return nameToNumber[hours.DayOfWeek] === dayOfWeek;
}

function makeTime(date, time) {
    const format = 'H:mm:ss';

    var timeMoment = moment(time, format);

    return date
        .clone()
        .hour(timeMoment.hour())
        .minute(timeMoment.minute())
        .second(timeMoment.second())
        .millisecond(timeMoment.millisecond());
}

function getEffectiveOpenTime(hours, referenceDate) {
    return makeTime(referenceDate, hours.Opens);
}

function getEffectiveCloseTime(hours, referenceDate) {
    var opens = getEffectiveOpenTime(hours, referenceDate);
    var closes = makeTime(referenceDate, hours.Closes);
    if (closes.isSameOrBefore(opens)) {
        closes = closes.add(24, 'hours');
    }
    return closes;
}

function isPickupTimeAnOptionWithinHours(testTime, hours, prepTime, referenceDate) {
    const time = testTime,
    open = getEffectiveOpenTime(hours, referenceDate).add(prepTime, 'minutes'),
    close = getEffectiveCloseTime(hours, referenceDate);

    var isValid = time.isBetween(open, close, null, '[]');

    return isValid;
}

function isPickupTimeAnOption(testTime, location, prepTime, referenceDate) {
    for(var i=0;i<location.Hours.length;i++){
        var hours = location.Hours[i];
        if (!isSameDayOfWeek(hours, referenceDate.day())) {
            continue;
        }

        if (isPickupTimeAnOptionWithinHours(testTime, hours, prepTime, referenceDate)) {
            return true;
        }
    }
    return false;
}

function getValidTimes(location, date) {
    if (location == null || date == null)
        return [];

    const locationTimeZone = location.Timezone;

    var validTimes = [];

     // how much time the location needs to prep an order
    var prepTime = moment.duration(location.PrepTime).asMinutes();
    
    var step = 15; // what increment do we want to show?

    var total = 48 * 60 / step;

    const currentTime = moment().tz(locationTimeZone);

    const currentTimeWithPrepTime = currentTime.clone().add(prepTime, 'minutes');

    var isToday = isSelectedDateToday(date, location);
    var isOpen = isLocationOpen(location);
    var prepTime = moment.duration(location.PrepTime).asMinutes();
    var shouldShowAsap = location.ShowAsSoonAsPossibleOrders && isPickupTimeAnOption(currentTimeWithPrepTime, location, prepTime, date);

    if (isToday && isOpen && shouldShowAsap) {
        validTimes.push({
            value: currentTimeWithPrepTime,
            label: Internationalization.strings('time_select.asap'),
            isASAP: true,
            longLabel: Internationalization.strings('time_select.as_soon_as_possible'),
            key: 'asap',
            confirmationLabel: currentTimeWithPrepTime.format('LT')
        });
    }

    if (!location.ShowScheduledOrders) {
        return validTimes;
    }

    for(var i=0;i<total;i++) {
        var testTime = date.clone().add(i * step, 'minutes');

        if (testTime.isSameOrBefore(currentTimeWithPrepTime)) {
            continue;
        }

        if (isPickupTimeAnOption(testTime, location, prepTime, date)) {
            validTimes.push({
                value: testTime,
                label: testTime.format('LT'),
                longLabel: testTime.format('LT'),
                isASAP: false,
                key: testTime.format('x'),
                confirmationLabel: testTime.format('LT'),
            });
        }
    }

    return validTimes;
}

function isLocationOpen(location) {
    if (location == null)
        return true;

    const locationTimeZone = location.Timezone;

    const currentTime = moment().tz(locationTimeZone);

    return isPickupTimeAnOption(currentTime, location, 0, currentTime);
}

function handleSelectedCardChange(state, card, mustBeEqual) {

    var originalIdempotencyKey = state.idempotencyKey;
    var incomingCardKey = getCardKey(card);
    var areEqual = incomingCardKey == state.selectedCardKey;
    var finalIdempotencyKey = originalIdempotencyKey;
    var finalCardKey = incomingCardKey;

    // if mustBeEqual is true then we will change the idempotency key if the incoming card's key matches the selected card's key
    // if mustBeEqual is false then we will change the idempotency key if the incoming card's key DOES NOT match the selected card's key    
    var changeIdempotencyKey = (areEqual && mustBeEqual) || (!areEqual && !mustBeEqual);
    if (changeIdempotencyKey) {
        finalIdempotencyKey = generateIdempotencyKey();
        if (mustBeEqual) {
            finalCardKey = getCardKey(null);
        }
    }

    /*
    console.log('handle selected card: ');
    console.log('    selectedCardKey: ', state.selectedCardKey);
    console.log('    incomingCardKey: ', incomingCardKey);
    console.log('    finalCardKey: ', finalCardKey);

    console.log('    areEqual: ', areEqual);
    console.log('    mustBeEqual: ', mustBeEqual);

    console.log('    changeIdempotencyKey: ', changeIdempotencyKey);
    console.log('    originalIdempotencyKey: ', originalIdempotencyKey);
    console.log('    finalIdempotencyKey: ', finalIdempotencyKey);
    */

    return {
        ...state,
        selectedCardKey: finalCardKey,
        idempotencyKey: finalIdempotencyKey
    };    
}

function getTipByPercentage(preview, percentage) {
    var grandTotal = preview.grandTotal;
    var totalTips = preview.totalTips;

    var preTipTotal = grandTotal - totalTips;

    return Math.floor(preTipTotal * percentage / 100);    
}

function isTipAmountValid(location, items, coupons, rewards, tipAmount) {
    var tipSettings = location.Tipping || {};
    var maxPercentage = tipSettings.MaximumPercentage || 25;
    var preview = calculateOrder(location, items, coupons, rewards, tipAmount);
    var maximumTipAmount = getTipByPercentage(preview, maxPercentage);
    if (tipAmount > maximumTipAmount)
        return false;
    return true;
}

function transformTimeToMatchDate(validTimes, time) {
    var isASAP = false;
    if (time.isASAP) {
        isASAP = true;
    }

    for(var i=0;i<validTimes.length;i++) {
        var validTime = validTimes[i];
        if (validTime.isASAP || isASAP) {
            if (validTime.isASAP && isASAP) {
                return validTime;
            }
            continue;
        }

        if (timesAreEqual(validTime, time)) {
            if (!validTime.value.isSame(time.value)) {
                return validTime;
            } else {
                break;
            }
        }
    }

    return null;
}

export default function cart(state, action) {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'CART_CLEAR_REMOVED_COUNT':
            return {
                ...state,
                removedCount: 0
            };
        case 'LOCATION_SELECTED':
            var idempotencyKey = state.idempotencyKey;
            var beforeCount = state.count;
            var items = adjustItemsForLocation(state.items, action.location);
            var afterCount = getItemCount(items, state.coupons, state.rewards);

            var idempotencyChanged = false;
            if (beforeCount != afterCount) {
                idempotencyChanged = true;
            }
            if (state.location == null && action.location != null) {
                idempotencyChanged = true;
            } else if (state.location.Id != action.location.Id) {
                idempotencyChanged = true;
            }

            var validDates = getValidDates(action.location);
            var date = state.date;
            if (!state.userSelectedDateTime || !isDateValid(validDates, date)) {
                var newDate = validDates.length > 0 ? validDates[0] : null;
                if (!datesAreEqual(date, newDate)) {
                    idempotencyChanged = true;
                }
                date = newDate;                
            }
            var time = state.time;
            var validTimes = getValidTimes(action.location, date);
            if (!state.userSelectedDateTime || !isTimeValid(validTimes, time)) {
                var newTime = validTimes.length > 0 ? validTimes[0] : null;
                if (!timesAreEqual(time, newTime)) {
                    idempotencyChanged = true;
                }
                time = newTime;
            }

            var tipAmount = state.tipAmount;
            if (!action.location.ShowTipping) {
                tipAmount = 0;
                idempotencyChanged = true;
            } else if (afterCount < beforeCount) {
                tipAmount = 0;
                idempotencyChanged = true;                
            }

            if (!isTipAmountValid(action.location, items, state.coupons, state.rewards, tipAmount)) {
                tipAmount = 0;
                idempotencyChanged = true;                
            }

            if (idempotencyChanged) {
                idempotencyKey = generateIdempotencyKey();
            }

            return {
                ...state,
                idempotencyKey: idempotencyKey,
                location: action.location,
                tipAmount: tipAmount,
                modifyTip: false,
                date: date,
                time: time,
                validDates: validDates,
                validTimes: validTimes,
                datePickerVisible: false,
                timePickerVisible: false,
                confirmDate: false,
                mustConfirmDate: !isSelectedDateToday(date, action.location),
                items: items,
                removedCount: beforeCount - afterCount,
                count: afterCount,
                preview: calculateOrder(action.location, items, state.coupons, state.rewards, tipAmount),
                isOpen: isLocationOpen(action.location)
            };
        case 'CART_TIMER_TICK':
            var idempotencyKey = state.idempotencyKey;
            var location = state.location;
            var idempotencyChanged = false;
            var validDates = getValidDates(location);
            var date = state.date;
            if (!state.userSelectedDateTime || !isDateValid(validDates, date)) {
                var newDate = validDates.length > 0 ? validDates[0] : null;
                if (!datesAreEqual(date, newDate)) {
                    idempotencyChanged = true;
                }
                date = newDate;
            }
            var time = state.time;
            var validTimes = getValidTimes(location, date);
            if (!state.userSelectedDateTime || !isTimeValid(validTimes, time)) {
                var newTime = validTimes.length > 0 ? validTimes[0] : null;
                if (!timesAreEqual(time, newTime)) {
                    idempotencyChanged = true;
                }
                time = newTime;
            }   
                     
            if (idempotencyChanged) {
                idempotencyKey = generateIdempotencyKey();
            }

            return {
                ...state,
                idempotencyKey: idempotencyKey,
                date: date,
                time: time,
                validDates: validDates,
                validTimes: validTimes,
                mustConfirmDate: !isSelectedDateToday(date, location),
                isOpen: isLocationOpen(location)
            };
        case 'ORDER_PLACED':
            var location = state.location;
            var validDates = getValidDates(location);
            var date = validDates.length > 0 ? validDates[0] : null;            
            var validTimes = getValidTimes(location, date);
            var time = validTimes.length > 0 ? validTimes[0] : null;
            return {
                ...state,
                items: [],
                coupons: [],
                rewards: [],
                tipAmount: 0,
                modifyTip: false,
                count: 0,
                removedCount: 0,
                datePickerVisible: false,
                timePickerVisible: false,        
                confirmDate: false,
                date: date,
                time: time,
                validDates: validDates,
                validTimes: validTimes,                
                userSelectedDateTime: false,
                guest: null,
                idempotencyKey: generateIdempotencyKey(),
                preview: {
                    grandTotal: 0,
                    totalTaxes: 0,
                    totalDiscounts: 0,
                    subTotal: 0,
                    lineItems: {}
                },
                pickupSettings: null,
                curbsideSettings: null,
                deliverySettings: null,
                fulfillmentType: null
            };
        case 'CART_ADD':
            var items = [...state.items, action.cartItem];
            
            var tipAmount = state.tipAmount;
            if (!isTipAmountValid(state.location, items, state.coupons, state.rewards, tipAmount)) {
                tipAmount = 0;
            }

            return {
                ...state,
                tipAmount: tipAmount,
                idempotencyKey: generateIdempotencyKey(),
                items: items,
                count: getItemCount(items, state.coupons, state.rewards),
                preview: calculateOrder(state.location, items, state.coupons, state.rewards, tipAmount)
            };
        case 'CART_UPDATE':
            var key = action.cartItem.key;
            var index = state.items.findIndex((v, i, arr) => v.key == key );

            var items = [];
            if (action.cartItem.quantity <= 0) {
                items = [
                    ...state.items.slice(0, index),
                    ...state.items.slice(index + 1)
                ];
            } else {
                var items = [
                    ...state.items.slice(0, index),
                    action.cartItem,
                    ...state.items.slice(index + 1)                        
                ];
            }    

            var tipAmount = state.tipAmount;
            if (!isTipAmountValid(state.location, items, state.coupons, state.rewards, tipAmount)) {
                tipAmount = 0;
            }

            return {
                ...state,
                tipAmount: tipAmount,
                idempotencyKey: generateIdempotencyKey(),
                items: items,
                count: getItemCount(items, state.coupons, state.rewards),
                preview: calculateOrder(state.location, items, state.coupons, state.rewards, tipAmount)
            };
        case 'CART_QUANTITY_INCREASE':
            var key = action.cartItem.key;
            var index = state.items.findIndex((v, i, arr) => v.key == key );
            var items = [...state.items];
            items[index].quantity++;

            var tipAmount = state.tipAmount;
            if (!isTipAmountValid(state.location, items, state.coupons, state.rewards, tipAmount)) {
                tipAmount = 0;
            }            

            return {
                ...state,
                tipAmount: tipAmount,
                idempotencyKey: generateIdempotencyKey(),
                items: items,
                count: getItemCount(items, state.coupons, state.rewards),
                preview: calculateOrder(state.location, items, state.coupons, state.rewards, tipAmount)
            };
        case 'CART_QUANTITY_DECREASE':
            var key = action.cartItem.key;
            var index = state.items.findIndex((v, i, arr) => v.key == key );
            var items = [...state.items];
            items[index].quantity--;
            if (items[index].quantity <= 0) {
                items = [
                    ...state.items.slice(0, index),
                    ...state.items.slice(index + 1)
                ];
            }

            var tipAmount = state.tipAmount;
            if (!isTipAmountValid(state.location, items, state.coupons, state.rewards, tipAmount)) {
                tipAmount = 0;
            }            

            return {
                ...state,
                tipAmount: tipAmount,
                idempotencyKey: generateIdempotencyKey(),
                items: items,
                count: getItemCount(items, state.coupons, state.rewards),
                preview: calculateOrder(state.location, items, state.coupons, state.rewards, tipAmount)
            };
        case 'CART_COUPON_ADD':
            var coupons = [action.coupon];
            var rewards = [];

            var tipAmount = state.tipAmount;
            if (!isTipAmountValid(state.location, state.items, coupons, rewards, tipAmount)) {
                tipAmount = 0;
            }

            return {
                ...state,
                idempotencyKey: generateIdempotencyKey(),
                coupons: coupons,
                rewards: rewards,
                count: getItemCount(state.items, coupons, rewards),
                preview: calculateOrder(state.location, state.items, coupons, rewards, tipAmount)
            };
        case 'CART_COUPON_REMOVE':
            var coupons = [];
            var rewards = state.rewards;

            var tipAmount = state.tipAmount;
            if (!isTipAmountValid(state.location, state.items, coupons, rewards, tipAmount)) {
                tipAmount = 0;
            }

            return {
                ...state,
                idempotencyKey: generateIdempotencyKey(),
                coupons: coupons,
                rewards: rewards,
                count: getItemCount(state.items, coupons, rewards),
                preview: calculateOrder(state.location, state.items, coupons, rewards, tipAmount)
            };

        case 'CART_REWARD_ADD':
            var coupons = [];
            var rewards = [action.reward];

            var tipAmount = state.tipAmount;
            if (!isTipAmountValid(state.location, state.items, coupons, rewards, tipAmount)) {
                tipAmount = 0;
            }

            return {
                ...state,
                idempotencyKey: generateIdempotencyKey(),
                coupons: coupons,
                rewards: rewards,
                count: getItemCount(state.items, coupons, rewards),
                preview: calculateOrder(state.location, state.items, coupons, rewards, tipAmount)
            };
        case 'CART_REWARD_REMOVE':
            var coupons = state.coupons;
            var rewards = [];

            var tipAmount = state.tipAmount;
            if (!isTipAmountValid(state.location, state.items, coupons, rewards, tipAmount)) {
                tipAmount = 0;
            }

            return {
                ...state,
                idempotencyKey: generateIdempotencyKey(),
                coupons: coupons,
                rewards: rewards,
                count: getItemCount(state.items, coupons, rewards),
                preview: calculateOrder(state.location, state.items, coupons, rewards, tipAmount)
            };
        case 'CART_DATE_CHOOSER':
            return {
                ...state,
                datePickerVisible: true
            };
        case 'CART_DATE_SELECTED':
            var date = action.date;
            var time = state.time;
            var idempotencyKey = state.idempotencyKey;
            var idempotencyChanged = false;
            if (!datesAreEqual(date, state.date)) {
                idempotencyChanged = true;
            }
            var validTimes = getValidTimes(state.location, date);
            if (!isTimeValid(validTimes, time)) {
                var newTime = validTimes.length > 0 ? validTimes[0] : null;
                if (!timesAreEqual(time, newTime)) {
                    idempotencyChanged = true;
                }
                time = newTime;
            } else {
                var newTime = transformTimeToMatchDate(validTimes, time);
                if (newTime != null) {
                    time = newTime;
                    idempotencyChanged = true;
                }
            }

            if (idempotencyChanged) {
                idempotencyKey = generateIdempotencyKey();
            }

            return {
                ...state,
                idempotencyKey: idempotencyKey,
                datePickerVisible: false,
                userSelectedDateTime: true,
                date: date,
                mustConfirmDate: !isSelectedDateToday(date, state.location),
                validTimes: validTimes,
                time: time
            };
        case 'CART_TIME_CHOOSER':
            return {
                ...state,
                timePickerVisible: true
            };
        case 'CART_TIME_SELECTED':
            var time = action.time;
            var idempotencyKey = state.idempotencyKey;
            var idempotencyChanged = false;
            if (!timesAreEqual(time, state.time)) {
                idempotencyChanged = true;
            }
            if (idempotencyChanged) {
                idempotencyKey = generateIdempotencyKey();
            }                 
            return {
                ...state,
                idempotencyKey: idempotencyKey,
                timePickerVisible: false,
                time: time,
                userSelectedDateTime: true
            };            
        case 'CART_DATE_SHOW_CONFIRMATION':
            return {
                ...state,
                confirmDate: true
            };
        case 'CART_DATE_HIDE_CONFIRMATION':
            return {
                ...state,
                confirmDate: false
            };
        case 'CART_SET_TIP':
            var idempotencyKey = state.idempotencyKey;

            var newTipAmount = action.tipAmount;
            if (!isTipAmountValid(state.location, state.items, state.coupons, state.rewards, newTipAmount)) {
                newTipAmount = 0;
            }

            if (state.tipAmount != newTipAmount) {
                idempotencyKey = generateIdempotencyKey();
            }

            return {
                ...state,
                idempotencyKey: idempotencyKey,
                tipAmount: newTipAmount,
                preview: calculateOrder(state.location, state.items, state.coupons, state.rewards, newTipAmount)
            };
        case 'CART_TIP_SHOW_MODIFY':
            return {
                ...state,
                modifyTip: true,
            };
        case 'CART_TIP_HIDE_MODIFY':
            return {
                ...state,
                modifyTip: false
            };
        case 'CART_UPDATE_GUEST':
            return {
                ...state,
                idempotencyKey: generateIdempotencyKey(),
                guest: {...action.guest}
            };
        case 'CARDS_INITIALIZED':
            // change idempotency key if this card is NOT the selected card
            return handleSelectedCardChange(state, action.defaultCard, false);
        case 'CARDS_REGISTERED':
            // change idempotency key if this card is NOT the selected card
            return handleSelectedCardChange(state, action.card, false);
        case 'CARDS_SELECT':
            // change idempotency key if this card is NOT the selected card
            return handleSelectedCardChange(state, action.card, false);
        case 'CARDS_REMOVE':
            // change idempotency key if this card is the selected card
            return handleSelectedCardChange(state, action.card, true);
        case 'CART_CLEAR_FULFILLMENT':
            return {
                ...state,
                fulfillmentType: null,
                pickupSettings: null,
                curbsideSettings: null,
                deliverySettings: null
            };            
        case 'CART_SET_PICKUP_FULFILLMENT':
            return {
                ...state,
                fulfillmentType: 'PICKUP',
                pickupSettings: {},
                curbsideSettings: null,
                deliverySettings: null
            };
        case 'SET_PICKUP_SETTINGS_NOTE':
            return {
                ...state,
                pickupSettings: {
                    ...state.pickupSettings,
                    notes: action.notes
                }
            };


        case 'CART_SET_CURBSIDE_FULFILLMENT':
            return {
                ...state,
                fulfillmentType: 'CURBSIDE',
                pickupSettings: null,
                curbsideSettings: {
                    vehicleDetails: action.vehicleDetails
                },
                deliverySettings: null
            };
        case 'CART_SET_DELIVERY_FULFILLMENT':
            return {
                ...state,
                fulfillmentType: 'DELIVERY',
                pickupSettings: null,
                curbsideSettings: null,
                deliverySettings: {
                    deliveryAddressId: action.deliveryAddressId
                }
            };
        default:
            return state;
    }  
}