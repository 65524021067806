/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import DeliveryAddressCreateModel from '../model/DeliveryAddressCreateModel';
import DeliveryAddressCreateResultModel from '../model/DeliveryAddressCreateResultModel';
import DeliveryAddressDeleteModel from '../model/DeliveryAddressDeleteModel';
import DeliveryAddressDeleteResultModel from '../model/DeliveryAddressDeleteResultModel';
import DeliveryAddressListModel from '../model/DeliveryAddressListModel';
import DeliveryAddressListResultModel from '../model/DeliveryAddressListResultModel';
import DeliveryAddressUpdateModel from '../model/DeliveryAddressUpdateModel';
import DeliveryAddressUpdateResultModel from '../model/DeliveryAddressUpdateResultModel';
import DeliveryAddressValidateModel from '../model/DeliveryAddressValidateModel';
import DeliveryAddressValidateResultModel from '../model/DeliveryAddressValidateResultModel';
import DeliveryQuoteModel from '../model/DeliveryQuoteModel';
import DeliveryQuoteResultModel from '../model/DeliveryQuoteResultModel';
import ModelStateErrorCollection from '../model/ModelStateErrorCollection';

/**
* Delivery service.
* @module api/DeliveryApi
* @version 1.0.0
*/
export default class DeliveryApi {

    /**
    * Constructs a new DeliveryApi. 
    * @alias module:api/DeliveryApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the deliveryCreateAddress operation.
     * @callback module:api/DeliveryApi~deliveryCreateAddressCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DeliveryAddressCreateResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/DeliveryAddressCreateModel} model 
     * @param {module:api/DeliveryApi~deliveryCreateAddressCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DeliveryAddressCreateResultModel}
     */
    deliveryCreateAddress(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling deliveryCreateAddress");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DeliveryAddressCreateResultModel;

      return this.apiClient.callApi(
        '/api/Delivery/Address/Create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the deliveryDeleteAddress operation.
     * @callback module:api/DeliveryApi~deliveryDeleteAddressCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DeliveryAddressDeleteResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/DeliveryAddressDeleteModel} model 
     * @param {module:api/DeliveryApi~deliveryDeleteAddressCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DeliveryAddressDeleteResultModel}
     */
    deliveryDeleteAddress(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling deliveryDeleteAddress");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DeliveryAddressDeleteResultModel;

      return this.apiClient.callApi(
        '/api/Delivery/Address/Delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the deliveryListAddresses operation.
     * @callback module:api/DeliveryApi~deliveryListAddressesCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DeliveryAddressListResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/DeliveryAddressListModel} model 
     * @param {module:api/DeliveryApi~deliveryListAddressesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DeliveryAddressListResultModel}
     */
    deliveryListAddresses(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling deliveryListAddresses");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DeliveryAddressListResultModel;

      return this.apiClient.callApi(
        '/api/Delivery/Address', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the deliveryQuote operation.
     * @callback module:api/DeliveryApi~deliveryQuoteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DeliveryQuoteResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/DeliveryQuoteModel} model 
     * @param {module:api/DeliveryApi~deliveryQuoteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DeliveryQuoteResultModel}
     */
    deliveryQuote(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling deliveryQuote");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DeliveryQuoteResultModel;

      return this.apiClient.callApi(
        '/api/Delivery/Quote', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the deliveryUpdateAddress operation.
     * @callback module:api/DeliveryApi~deliveryUpdateAddressCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DeliveryAddressUpdateResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/DeliveryAddressUpdateModel} model 
     * @param {module:api/DeliveryApi~deliveryUpdateAddressCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DeliveryAddressUpdateResultModel}
     */
    deliveryUpdateAddress(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling deliveryUpdateAddress");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DeliveryAddressUpdateResultModel;

      return this.apiClient.callApi(
        '/api/Delivery/Address/Update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the deliveryValidateAddress operation.
     * @callback module:api/DeliveryApi~deliveryValidateAddressCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DeliveryAddressValidateResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/DeliveryAddressValidateModel} model 
     * @param {module:api/DeliveryApi~deliveryValidateAddressCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DeliveryAddressValidateResultModel}
     */
    deliveryValidateAddress(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling deliveryValidateAddress");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DeliveryAddressValidateResultModel;

      return this.apiClient.callApi(
        '/api/Delivery/Address/Validate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }


}
