import React from 'react';
import { connect } from 'react-redux';
import { default as LargeLocationModal } from './largeLocationModal';

class SmartLocationModal extends React.Component {
    render() {
        return (
            <LargeLocationModal toast={this.props.toast} />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        locations: state.location.locations,
    }
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SmartLocationModal);