import React from 'react';
import { connect } from 'react-redux';

class StoreClosedModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: null,
        }
    }

    closeClicked() {
        this.setState({visible: false});
    }

    render() {
        if (!this.props.location) {
            return null;
        }

        var modalVisible = false;
        if (this.state.visible !== null) {
            modalVisible = this.state.visible;
        } else {
            modalVisible = !this.props.isOpen;
        }        

        if (!modalVisible) {
            return null;
        }

        const hasScheduledOrders = this.props.location.ShowScheduledOrders;        
        
        var backgroundColor = 'rgba(0,222,173,.90)';

        var message = 'This location is currently closed.';
        if (hasScheduledOrders) {
            message = 'This location is currently closed. Orders placed must be scheduled for future pickup.';
        }
        
        return (
        <div className="toast" style={{backgroundColor: backgroundColor}}>
            <a href="javascript://" onClick={() => this.closeClicked()} className="close"><i className="material-icons md-36">close</i></a>
            <div className="heading">Oops!</div>
            <div className="message">{message}</div>
        </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        location: state.location.currentLocation,
        isOpen: state.cart.isOpen
    }
};

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreClosedModal);