/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import LoyaltyRewardType from './LoyaltyRewardType';





/**
* The LoyaltyRewardModel model module.
* @module model/LoyaltyRewardModel
* @version 1.0.0
*/
export default class LoyaltyRewardModel {
    /**
    * Constructs a new <code>LoyaltyRewardModel</code>.
    * @alias module:model/LoyaltyRewardModel
    * @class
    * @param points {Number} 
    * @param type {module:model/LoyaltyRewardType} 
    */

    constructor(points, type) {
        

        
        

        this['Points'] = points;this['Type'] = type;

        
    }

    /**
    * Constructs a <code>LoyaltyRewardModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/LoyaltyRewardModel} obj Optional instance to populate.
    * @return {module:model/LoyaltyRewardModel} The populated <code>LoyaltyRewardModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LoyaltyRewardModel();

            
            
            

            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('Points')) {
                obj['Points'] = ApiClient.convertToType(data['Points'], 'Number');
            }
            if (data.hasOwnProperty('Name')) {
                obj['Name'] = ApiClient.convertToType(data['Name'], 'String');
            }
            if (data.hasOwnProperty('Variations')) {
                obj['Variations'] = ApiClient.convertToType(data['Variations'], ['String']);
            }
            if (data.hasOwnProperty('Items')) {
                obj['Items'] = ApiClient.convertToType(data['Items'], ['String']);
            }
            if (data.hasOwnProperty('Categories')) {
                obj['Categories'] = ApiClient.convertToType(data['Categories'], ['String']);
            }
            if (data.hasOwnProperty('Type')) {
                obj['Type'] = LoyaltyRewardType.constructFromObject(data['Type']);
            }
            if (data.hasOwnProperty('Percentage')) {
                obj['Percentage'] = ApiClient.convertToType(data['Percentage'], 'Number');
            }
            if (data.hasOwnProperty('AmountMoney')) {
                obj['AmountMoney'] = ApiClient.convertToType(data['AmountMoney'], 'Number');
            }
            if (data.hasOwnProperty('MaximumMoney')) {
                obj['MaximumMoney'] = ApiClient.convertToType(data['MaximumMoney'], 'Number');
            }
        }
        return obj;
    }

    /**
    * @member {String} Id
    */
    Id = undefined;
    /**
    * @member {Number} Points
    */
    Points = undefined;
    /**
    * @member {String} Name
    */
    Name = undefined;
    /**
    * @member {Array.<String>} Variations
    */
    Variations = undefined;
    /**
    * @member {Array.<String>} Items
    */
    Items = undefined;
    /**
    * @member {Array.<String>} Categories
    */
    Categories = undefined;
    /**
    * @member {module:model/LoyaltyRewardType} Type
    */
    Type = undefined;
    /**
    * @member {Number} Percentage
    */
    Percentage = undefined;
    /**
    * @member {Number} AmountMoney
    */
    AmountMoney = undefined;
    /**
    * @member {Number} MaximumMoney
    */
    MaximumMoney = undefined;








}


