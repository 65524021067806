class VehicleDetails{
    year:string | undefined
    make:string | undefined
    model:string | undefined
    color:string | undefined
    notes:string | undefined
}

class CurbsideState
{
    vehicleDetails:VehicleDetails | undefined | null
    ignoredOrders!: string[]
};

const initialState:CurbsideState = {
    vehicleDetails: null,
    ignoredOrders: []
};

export default function curbside(state:CurbsideState, action:any):CurbsideState {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'CURBSIDE_SET_VEHICLE_DETAILS':
            return {
                ...state,
                vehicleDetails: action.vehicleDetails
            };
        case 'CURBSIDE_IGNORE_ORDER':
            return {
                ...state,
                ignoredOrders: [action.order, ...state.ignoredOrders]
            };
        default:
            return state;
    }
}