import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import rootReducer from './reducers'
import { persistStore } from 'redux-persist'


export default class Store {
    constructor() {
        this._store = null;
        this._persistor = null;
    }

    configureStore() {
        const enhancers = [];
        const middleware = [ ];

        const sentryBreadcrumbLogger = store => next => action => {
            try {
                global.sentry.captureBreadcrumb({
                    category: 'redux.action',
                    message: JSON.stringify(action),
                    level: "debug",
                });
            }
            catch {

            }

            return next(action); // dispatch
        };

        middleware.push(sentryBreadcrumbLogger);

        if (process && process.env && process.env.NODE_ENV === 'development') {
            middleware.push(logger);
            const { devToolsExtension } = window;

            if (typeof devToolsExtension === 'function') {
                enhancers.push(devToolsExtension());
            }
        }

        const composedEnhancers = compose(
            applyMiddleware(...middleware),
            ...enhancers
        );                

        return createStore(rootReducer, composedEnhancers);
    }

    getStore() {
        if (this._store == null) {
            this._store = this.configureStore();
        }
        return this._store;
    }

    getPersistor() {
        if (this._persistor == null) {
            this._persistor = persistStore(this.getStore());
        }
        return this._persistor;
    }
}

Store.instance = new Store();