import React from 'react';
import { default as InputError } from './inputError';
import MaskedInput from 'react-text-mask';

export default class TextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
        };      
    }

    componentWillReceiveProps(nextProps) {
        this.setState({errorMessage: nextProps.errorMessage});
    }

    executeCallback(name, e) {
        if (this.props[name]) {
            this.props[name](e);
        }
    }

    onInputKeyDown(e) {
        if (e.keyCode == 13) {
            if (this.props.onEnterPress) {
                this.props.onEnterPress(e);
            }
        }
        this.executeCallback('onKeyDown', e);
    }

    render() {
        var  { type, errorMessage, underMessage, onEnterPress, mask, ...otherProps } = this.props;

        type = type || 'text';
     
        mask = mask || false;

        return (
            <div>
                <MaskedInput {...otherProps} type={type} mask={mask} onKeyDown={(e)=>this.onInputKeyDown(e)} />
                <InputError errorMessage={this.state.errorMessage} />
                {!this.state.errorMessage && underMessage && (<span className="note">{underMessage}</span>)}
            </div>
        );
    }
}