import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class CategoryCard extends React.Component {
    render() {
        var imageUrl = this.props.category.ImageUrl;
        
        if (imageUrl == null) {
            for(var i=0;i<this.props.category.Children.length;i++){
                var item = this.props.category.Children[i];
                if (item.ImageUrl) {
                    imageUrl = item.ImageUrl;
                    break;
                }
            }

            if (imageUrl == null) {
                imageUrl = global.imagesPath + 'category-placeholder.jpg';
            }
        }

        var url = '/categories/' + this.props.category.Id;

        return (
            <div className="category-card">
                <Link to={url} className="image" style={{backgroundImage: "url(" + imageUrl + ")"}} onClick={() => this.onClick()}>
                    <span className="sr-only">See all items under this category</span>
                </Link>
                <h2>
                    <Link to={url} onClick={() => this.onClick()}>{this.props.category.Name}</Link>
                </h2>
            </div>
        );
    }

    onClick() {
        this.props.select(this.props.category);
    }
}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => ({
    select: (category) => {
        dispatch({type: 'CATALOG_SELECT_CATEGORY', category: category});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryCard);