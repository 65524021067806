import React from 'react';
import { connect } from 'react-redux';
import { GoogleApiWrapper, Map, InfoWindow, Marker } from 'google-maps-react';
import scrollIntoView from 'scroll-into-view';
import moment from 'moment';
import {PhoneNumberFormat, PhoneNumberUtil} from 'google-libphonenumber';
import { default as GeoHelper } from '../geoHelper';

class LocationSelectPartial extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            selectedPlace: null,
            activeMarker: null,
            showingInfoWindow: false,
            searchPosition: null,
            searchText: null
        };
        this.locationCards = {};
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.removedCount > 0 && nextProps.removedCount != this.props.removedCount) {
            this.props.toast.warning('We removed items from your cart because they are not available at this location.', () => {
                this.props.clearRemovedCount();
            });
        }
    } 

    selectLocation(location) {
        this.setState({
            selectedPlace: null,
            showingInfoWindow: false,
            activeMarker: null,
            search: null,
            searchPosition: null,
            searchText: null
        });        
        this.props.setLocation(location);
    }

    getDistance(lat1, lon1, lat2, lon2) {
        var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var distance = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (distance > 1) {
			distance = 1;
		}
		distance = Math.acos(distance);
		distance = distance * 180/Math.PI;
		distance = distance * 60 * 1.1515;

        return distance;
    }
    
    getDistanceToLocation(address) {
        if (!address || !address.Longitude || !address.Latitude) {
            return null;
        }

        var lat1 = address.Latitude;
        var lon1 = address.Longitude;

        var lat2 = null;
        var lon2 = null;

        if (this.state.searchPosition && this.state.searchPosition != 'Error' && this.state.searchPosition.lat && this.state.searchPosition.lng) {
            lat2 = this.state.searchPosition.lat;
            lon2 = this.state.searchPosition.lng;
        }

        if (lat2 == null || lon2 == null)
            return null;

        return this.getDistance(lat1,lon1,lat2,lon2);
    }

    renderAddressParts(location) {
        var address = location.Address;

        var addressParts = [
            address.AddressLine1
        ];

        addressParts.push((<br key="1" />))
        if (address.AddressLine2 && address.AddressLine2 != '') {
            addressParts.push(address.AddressLine2);
            addressParts.push((<br key="2" />))
        }
        addressParts.push(address.Locality + ', ' + address.AdministrativeDistrictLevel1 + ' ' + address.PostalCode);
        addressParts.push((<br key="3" />))

        return addressParts;
    }

    formatPhone(location) {
        var companyPhone = null;

        var contacts = location.Contacts;
        for(var i=0;i<contacts.length;i++) {
            var contact = contacts[i];
            var type = contact.Type;
            if (type == 'Phone') {
                companyPhone = contact.Value;
                break;
            }
        }

        if (!companyPhone) {
            return (<div></div>);
        }

        var pnu = PhoneNumberUtil.getInstance();

        var url = 'tel:' + companyPhone.replace(/\D/g,'');
        var text = pnu.format(pnu.parse(companyPhone, 'US'), PhoneNumberFormat.NATIONAL);

        return (
            <div className="phone">
                <a href={url}>{text}</a>
            </div>        
        );
    }

    formatTime(value) {
        var m = moment.duration(value);
        var hh = m.hours();
        var mm = m.minutes();
        var dd = 'AM';
        var h = hh;
        if (h >= 12) {
            h = hh - 12;
            dd = 'PM';
        }
        if (h == 0) {
            h = 12;
        }

        mm = mm < 10 ? "0" + mm.toString() : mm.toString();

        return h.toString() + ':' + mm + ' ' + dd;
    }

    formatHourPair(value, index, array) {
        var opens = this.formatTime(value.Opens);
        var closes = this.formatTime(value.Closes);
        return opens + ' - ' + closes;
    }

    formatHours(location) {
        var hours = location.Hours;
        if (hours == null || hours.length == 0) {
            return null;
        }

        const currentTime = moment().tz(location.Timezone);

        var days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        var dayOfWeek = days[currentTime.day()];
        var hoursForToday = [];

        for(var i=0;i<hours.length;i++) {
            var hour = hours[i];
            if (hour.DayOfWeek == dayOfWeek) {
                hoursForToday.push(hour);
            }
        }

        var result = null;
        if (hoursForToday.length == 0) {
            result = (<span><strong>{dayOfWeek}:</strong> Closed</span>);
        } else {
            var text = hoursForToday.map((v, i, a) => this.formatHourPair(v,i,a)).join(", ");
            result = (<span><strong>{dayOfWeek}:</strong> {text}</span>);
        }

        return (
            <div className="hours">
                {result}
            </div>
        );
    }

    isLocationSelected(location) {
        if (this.state.selectedPlace)
            return this.state.selectedPlace.Id == location.Id;
        else if (this.props.location && this.props.location.Id == location.Id)
            return true;
        return false;
    }

    renderLocation(location, distance) {
        let distanceString = null;
        if (distance != null) {
            if (distance > 10) {
                distanceString = distance.toFixed(0);
            } else {
                distanceString = distance.toFixed(1);
            }
        }

        const isSelected = this.isLocationSelected(location);

        var containerClasses = ['location', isSelected ? 'active':''].join(' ');
        var buttonClasses = 'btn btn-primary';

        return (
            <div ref={(ref) => this.locationCards[location.Id] = ref } key={location.Id} className={containerClasses}>
                <div className="title">
                    <strong>{location.Name}</strong>
                    {distanceString && <span>{distanceString} mi</span>}
                </div>
                <div>{this.renderAddressParts(location)}</div>
                <div>{this.formatPhone(location)}</div>
                <div>{this.formatHours(location)}</div>
                <a href="javascript://" onClick={() => this.selectLocation(location)} className={buttonClasses}>Select Location</a>
            </div>
        )
    }

    onMapClicked(props) {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    }

    onMarkerClicked(props, marker, location ) {
        var card = this.locationCards[location.Id];
        scrollIntoView(card.current || card, {
            isScrollable: (target, defaultIsScrollable) => {
                return target === window || target === document || target === document.body ? false : defaultIsScrollable(target);
            }
        });
        this.setState({
            selectedPlace: location,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }

    renderInfoWindow() {
        var location = this.state.selectedPlace;
        if (!location)
            return (<div></div>);
        const distance = this.getDistanceToLocation(location.Address);
        let distanceString = null;
        if (distance != null) {
            if (distance > 10) {
                distanceString = distance.toFixed(0);
            } else {
                distanceString = distance.toFixed(1);
            }
        }    

        return (
            <div key={location.Id} className="info-window-location">
                <div className="title">
                    <strong>{location.Name}</strong>
                    {distanceString && <span>{distanceString} mi</span>}
                </div>
                <p>{this.renderAddressParts(location)}</p>
            </div>
        );
    }

    renderMarker(location, position) {
        return (<Marker onClick={(props, marker) => this.onMarkerClicked(props, marker, location)} key={location.Id} position={position} name={location.Name} />);
    }

    onSearchKeyPress(e) {
        var key=e.keyCode || e.which;
        if (key == 13) {
            this.onSearchClicked();
        }
    }

    onSearchChanged(e) {
        this.setState({search: e.target.value});
    }

    onSearchClicked() {
        var searchText = this.state.search;
        if (!searchText) {
            this.setState({
                searchText: null,
                searchPosition: null
            })
            return;
        }
        this.setState({searchText: searchText});
        var that = this;
        var geocoder = new this.props.google.maps.Geocoder();
        geocoder.geocode( { 'address': searchText }, function(results, status) {
            if (status == 'OK') {
                var position = {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                };
                
                that.setState({
                    searchPosition: position
                });
            } else {
              that.setState({
                  searchPosition: 'Error'
              });
            }
          });        
    }

    getReverseGeoCodeDetail(results) {
        var model = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
            zipCode: null
        };

        for(var i=0;i<results.length;i++) {
            var result = results[i];
            for(var j=0;j<result.address_components.length;j++) {
                var component = result.address_components[j];
                for (var k=0;k<component.types.length;k++) {
                    var type = component.types[k];
                    if (type == 'postal_code') {
                        model.zipCode = component.long_name;
                        return model;
                    }
                }
            }
        }

        return model;
    }

    useMyLocation(attempt) {
        if (attempt > 16) {
            return;
        }

        var that = this;
        var newCoordinates = GeoHelper.instance.getPosition();
        if (newCoordinates == null) {
            setTimeout(() => {
                that.useMyLocation(attempt + 1);
            }, 333);
            return;
        }

        var searchPosition = {
            lat: newCoordinates.latitude,
            lng: newCoordinates.longitude
        };
        var geocoder = new this.props.google.maps.Geocoder();
        geocoder.geocode( { 'location': searchPosition }, function(results, status) {
            if (status == 'OK') {
                if (results.length > 0) {
                    var detail = that.getReverseGeoCodeDetail(results);
                    that.setState({
                        searchPosition: searchPosition,
                        searchText: detail.zipCode,
                        search: detail.zipCode
                    });                        
                } else {
                    that.setState({
                        searchPosition: searchPosition,
                        searchText: 'your location'
                    });
                }
            } else {
                that.setState({
                    searchPosition: searchPosition,
                    searchText: 'your location'
                });
            }
        });
    }

    onUseMyLocationClicked() {
        GeoHelper.instance.watch();

        var that = this;
        setTimeout(() => {
            that.useMyLocation(0);
        }, 333);
    }

    renderSearchForm() {
        return (
            <div className="form">
                <strong>Change Pickup Location</strong>
                <div className="input full-size">
                    <div>
                        <input type="text" placeholder="Enter a zip code" value={this.state.search} onKeyPress={(e)=>this.onSearchKeyPress(e)} onChange={(e) => this.onSearchChanged(e)}  />
                        {this.state.searchPosition == 'Error' && <span className="error text-danger">Oops! We can't find {this.state.searchText}!</span>}
                        <a href="javascript://" className="icon-right" onClick={()=>this.onSearchClicked()}><i className="fas fa-search" /></a>
                    </div>
                </div>
                <div className="input full-size">
                    <button className="btn btn-primary" onClick={() => this.onUseMyLocationClicked()}>Use My Location</button>
                </div>
                {this.state.searchPosition && this.state.searchPosition != 'Error' && <strong>Showing locations near {this.state.searchText}:</strong>}
            </div>
        )
    }

    render() {
        if (!this.props.visible) {
            return null;
        }

        const fieldSorter = (fields) => (a, b) => fields.map(o => {
            let dir = 1;
            if (o[0] === '-') { dir = -1; o=o.substring(1); }
            return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
        }).reduce((p, n) => p ? p : n, 0);

        var markers = [];
        var bounds = new this.props.google.maps.LatLngBounds();
        var locations = [];
        for(var i=0;i<this.props.locations.length;i++) {
            var location = this.props.locations[i];
            const distance = this.getDistanceToLocation(location.Address);
            if (location.Address) {
                if (location.Address.Longitude && location.Address.Latitude) {
                    var position = {lat: location.Address.Latitude, lng: location.Address.Longitude};
                    bounds.extend(position);
                    markers.push(this.renderMarker(location, position));
                }
            }
            locations.push({
                content: this.renderLocation(location, distance),
                distance: distance,
                name: location.Name
            });
        }

        locations.sort(fieldSorter(['distance', 'name']));
        locations = locations.map(x => x.content);        
        
        return (
            <div className="inner">
                <div className="left">
                    {this.renderSearchForm()}
                    {locations}
                </div>
                <div className="right">
                    <Map 
                        google={this.props.google} 
                        onReady={(mapProps, map) => map.fitBounds(bounds)} 
                        onClick={(props) => this.onMapClicked(props)} 
                        containerStyle={{position: 'relative', flex: 1, display: 'flex', flexGrow: 1}}>
                        {markers}
                        <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
                            {this.renderInfoWindow()}
                        </InfoWindow>
                    </Map>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        locations: state.location.locations,
        location: state.location.currentLocation,
        removedCount: state.cart.removedCount,
    }
};

const mapDispatchToProps = (dispatch) => ({
    setLocation: (location) => {
        dispatch({type:'LOCATION_SELECTED', location: location});
    },
    clearRemovedCount: () => {
        dispatch({type:'CART_CLEAR_REMOVED_COUNT'});
    }
});

const mapConfig = {
    apiKey: 'AIzaSyDKeAJHrSN3fkbFm-_8vp7v_lfhyWYrskc',
    LoadingContainer: (props) => (<div></div>),
    wrapperClassName: 'wrap'
};

export default GoogleApiWrapper(mapConfig)(connect(mapStateToProps, mapDispatchToProps)(LocationSelectPartial));