import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { default as GeoHelper } from '../geoHelper';
import accountIcon from '../img/outline-account_circle-24px.png';
import cartIcon from '../img/outline-shopping_basket-24px.png';

class Header extends React.Component {
    onLocationClicked() {
        GeoHelper.instance.watch();
        this.props.openLocationModal();
    }

    render() {
        const location = this.props.currentLocation;
        const catalog = this.props.catalog;

        var locationControl = null;
        var menuControls = null;
        if (location) {
            if (this.props.hasMultipleLocations) {
                var address = location.Name || location.Address.AddressLine1;
                locationControl = (
                    <div className="location">
                        <span>Your Current Location</span>
                        <a href="javascript://" onClick={()=> this.onLocationClicked()} className="current-location-btn">
                            <div className="current">{address}</div>
                            <div className="button">Change It</div>
                        </a>
                    </div>                
                );
            }

            var continueShoppingLink = null;
            if (this.props.location && this.props.location.pathname != '/') {
                continueShoppingLink = (
                    <Link to="/">
                        <i className="material-icons md-24">keyboard_return</i>
                        <span>Continue Shopping</span>
                    </Link>
                );
            }

            var myAccountLink = '/sign-in';
            if (this.props.isLoggedIn) {
                myAccountLink = '/my-account';
            }            
            var itemCount = this.props.cartCount;            
            menuControls = (
                <div className="menu">
                    {continueShoppingLink}
                    <Link to={myAccountLink}>
                        <img src={accountIcon} />
                        <span>My Account</span>
                    </Link>
                    <Link to="/cart">
                        <img src={cartIcon} />
                        <span>Bag</span>
                        <span className="items">{itemCount}</span>
                    </Link>
                </div>
            );
         }

         var companyLogo = global.imagesPath + 'logo.png';         

         var logoAltText = '';
         if (catalog) {
             logoAltText = catalog.Name;
         }

        return (
            <header>
                <div className="container">
                    <div className="logo"><Link to="/"><img src={companyLogo} alt={logoAltText} className="logo" /></Link></div>
                    {locationControl}
                    {menuControls}
                </div>
            </header>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        initialized: state.api.initialized,
        currentLocation: state.location.currentLocation,
        hasMultipleLocations: state.location.locations.length > 1,
        cartCount: state.cart.count,
        catalog: state.catalog.data,
        isLoggedIn: state.auth.isLoggedIn
    }
};

const mapDispatchToProps = (dispatch) => ({
    openLocationModal: () => {
        dispatch({type: 'LOCATION_CHOOSER'});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);