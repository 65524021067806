import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Validation, ApiService, CardService, Internationalization, CustomStorage } from '../lib';
import async from 'async';
import { Hero, EmailField, PasswordField } from '../components';

const ValidationRules = [
    {
        input: 'email',
        output: 'emailErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: "Email Address is required"
            },
            {
                rule: Validation.rules.email,
                message: "Email Address is not valid"
            }
        ]
    },
    {
        input: 'password',
        output: 'passwordErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: "Password is required"
            }
        ]
    }    
];

class SignInScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            emailErrorMessage: null,
            password: null,
            passwordErrorMessage: null,
            loginPending: false
        };
    }

    onLoginClicked() {
        var isValid = Validation.isValid(this.state, ValidationRules, (newState) => this.setState(newState));
        if (!isValid) {
            return;
        }

        CustomStorage.get('returnUrl').then((returnUrl) => {
            this.setState({loginPending: true});
            this.props.login(this.state.email, this.state.password, this.props.localFavorites)
            .then(() => {
                this.setState({loginPending: false});
                this.props.history.push(returnUrl || '/my-account');
            })
            .catch((reason) => {
                this.setState({loginPending: false});
                this.props.toast.error('Invalid email address or password!');
            });        
        })
        
    }

    onEmailTextChanged(e) {
        this.setState({email: e.target.value});
    }

    onPasswordTextChanged(e) {
        this.setState({password: e.target.value});
    }

    render() {
        return (
            <div className="account">
                <Hero message="Welcome back, please login." />
                <div className="body">
                    <div className="container">
                    {!this.state.loginPending && this.renderForm()}
                        {this.state.loginPending && this.renderPending()}                    
                    </div>
                </div>
            </div>
        );
    }

    renderForm() {
        return (
            <div>
                <div className="col-half center-block">
                    <div className="account-status-buttons tabs">
                        <a href="javascript://" id="tab1" className="active">Already a customer</a>
                        <Link to='/sign-up' id="tab2">I'm a new customer</Link>
                    </div>
                </div>
                <div id="tab1_block" className="form col-half center-block current-customer-form tab-block active">
                    <div className="input full-size">
                        <EmailField 
                            name="email"
                            placeholder="Email Address" 
                            onChange={(e) => this.onEmailTextChanged(e)} 
                            onEnterPress={() => this.onLoginClicked()}
                            errorMessage={this.state.emailErrorMessage} />
                    </div>
                    <div className="input full-size">
                        <PasswordField 
                            name="password"
                            placeholder="Password"
                            onChange={(e) => this.onPasswordTextChanged(e)}
                            onEnterPress={() => this.onLoginClicked()}
                            errorMessage={this.state.passwordErrorMessage} />
                        <div className="additional right"><Link to="/forgot-password">Forgot Password?</Link></div>
                    </div>
                    <div className="button full-size">
                        <button className="btn btn-primary" onClick={()=>this.onLoginClicked()}>Login</button>
                    </div>
                </div>
            </div>
        );
    }

    renderPending() {
        return (
            <div className="form col-half center-block text-center">
                <i className="fas fa-spinner fa-pulse fa-5x"></i>
            </div>
        );
    }    
}

const mapStateToProps = (state) => {
    return {
        localFavorites: state.favorites.items
    }
};

const mapDispatchToProps = (dispatch) => ({
    login: (username, password, localFavorites) => {
        dispatch({ type: 'AUTH_LOGIN_PENDING' });
        return new Promise((resolve, reject) => {
            ApiService.instance.login({
                username: username,
                password: password
            }, (error, data, response) => {
                if (error) {
                    dispatch({type: 'AUTH_LOGIN_COMPLETED', isLoggedIn: false });
                    reject("Invalid email address or password!");
                } else {
                    async.parallel({
                        authStatus: (cb) => ApiService.instance.accountStatus({}, (e, data, response) => {
                            dispatch({type: 'AUTH_STATUS', data: data });
                            cb(null, true);
                        }),                        
                        orders: (cb) => ApiService.instance.orderList({}, (e, data, response)=> {
                            dispatch({type: 'ORDERS_INITIALIZED', data: data });
                            cb(null, true);
                        }),
                        /*
                        mergeFavorites: (cb) => ApiService.instance.favoriteMerge({items: localFavorites}, (e, data, response) => {
                            dispatch({type: 'FAVORITES_INITIALIZED', data: data });
                            cb(null, true);
                        }),
                        */
                        loyalty: (cb) => ApiService.instance.loyaltyStatus({}, (e, data, response) => {
                            dispatch({type: 'LOYALTY_INITIALIZED', data: data })
                            cb(null, true);
                        }),
                        cards: (cb) => CardService.instance.mergeInit((data, defaultCard) => {
                            dispatch({type: 'CARDS_INITIALIZED', cards: [], defaultCard: defaultCard, remote: data });
                            cb(null, true);
                        }),
                    }, (err, results) => {
                        dispatch({type: 'AUTH_LOGIN_COMPLETED', isLoggedIn: true });
                        resolve(true);
                    });
                }
            });
        });    
    }
});


export default connect(mapStateToProps, mapDispatchToProps)(SignInScreen);