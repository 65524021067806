import { CurrencyFormatter } from '../';

const initialState = {
    itemsById: {},
    variationMap: null,
    minVariation: null,
    maxVariation: null,
    detail: null,
    selectedVariation: null,
    selectedModifiers: null,
    quantity: 0,
    note: null,
    estimatedCost: 0,
    isEditMode: false,
    currency: null,    
    modifierListMap: null,
    modifierMap: {},
    initial: {
        selectedVariation: null,
        selectedModifiers: null,
        note: null,
        key: null          
    }       
};

function calculateEstimatedCost(state) {
    var variation = state.selectedVariation;
    var modifiers = state.selectedModifiers;
    var quantity = state.quantity;

    var modifiersCost = 0;
    var modifierIds = [].concat.apply([],Object.values(modifiers));
    var modifierMap = state.modifierMap;
    modifierIds.forEach((modifierId) => {
        var modifier = modifierMap[modifierId];
        if (modifier)
            modifiersCost += modifier.Price || 0;
    });

    if (variation) {
        var price = (modifiersCost + (state.variationMap[variation].Price || 0)) * quantity;
        return CurrencyFormatter.format(price, state.currency);
    } else {
        var min = (state.minVariation + modifiersCost) * quantity;
        var max = (state.maxVariation + modifiersCost) * quantity;
        return CurrencyFormatter.format(min, state.currency) + ' - ' + CurrencyFormatter.format(max, state.currency);
    }        
}

function transformSelectedCartItem(state, entry, isEditMode) {
    var item = entry.item;
    if (isEditMode) {
        // transform item from id to actual item
        item = state.itemsById[item];
    }
    
    var quantity = entry.quantity;
    var selectedVariation = entry.variation;
    var note = entry.note;
    var selectedModifiers = entry.modifiers;
    var key = entry.key;

    if (item && item.Variations && item.Variations.length == 1) {
        selectedVariation = item.Variations[0].Id;
    }    

    var variationMap = {};
    var minVariation = null;
    var maxVariation = null;
    for(var i=0;i<item.Variations.length;i++){
        var variation = item.Variations[i];
        variationMap[variation.Id] = variation;
        var price = variation.Price;
        if (minVariation == null || price < minVariation ) {
            minVariation = price;
        }
        if (maxVariation == null || price > maxVariation ) {
            maxVariation = price;
        }
    }

    return {
        ...state,
        variationMap: variationMap,
        minVariation: minVariation,
        maxVariation: maxVariation,
        detail: item,
        selectedVariation: selectedVariation,
        selectedModifiers: selectedModifiers,
        quantity: quantity,
        note: note,
        isEditMode: isEditMode,
        initial: {
            selectedVariation: selectedVariation,
            selectedModifiers: selectedModifiers,
            note: note,
            key: key            
        }        
    };
}

function transformSelectedItem(state, item, variation) {
    var entry = {
        item: item,
        quantity: 1,
        variation: variation || null,
        note: null,
        modifiers: {},
        key: null
    };

    return transformSelectedCartItem(state, entry, false);
}

function handleSelectModifiers(state, modifierList, selectedModifiers) {
    var id = modifierList.Id;
    var x = {
        ...state,
        selectedModifiers: {
            ...state.selectedModifiers,
        }
    };
    x.selectedModifiers[id] = selectedModifiers;
    return x;
}

function handleSelectVariation(state, selectedVariation) {
    return {
        ...state,
        selectedVariation: selectedVariation,
    };
}

function handleSetQuantity(state, quantity) {
    return {
        ...state,
        quantity: quantity,
    };    
}

function handleSetNote(state, note) {
    return {
        ...state,
        note: note
    };
}

function transformModifierListMap(location) {
    if (location) {
        return new Map(location.ModifierLists.map((x) => [x.Id, x]));    
    }
    return new Map();
}

function transformModifierMap(location) {
    var modifierMap = {};

    if (location) {
        location.ModifierLists.forEach((list)=> {
            list.Modifiers.forEach((modifier) => {
                modifierMap[modifier.Id] = modifier;
            });
        });        
}

    return modifierMap;
}

function transformItemsById(location) {
    if (!location) {
        return {};
    }

    var datasource = {};

    var categories = location.Categories;    
    for(var i=0;i<categories.length;i++) {
        var category = categories[i];
        if (!category.Items || category.Items.length == 0)
            continue; 
        for (var j=0;j<category.Items.length;j++) {
            var item = category.Items[j];
            datasource[item.Id] = item;
        }
    }

    return datasource;
}

function handleLocationSelected(state, location) {
    return {
        ...state,
        currency: location.Currency,
        modifierListMap: transformModifierListMap(location),
        modifierMap: transformModifierMap(location),
        itemsById: transformItemsById(location)
    };
}

function adjustEstimatedCost(state) {
    state.estimatedCost = calculateEstimatedCost(state);
    return state;
}

export default function selectedItem(state, action) {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'LOCATION_SELECTED':
            return handleLocationSelected(state, action.location);
        case 'CATALOG_SELECT_ITEM':
            return adjustEstimatedCost(transformSelectedItem(state, action.item, action.variation));
        case 'CATALOG_SELECT_CART_ITEM':
            return adjustEstimatedCost(transformSelectedCartItem(state, action.cartItem, true));
        case 'CATALOG_SELECT_MODIFIERS':
            return adjustEstimatedCost(handleSelectModifiers(state, action.modifierList, action.selectedModifiers));
        case 'CATALOG_SELECT_VARIATION':
            return adjustEstimatedCost(handleSelectVariation(state, action.selectedVariation));
        case 'CATALOG_SET_QUANTITY':
            return adjustEstimatedCost(handleSetQuantity(state, action.quantity));            
        case 'CATALOG_SET_NOTE':
            return handleSetNote(state, action.note);
        default:
            return state;
    }  
}