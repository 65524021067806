const initialState = {
    locations: [],
    currentLocation: null,
    modalVisible: false,
    selectedLocationId: null
};

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

function mockAddress(i) {
    return {
        Id: i.toString(),
        Address: {
            "AddressLine1": "123-" + i + " Main St",
            "AddressLine2": null,
            "AddressLine3": null,
            "Locality": "Anytown",
            "Sublocality1": null,
            "Sublocality2": null,
            "Sublocality3": null,
            "AdministrativeDistrictLevel1": "GA",
            "AdministrativeDistrictLevel2": null,
            "AdministrativeDistrictLevel3": null,
            "PostalCode": "30028",
            "Country": "US",
            Latitude: getRandomArbitrary(-90, 90),
            Longitude: getRandomArbitrary(-180, 180)
        },
        Timezone: "America/New_York",
        Categories: [],
        Taxes: [],
        ModifierLists: [],
        Name: "Anytown #" + i,
        Contacts: [],
        Hours: []
    }
}

export default function location(state, action) {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'LOCATION_INITIALIZED':
            var currentLocation = null;
            var selectedLocationId = null;
            if (state.currentLocation) {
                for(var i=0;i<action.locations.length;i++) {
                    var location = action.locations[i];
                    if (location.Id == state.currentLocation.Id) {
                        selectedLocationId = location.Id;
                        currentLocation = location;
                        break;
                    }
                }
            }
            return {
                // locations: [...action.locations, mockAddress(1), mockAddress(2), mockAddress(3), mockAddress(4), mockAddress(5)],
                locations: action.locations,
                currentLocation: currentLocation,
                selectedLocationId: selectedLocationId
            };
        case 'LOCATION_CHOOSER':
            return {
                ...state,
                modalVisible: true
            };
        case 'LOCATION_CLOSE_CHOOSER':
            return {
                ...state,
                modalVisible: false
            };			
        case 'LOCATION_SELECTED':
            return {
                ...state,
                currentLocation: action.location,
                selectedLocationId: action.location ? action.location.Id : null,
                modalVisible: false
            }
        default:
            return state;
    }  
}