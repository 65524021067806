const initialState = {
    items: [],
    remote: []
};

export default function favorites(state, action) {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'AUTH_SIGNED_OUT':
            return {
                ...state,
                remote: []
            };
        case 'FAVORITES_ADD':
            var id = action.item;
            return {
                ...state,
                items: [...state.items, id]
            }; 
            case 'FAVORITES_ADD_REMOTE':
            var id = action.item;
            return {
                ...state,
                remote: [...state.remote, id]
            };
        case 'FAVORITES_INITIALIZED':
            return {
                ...state,
                items: [],
                remote: action.data.Items
            };
        case 'FAVORITES_REMOVE':
            var id = action.item;
            var items = state.items.filter((listItem)=>{ return listItem != id });
            return {
                ...state,
                items: items
            };
            case 'FAVORITES_REMOVE_REMOTE':
            var id = action.item;
            var remote = state.remote.filter((listItem)=>{ return listItem != id });
            return {
                ...state,
                remote: remote
            };            
        default:
            return state;
    }  
}