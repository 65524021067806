import React from 'react';
import { default as TextField } from './textField';

export default class PhoneField extends React.Component {
    render() {
        var props = {
            ...this.props,
            type: "tel",
            pattern: '\d{3}[\-]\d{3}[\-]\d{4}'
        };

        return (<TextField {...props} />);
    }
}