import React from 'react';
import { connect } from 'react-redux';
import { ApiService, CardService } from '../lib';
import { AccountHeader } from '../components';

class SavedCardsScreen extends React.Component {
    getCardIcon(brand) {
        if (!brand) {
            return 'credit-card';
        }
        
        switch(brand.toLowerCase()) {
            case 'americanexpress':
            case 'american_express':
            case 'amex':            
                return 'cc-amex';
            case 'discover':
                return 'cc-discover';
            case 'discoverdiners':
                return 'cc-diners-club';
            case 'jcb':
                return 'cc-jcb';
            case 'mastercard':
                return 'cc-mastercard';
            case 'visa':
                return 'cc-visa';
            case 'unionpay':
            default:
                return 'credit-card';
        }
    }

    onCardListRemoveClicked(card) {
        this.props.removeCard(card, this.props.isLoggedIn);
    }

    renderCard(card) {
        var cardIcon = this.getCardIcon(card.brand);
        var label = '************' + card.last4;

        var cardClasses = "fab fa-fw fa-3x fa-" + cardIcon;

        return (
            <div className="row">
                <div className="card-type"><i className={cardClasses}></i></div>
                <div className="name">{label} <small>Exp {card.expMonth}/{card.expYear}</small></div>
                <div><a href="javascript://" className="btn btn-inline btn-primary" onClick={() => {this.onCardListRemoveClicked(card)}}><span>Remove Card</span></a></div>
            </div>
        )
    }

    render() {

        return (
            <div className="my-acct-saved-cards">
                <AccountHeader />
                <div className="body my-account-pages">
                    <div className="container">
                        <div className="card-grid">
                            {this.props.cards.length == 0 && <h2>You don't have any saved cards.</h2>}
                            {this.props.cards.map((card) => this.renderCard(card))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        cards: state.cards.cards,
    }
};

const mapDispatchToProps = (dispatch) => ({
    removeCard: (card, isLoggedIn) => {
        if (card.id) {
            ApiService.instance.cardRemove({ cardId: card.id }, (error, data, response) => { });
        }
        
        if (isLoggedIn) {
            // this keeps the secure storage in sync with the redux state            
            CardService.instance.remove(card);
        }
        dispatch({type: 'CARDS_REMOVE', card: card});
    },   
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedCardsScreen);