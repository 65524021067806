const initialState = {
    cards: [],
    defaultCard: null,
    transientCard: null,
    selectedCard: null,
    pendingCard: null,
    saveModalVisible: false
};

function transformRemoteCards(remote) {
    if (!remote) {
        return [];
    }

    if (!remote.Cards) {
        return [];
    }

    return remote.Cards.map(remoteCard => {
        return {
            nonce: null,
            id: remoteCard.Id,
            brand: remoteCard.Brand,
            last4: remoteCard.Last4,
            expMonth: remoteCard.ExpirationMonth,
            expYear: remoteCard.ExpirationYear,
            address: {
                address1: remoteCard.Address.AddressLine1,
                address2: remoteCard.Address.AddressLine2,
                city: remoteCard.Address.Locality,
                state: remoteCard.Address.AdministrativeDistrictLevel1,
                postalCode: remoteCard.Address.PostalCode,
                country: remoteCard.Address.Country
            }
        };        
    });
}
  
export default function cards(state, action) {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'CARDS_INITIALIZED':
            var cards = action.cards ? [...action.cards, ...transformRemoteCards(action.remote)] : null;
            var defaultCard = action.defaultCard ? {...action.defaultCard} : null;        
            var selectedCard = action.defaultCard ? {...action.defaultCard} : null;
            return {
                cards: cards,
                defaultCard: defaultCard,
                transientCard: null,
                selectedCard: selectedCard,
                pendingCard: null,
                saveModalVisible: false
            };        
        case 'CARDS_PENDING':
            return {
                ...state,
                pendingCard: action.card,
                saveModalVisible: false
            };
        case 'CARDS_REGISTERED':
            if (action.remember) {
                return {
                    ...state,
                    cards: [...state.cards, action.card],
                    selectedCard: action.card,
                    defaultCard: action.card,
                    pendingCard: null,
                    saveModalVisible: false
                }            
            } else {
                return {
                    ...state,
                    transientCard: action.card,
                    selectedCard: action.card,
                    pendingCard: null,
                    saveModalVisible: false
                };
            }
        case 'CARDS_SELECT':
            return {
                ...state,
                selectedCard: action.card,
                defaultCard: action.card.id ? action.card : state.defaultCard,
                pendingCard: null,
                saveModalVisible: false
            };
        case 'CARDS_REMOVE':
            var card = action.card;

            var cards = state.cards.filter((listItem)=>{ return listItem.id != card.id });

            var selectedCard = state.selectedCard;

            if (selectedCard && ((card.id && selectedCard.id && card.id == selectedCard.id) || (card.nonce && selectedCard.nonce && card.nonce == selectedCard.nonce)))
                selectedCard = null;

            var defaultCard = state.defaultCard;

            if (defaultCard && card.id && defaultCard.id == card.id)
                defaultCard = cards.length > 0 ? cards[0] : null;

            var transientCard = state.transientCard;

            if (transientCard && card.nonce && transientCard.nonce == card.nonce)
                transientCard = null;

            return {
                ...state,
                cards: cards,
                selectedCard: selectedCard,
                defaultCard: defaultCard,
                transientCard: transientCard,
                pendingCard: null,
                saveModalVisible: false
            };
        case 'CARDS_SHOW_SAVE_CONFIRMATION':
            return {
                ...state,
                saveModalVisible: true
            };
        case 'CARDS_HIDE_SAVE_CONFIRMATION':
            return {
                ...state,
                saveModalVisible: false
            };
        default:
            return state;
    }  
}