const initialState = {
    autoLogin: {
        initialized: false,
        initializing: false
    },
    login: {
        pending: false
    },
    profile: {
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        accessToken: null,
        refreshToken: null,
        expires: null
    },
    notifications: {
        optIn: null
    },
    isLoggedIn: false,
	isVerified: false
};

export default function auth(state, action) {
    if (state === undefined) {
        return initialState;
    }
    switch(action.type) {
        case 'AUTH_AUTOLOGIN_INITIALIZING':
            return {
                autoLogin: {
                    initialized: false,
                    initializing: true,
                },
                notifications: {
                    optIn: null
                },                
                isLoggedIn: false,
				isVerified: false
            };  
        case 'AUTH_AUTOLOGIN_INITIALIZED':
            return {
                autoLogin: {
                    initialized: true,
                    initializing: false
                },
                notifications: {
                    optIn: null
                },                
                isLoggedIn: action.isLoggedIn,
				isVerified: false
            };          
        case 'AUTH_LOGIN_PENDING':
            return {
                login: {
                    pending: true
                },
                notifications: {
                    optIn: null
                },                
                isLoggedIn: false,
				isVerified: false
            };  
        case 'AUTH_LOGIN_COMPLETED':
            if (action.isLoggedIn) {
                return {
                    login: {
                        pending: false
                    },
                    notifications: {
                        optIn: state.notifications.optIn
                    },                
                    isLoggedIn: true,
                    isVerified: state.isVerified || false
                };     
            } else {
                return {
                    login: {
                        pending: false
                    },
                    notifications: {
                        optIn: null
                    },                
                    isLoggedIn: false,
                    isVerified: false
                };                     
            }
        case 'AUTH_SIGNED_OUT':
            return {
                isLoggedIn: false,
                notifications: {
                    optIn: null
                }
            };
		case 'AUTH_STATUS':
			return {
				...state,
                isVerified: action.data.Verified,
                notifications: {
                    optIn: action.data.TextNotificationOptIn
                }
			};
    }
    return state;
}