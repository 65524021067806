import React from 'react';
import { connect } from 'react-redux';
import { Validation } from '../lib';
import { RadioButton, TextField } from '../components';

const ValidationRulesPickup = [];

const ValidationRulesCurbside = [
    {
        input: 'vehicleMake',
        output: 'vehicleMakeErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: 'Vehicle Make is required'
            }      
        ]
    },
    {
        input: 'vehicleModel',
        output: 'vehicleModelErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: 'Vehicle Model is required'
            }      
        ]
    },
    {
        input: 'vehicleColor',
        output: 'vehicleColorErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: 'Vehicle Color is required'
            }      
        ]
    },
];

class FulfillmentForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'edit',   // 'edit' | 'curbside' | 'pickup' | 'display'

            vehicleMake: null,
            vehicleModel: null,
            vehicleColor: null,
            curbsideNotes: null,
            pickupNotes: null,
            fulfillmentType: null,
            vehicleMakeErrorMessage: null,
            vehicleModelErrorMessage: null,
            vehicleColorErrorMessage: null,

            initial: {
                vehicleMake: null,
                vehicleModel: null,
                vehicleColor: null,
                curbsideNotes: null,
                pickupNotes: null,
            }
        };
    }

    componentDidMount() {
        var stateChange = {};
        var changed = false;

        var vehicleDetails = this.props.vehicleDetails;
        if (vehicleDetails) {
            changed = true;
            stateChange = {
                ...stateChange,
                vehicleMake: vehicleDetails.make,
                vehicleModel: vehicleDetails.model,
                vehicleColor: vehicleDetails.color,
                curbsideNotes: vehicleDetails.note,   
                initial: {
                    ...stateChange.initial,
                    vehicleMake: vehicleDetails.make,
                    vehicleModel: vehicleDetails.model,
                    vehicleColor: vehicleDetails.color,
                    curbsideNotes: vehicleDetails.note,
                }
            }
        }

        var pickupSettings = this.props.pickupSettings;
        if (pickupSettings) {
            changed = true;
            stateChange = {
                ...stateChange,
                pickupNotes: pickupSettings.notes,
                initial: {
                    ...stateChange.initial,
                    pickupNotes: pickupSettings.notes
                }
            };
        }

        var fulfillmentType = this.props.fulfillmentType;
        if (fulfillmentType) {
            changed = true;
            stateChange = {
                ...stateChange,
                fulfillmentType: fulfillmentType,
            };

            if (fulfillmentType == 'PICKUP') {
                stateChange.mode = 'display';
            } else if (fulfillmentType == 'CURBSIDE') {
                var curbsideSettings = this.props.curbsideSettings;
                if (curbsideSettings && curbsideSettings.vehicleDetails &&  curbsideSettings.vehicleDetails.make && curbsideSettings.vehicleDetails.model && curbsideSettings.vehicleDetails.color) {
                    stateChange.mode = 'display';
                } else {
                    stateChange.mode = 'curbside';
                }
            }
        }

        var hasPickup = this.props.hasPickupFulfillment;
        var hasCurbside = this.props.hasCurbsideFulfillment;

        if (hasPickup && !hasCurbside) {
            changed = true;
            stateChange.fulfillmentType = 'PICKUP';
            stateChange.mode = 'pickup';
        } else if (!hasPickup && hasCurbside) {
            changed = true;
            stateChange.fulfillmentType = 'CURBSIDE';
            stateChange.mode = 'curbside';
        } else if (!hasPickup && !hasCurbside) {
            changed = true;
            stateChange.fulfillmentType = 'PICKUP';
            stateChange.mode = 'pickup';
        }

        if (changed) {
            this.setState(stateChange);
        }
    }
    
    onTextChanged(e, fieldName) {
        this.setState({[fieldName]: e.target.value});
    }

    onBackPressed() { 
        this.setState({ mode: 'edit' });
    }

    onUpdatePressed() {
        if (this.state.fulfillmentType === 'CURBSIDE') {
            var isValid = Validation.isValid(this.state, ValidationRulesCurbside, (newState) => this.setState(newState));
            if (!isValid) {
                return;
            }
    
            var vehicleDetails = {
                make: this.state.vehicleMake,
                model: this.state.vehicleModel,
                color: this.state.vehicleColor,
                note: this.state.curbsideNotes
            }
    
            this.props.selectCurbsideFulfillment(vehicleDetails);
    
            this.setState({
                ...this.state,
                mode: 'display',
                initial: {
                    vehicleMake: this.state.vehicleMake,
                    vehicleModel: this.state.vehicleModel,
                    vehicleColor: this.state.vehicleColor,
                    curbsideNotes: this.state.curbsideNotes,
                    pickupNotes: this.state.pickupNotes
                }
            });
        }
        if (this.state.fulfillmentType === 'PICKUP') {
            var isValid = Validation.isValid(this.state, ValidationRulesPickup, (newState) => this.setState(newState));
            if (!isValid) {
                return;
            }
        
            this.props.selectPickupFulfillment(this.state.pickupNotes);
    
            this.setState({
                ...this.state,
                mode: 'display',
                initial: {
                    vehicleMake: this.state.vehicleMake,
                    vehicleModel: this.state.vehicleModel,
                    vehicleColor: this.state.vehicleColor,
                    curbsideNotes: this.state.curbsideNotes,
                    pickupNotes: this.state.pickupNotes
                }
            });
        }
        
    }

    onCurbsideNoteChanged(e) {
        this.setState({curbsideNotes:e.currentTarget.value});      
    }

    onPickupNoteChanged(e) {
        this.setState({pickupNotes:e.currentTarget.value});
    }

    renderBackToPickerButton() {
        if (this.props.hasCurbsideFulfillment && this.props.hasPickupFulfillment) {
            return (<button className="btn btn-primary" onClick={()=>this.onBackPressed()}>Back</button>);
        }
        return null;
    }
 
    renderCurbsideEdit() {
        var note = this.state.curbsideNotes || this.state.initial.curbsideNotes || '';
        return (
            <div>
                <section className="curbside-form">
                    <h3>Vehicle Details</h3>
                    <div className="input full-size">
                        <TextField 
                            name="vehicleMake"
                            value={this.state.vehicleMake}
                            placeholder="Make" 
                            defaultValue={this.state.initial.vehicleMake}
                            onChange={(e) => this.onTextChanged(e, 'vehicleMake')} 
                            onEnterPress={()=>this.onUpdatePressed()}
                            errorMessage={this.state.vehicleMakeErrorMessage} />
                    </div>
                    <div className="input full-size">
                        <TextField 
                            name="vehicleModel"
                            placeholder="Model" 
                            value={this.state.vehicleModel}
                            defaultValue={this.state.initial.vehicleModel}
                            onChange={(e) => this.onTextChanged(e, 'vehicleModel')} 
                            onEnterPress={()=>this.onUpdatePressed()}
                            errorMessage={this.state.vehicleModelErrorMessage} />
                    </div>
                    <div className="input full-size">
                        <TextField 
                            name="vehicleColor"
                            placeholder="Color" 
                            value={this.state.vehicleColor}
                            defaultValue={this.state.initial.vehicleColor}
                            onChange={(e) => this.onTextChanged(e, 'vehicleColor')} 
                            onEnterPress={()=>this.onUpdatePressed()}
                            errorMessage={this.state.vehicleColorErrorMessage} />
                    </div>
                    <div className="input full-size">
                        <textarea maxLength={150} value={note} onChange={(text) => this.onCurbsideNoteChanged(text)} placeholder="Please type any extra notes you may have..."></textarea>
                    </div>
                    <div className="fields-side-by-side">
                        {this.renderBackToPickerButton()}
                        <button className="btn btn-primary" onClick={()=>this.onUpdatePressed()}>Update</button>
                    </div>
                </section>
            </div>
        );
    }

    renderPickupEdit() {
        var note = this.state.pickupNotes || this.state.initial.pickupNotes || '';
        return (
            <div>
                <section className="curbside-form">
                    <h3>Pickup Additional Notes</h3>
                        <div className="input full-size">
                        <textarea maxLength={150} value={note} onChange={(text) => this.onPickupNoteChanged(text)} placeholder="Please type any extra notes you may have..."></textarea>
                    </div>
                    <div className="fields-side-by-side">
                        {this.renderBackToPickerButton()}
                        <button className="btn btn-primary" onClick={()=>this.onUpdatePressed()}>Update</button>
                    </div>
                </section>
            </div>
        );
    }

    onChangeClicked() {
        this.setState({ mode: this.state.fulfillmentType.toLowerCase() });
    }

    renderPickupDisplay() {
        var notes = this.state.pickupNotes;
        if (!notes) {
            notes = (<em>(None)</em>);
        }
        return (
            <section className="curbSideForm">
                <h3>Pickup Additional Notes</h3>
                <div className="guest-profile">
                    <div className="detail">
                        <p>{notes}</p>
                    </div>
                    <div><a href="javascript://" className="btn btn-inline btn-primary change-it-btn" onClick={() =>{this.onChangeClicked()}}><span>Change it</span></a></div>
                </div>
            </section>
        );
    }

    renderCurbsideDisplay() {
        var notes = this.state.curbsideNotes;
        if (!notes) {
            notes = (<em>(None)</em>);
        }        
        return (
            <section className="curbSideForm">
                <h3>Vehicle Details</h3>
                <div className="guest-profile">
                    <div className="detail">
                        <h4>Vehicle Info</h4>    
                        <p>{this.state.vehicleColor} {this.state.vehicleMake} {this.state.vehicleModel} </p>   
                        <h4>Additional Notes</h4>                 
                        <p>{notes}</p>
                    </div>
                    <div><a href="javascript://" className="btn btn-inline btn-primary change-it-btn" onClick={() =>{this.onChangeClicked()}}><span>Change it</span></a></div>
                </div>
            </section>
        );
    }
    renderDisplayMode() {
        if (this.state.fulfillmentType === 'CURBSIDE') {
            return this.renderCurbsideDisplay();
        }
        if (this.state.fulfillmentType === 'PICKUP') {
            return this.renderPickupDisplay();
        }
        if (!this.state.fulfillmentType) {
            return this.renderPicker();
        }
        return null;
    }

    onPickupChange(e,o) {
        this.setState({ fulfillmentType: 'PICKUP' });
    }
    
    onCurbsideChange(e,o) {
        this.setState({ fulfillmentType: 'CURBSIDE' });      
    }

    onPickerContinueClicked() {
        this.setState({ mode: this.state.fulfillmentType.toLowerCase() });
    }

    renderPickupField(e) {
        if (!this.props.hasPickupFulfillment) {
            return null;
        }
        var isChecked = this.state.fulfillmentType === "PICKUP";
        return (
            <RadioButton value='pickup' label='Pickup' checked={isChecked} onSelect={() => this.onPickupChange()} />
        );
    }
    
    renderCurbsideField() {
        if (!this.props.hasCurbsideFulfillment) {
            return null;
        }
        var isChecked = this.state.fulfillmentType === "CURBSIDE";
        return (
            <RadioButton value='curbside' label='Curbside' checked={isChecked} onSelect={() => this.onCurbsideChange()} />
        );
    }

    renderPicker() {
        return (
            <section className="pickup-date-and-time">
                <h3>Would you like pickup or curbside?</h3>
              
                <div className='curbside-form'>
                <div className="input full-size">
                    {this.renderPickupField()}
                </div>
               
                <div className="input full-size">
                    {this.renderCurbsideField()}
                </div>

               <div className="button full-size">
                    <button className="btn btn-primary" onClick={() => this.onPickerContinueClicked()}>Continue</button>
                </div>
            </div>
            </section>
        );
    }

    render() {
        if (!this.props.hasPickupFulfillment && !this.props.hasCurbsideFulfillment) {
            return null;
        }

        if (this.state.mode == 'edit') {
            return this.renderPicker();
        } else if (this.state.mode == 'curbside') {
            return this.renderCurbsideEdit();
        } else if (this.state.mode == 'pickup') {
            return this.renderPickupEdit();
        } else {
            return this.renderDisplayMode();
        }
    }    
}
const mapStateToProps = (state) => {
    var location = state.location.currentLocation;

    var hasPickupFulfillment= false;
    var hasCurbsideFulfillment = false;
    var hasDeliveryFulfillment = false;

    if (location && location.Fulfillments) {
        var pickup = location.Fulfillments.Pickup || {Enabled: false};
        var curbside = location.Fulfillments.Curbside || {Enabled: false};
        var delivery = location.Fulfillments.Delivery || {Enabled: false};

        if (pickup.Enabled) {
            hasPickupFulfillment = true;
        }
        if (curbside.Enabled) {
            hasCurbsideFulfillment = true;
        }
        if (delivery.Enabled) {
            hasDeliveryFulfillment = true;
        }
    }

    if (!hasPickupFulfillment && !hasCurbsideFulfillment) {
        hasPickupFulfillment = true;
    }

    return {
        hasPickupFulfillment: hasPickupFulfillment,
        hasCurbsideFulfillment: hasCurbsideFulfillment,
        hasDeliveryFulfillment: hasDeliveryFulfillment,

        vehicleDetails: state.curbside.vehicleDetails,
        fulfillmentType: state.cart.fulfillmentType,
        pickupSettings: state.cart.pickupSettings || {},
        curbsideSettings: state.cart.curbsideSettings || {},
    };
};

const mapDispatchToProps = (dispatch) => ({
    selectCurbsideFulfillment: (vehicleDetails) => {
        dispatch({type: 'CURBSIDE_SET_VEHICLE_DETAILS', vehicleDetails: vehicleDetails});
        dispatch({type: 'CART_SET_CURBSIDE_FULFILLMENT', vehicleDetails: vehicleDetails});
    },
    selectPickupFulfillment: (notes) => {
        dispatch({type: 'CART_SET_PICKUP_FULFILLMENT'});
        dispatch({type: 'SET_PICKUP_SETTINGS_NOTE', notes: notes});
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(FulfillmentForm);