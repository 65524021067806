/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import LoyaltyCouponModel from './LoyaltyCouponModel';
import LoyaltyRewardModel from './LoyaltyRewardModel';





/**
* The LoyaltyVerifyResultModel model module.
* @module model/LoyaltyVerifyResultModel
* @version 1.0.0
*/
export default class LoyaltyVerifyResultModel {
    /**
    * Constructs a new <code>LoyaltyVerifyResultModel</code>.
    * @alias module:model/LoyaltyVerifyResultModel
    * @class
    * @param balance {Number} 
    */

    constructor(balance) {
        

        
        

        this['Balance'] = balance;

        
    }

    /**
    * Constructs a <code>LoyaltyVerifyResultModel</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/LoyaltyVerifyResultModel} obj Optional instance to populate.
    * @return {module:model/LoyaltyVerifyResultModel} The populated <code>LoyaltyVerifyResultModel</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LoyaltyVerifyResultModel();

            
            
            

            if (data.hasOwnProperty('Balance')) {
                obj['Balance'] = ApiClient.convertToType(data['Balance'], 'Number');
            }
            if (data.hasOwnProperty('Rewards')) {
                obj['Rewards'] = ApiClient.convertToType(data['Rewards'], [LoyaltyRewardModel]);
            }
            if (data.hasOwnProperty('Coupons')) {
                obj['Coupons'] = ApiClient.convertToType(data['Coupons'], [LoyaltyCouponModel]);
            }
        }
        return obj;
    }

    /**
    * @member {Number} Balance
    */
    Balance = undefined;
    /**
    * @member {Array.<module:model/LoyaltyRewardModel>} Rewards
    */
    Rewards = undefined;
    /**
    * @member {Array.<module:model/LoyaltyCouponModel>} Coupons
    */
    Coupons = undefined;








}


