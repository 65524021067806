/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CurbsideSettings from './CurbsideSettings';
import DeliverySettings from './DeliverySettings';
import PickupSettings from './PickupSettings';





/**
* The FulfillmentSettings model module.
* @module model/FulfillmentSettings
* @version 1.0.0
*/
export default class FulfillmentSettings {
    /**
    * Constructs a new <code>FulfillmentSettings</code>.
    * @alias module:model/FulfillmentSettings
    * @class
    */

    constructor() {
        

        
        

        

        
    }

    /**
    * Constructs a <code>FulfillmentSettings</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/FulfillmentSettings} obj Optional instance to populate.
    * @return {module:model/FulfillmentSettings} The populated <code>FulfillmentSettings</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FulfillmentSettings();

            
            
            

            if (data.hasOwnProperty('Pickup')) {
                obj['Pickup'] = PickupSettings.constructFromObject(data['Pickup']);
            }
            if (data.hasOwnProperty('Curbside')) {
                obj['Curbside'] = CurbsideSettings.constructFromObject(data['Curbside']);
            }
            if (data.hasOwnProperty('Delivery')) {
                obj['Delivery'] = DeliverySettings.constructFromObject(data['Delivery']);
            }
        }
        return obj;
    }

    /**
    * @member {module:model/PickupSettings} Pickup
    */
    Pickup = undefined;
    /**
    * @member {module:model/CurbsideSettings} Curbside
    */
    Curbside = undefined;
    /**
    * @member {module:model/DeliverySettings} Delivery
    */
    Delivery = undefined;








}


