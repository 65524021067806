/**
 * PoppinPay API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ChangePasswordBindingModel from '../model/ChangePasswordBindingModel';
import ForgotPasswordModel from '../model/ForgotPasswordModel';
import InlineResponse200 from '../model/InlineResponse200';
import InlineResponse400 from '../model/InlineResponse400';
import ModelStateErrorCollection from '../model/ModelStateErrorCollection';
import RegisterBindingModel from '../model/RegisterBindingModel';
import ResendRegisterCodeBindingModel from '../model/ResendRegisterCodeBindingModel';
import ResetPasswordModel from '../model/ResetPasswordModel';
import StatusBindingModel from '../model/StatusBindingModel';
import StatusResultModel from '../model/StatusResultModel';
import UpdateBindingModel from '../model/UpdateBindingModel';
import VerifyRegisterModel from '../model/VerifyRegisterModel';

/**
* Account service.
* @module api/AccountApi
* @version 1.0.0
*/
export default class AccountApi {

    /**
    * Constructs a new AccountApi. 
    * @alias module:api/AccountApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the accountChangePassword operation.
     * @callback module:api/AccountApi~accountChangePasswordCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/ChangePasswordBindingModel} model 
     * @param {module:api/AccountApi~accountChangePasswordCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountChangePassword(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling accountChangePassword");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/ChangePassword', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the accountForgotPassword operation.
     * @callback module:api/AccountApi~accountForgotPasswordCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/ForgotPasswordModel} model 
     * @param {module:api/AccountApi~accountForgotPasswordCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountForgotPassword(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling accountForgotPassword");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/ForgotPassword', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the accountLogin operation.
     * @callback module:api/AccountApi~accountLoginCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse200} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.clientId 
     * @param {String} opts.grantType  (default to password)
     * @param {String} opts.username 
     * @param {String} opts.password 
     * @param {String} opts.refreshToken 
     * @param {module:api/AccountApi~accountLoginCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/InlineResponse200}
     */
    accountLogin(opts, callback) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'client_id': opts['clientId'],
        'grant_type': opts['grantType'],
        'username': opts['username'],
        'password': opts['password'],
        'refresh_token': opts['refreshToken']
      };

      let authNames = [];
      let contentTypes = ['application/x-www-form-urlencoded'];
      let accepts = ['application/json'];
      let returnType = InlineResponse200;

      return this.apiClient.callApi(
        '/api/Account/Login', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the accountRegister operation.
     * @callback module:api/AccountApi~accountRegisterCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/RegisterBindingModel} model 
     * @param {module:api/AccountApi~accountRegisterCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountRegister(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling accountRegister");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/Register', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the accountResendRegisterCode operation.
     * @callback module:api/AccountApi~accountResendRegisterCodeCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/ResendRegisterCodeBindingModel} model 
     * @param {module:api/AccountApi~accountResendRegisterCodeCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountResendRegisterCode(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling accountResendRegisterCode");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/ResendRegisterCode', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the accountResetPassword operation.
     * @callback module:api/AccountApi~accountResetPasswordCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/ResetPasswordModel} model 
     * @param {module:api/AccountApi~accountResetPasswordCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountResetPassword(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling accountResetPassword");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/ResetPassword', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the accountStatus operation.
     * @callback module:api/AccountApi~accountStatusCallback
     * @param {String} error Error message, if any.
     * @param {module:model/StatusResultModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/StatusBindingModel} model 
     * @param {module:api/AccountApi~accountStatusCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/StatusResultModel}
     */
    accountStatus(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling accountStatus");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = StatusResultModel;

      return this.apiClient.callApi(
        '/api/Account/Status', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the accountUpdate operation.
     * @callback module:api/AccountApi~accountUpdateCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/UpdateBindingModel} model 
     * @param {module:api/AccountApi~accountUpdateCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountUpdate(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling accountUpdate");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/Update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

    /**
     * Callback function to receive the result of the accountVerify operation.
     * @callback module:api/AccountApi~accountVerifyCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/VerifyRegisterModel} model 
     * @param {module:api/AccountApi~accountVerifyCallback} callback The callback function, accepting three arguments: error, data, response
     */
    accountVerify(model, callback) {
      let postBody = model;

      // verify the required parameter 'model' is set
      if (model === undefined || model === null) {
        throw new Error("Missing the required parameter 'model' when calling accountVerify");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['oauth2'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/Account/Verify', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }


}
