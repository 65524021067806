import React from 'react';
import { Internationalization } from '../lib';

export default class ErrorModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: null,
            visible: false,
            style: 'success',
            heading: null,
            closeCallback: null
        }
    }

    error(message, callback = null) {
        this.setState({visible: true, message: message, style: 'error', heading: Internationalization.strings('error_modal.error_heading'), closeCallback: callback});
    }

    success(message, callback = null) {
        this.setState({visible: true, message: message, style: 'success', heading: Internationalization.strings('error_modal.success_heading'), closeCallback: callback});
    }

    warning(message, callback = null) {
        this.setState({visible: true, message: message, style: 'warning', heading: Internationalization.strings('error_modal.warning_heading'), closeCallback: callback});
    }

    warn(message, callback = null) {
        this.setState({visible: true, message: message, style: 'warning', heading: Internationalization.strings('error_modal.warning_heading'), closeCallback: callback});
    }    

    custom(style, heading, message, callback = null) {
        this.setState({visible: true, message: message, style: style, heading: heading, closeCallback: callback});        
    }    

    closeClicked() {
        var closeCallback = this.state.closeCallback;
        this.setState({visible: false, closeCallback: null});
        if (closeCallback) {
            closeCallback();
        }
    }

    render() {
        if (!this.state.visible) {
            return null;
        }

        var backgroundColor = 'rgba(0,222,173,.90)';
        if (this.state.style == 'error') {
            backgroundColor = 'rgba(250,0,39,.90)';
        } else if (this.state.style == 'warning') {
            backgroundColor = 'rgba(255,159,0,.90)';
        }

        
        return (
        <div className="toast" style={{backgroundColor: backgroundColor}}>
            <a href="javascript://" onClick={() => this.closeClicked()} className="close"><i className="material-icons md-36">close</i></a>
            <div className="heading">{this.state.heading}</div>
            <div className="message">{this.state.message}</div>
        </div>);
    }
}