import React from 'react';
import { connect } from 'react-redux';
import { ConfigService, Internationalization, Validation, CurrencyFormatter } from '../lib';
import { default as CurrencyField } from './currencyField';

const ValidationRules = [
    {
        input: 'tip',
        output: 'tipErrorMessage',
        stopOnError: true,
        rules: [
            {
                rule: Validation.rules.notEmpty,
                message: "Tip Amount is required"
            },
            {
                rule: Validation.rules.number,
                message: "Tip Amount is not valid"
            },
            {
                rule: function(text, model, rule, context) {
                    var tipSettings = context.props.tipSettings || {};
                    var maxPercentage = tipSettings.MaximumPercentage || 25;
                    var maximumTipAmount = context.getTipByPercentage(maxPercentage);
                    var number = CurrencyFormatter.parseInput(text, context.props.currencyCode);

                    if (number > maximumTipAmount)
                        return false;

                    return true;
                },
                messageCallback: function(text, model, rule, context) {
                    var tipSettings = context.props.tipSettings || {};
                    var maxPercentage = tipSettings.MaximumPercentage || 25;
                    var maximumTipAmount = context.getTipByPercentage(maxPercentage);
                    var maxText = CurrencyFormatter.format(maximumTipAmount, context.props.currencyCode);
                    return "Tip Amount must not exceed " + maxText;
                }
            }
        ]
    }
];

class ModifyTipModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tip: null,
            tipErrorMessage: null,
            tipChanged: false
        }
    }

    onUpdatePressed() {
        if (this.state.tip == null && this.state.tipChanged == false) {
            this.props.closeModifyTipModal();
            return;
        }

        var isValid = Validation.isValid(this.state, ValidationRules, (newState) => this.setState(newState), null, this);
        if (!isValid) {
            return;
        }

        var tipAmount = CurrencyFormatter.parseInput(this.state.tip, this.props.currencyCode);
        this.props.setTip(tipAmount);
        this.setState({tip: null, tipChanged: false, tipErrorMessage: null});
        this.props.closeModifyTipModal();
    }

    onCancelPressed() {
        this.props.closeModifyTipModal();
    }

    getTipByPercentage(percentage) {
        var grandTotal = this.props.preview.grandTotal;
        var totalTips = this.props.preview.totalTips;

        var preTipTotal = grandTotal - totalTips;

        return Math.floor(preTipTotal * percentage / 100);
    }

    onTipPressed(percentage) {
        var tipAmount = this.getTipByPercentage(percentage);

        var tipText = CurrencyFormatter.formatInput(tipAmount, this.props.currencyCode);

        this.setState({tip: tipText, tipChanged: true, tipErrorMessage: null});
    }

    createTipButton(title, percentage) {
        var key = 'tipButton' + (percentage == null ? 'Custom' : percentage.toString());
        return (<button className="btn btn-inline btn-primary" key={key} onClick={() => this.onTipPressed(percentage)}>{title}</button>);
    }

    tipAmountChanged(e) {
        this.setState({tip: e.target.value, tipChanged: true});
    }

    render() {
        if (!this.props.location) {
            return null;
        }

        if(!this.props.modalVisible) {
            return null;
        }

        var defaultValue = this.props.tipAmount;
        if (defaultValue == 0 && defaultValue !== null) {
            defaultValue = '';
        } else {
            defaultValue = CurrencyFormatter.formatInput(defaultValue, this.props.currencyCode);
        }

        var tipSettings = this.props.tipSettings || {};

        var tipButtons = [];
        var tipPercentages = tipSettings.CustomPercentages || [];
        if (tipPercentages.length == 0) {
            tipPercentages = [10, 15, 20];
        }

        tipButtons.push(this.createTipButton('None', 0));

        for(var i=0;i<tipPercentages.length;i++) {
            var tipPercentage = tipPercentages[i];
            tipButtons.push(this.createTipButton(tipPercentage + '%', tipPercentage));
        }
        
        var editable = tipSettings.AllowCustomAmounts == true;

        var tipFieldValue = defaultValue;
        if (this.state.tip || this.state.tipChanged) {
            tipFieldValue = this.state.tip;
        }

        return (
            <div className="modal-overlay">
                <div className="modify-tip-modal modal" style={{display: 'inherit'}}>
                    <a href="javascript://" onClick={() => this.onCancelPressed()} className="close"><i className="material-icons md-36">close</i></a>
                    <div className="wrap">
                        <div className="inner">
                            <div className="cards form">
                                <h2>Add A Tip</h2>
                                <div className="btn-group">
                                    {tipButtons}
                                </div>
                                <div className="input full-size">
                                    <CurrencyField 
                                        name="tipAmount"
                                        placeholder="Tip Amount" 
                                        disabled={!editable}
                                        value={tipFieldValue}
                                        onChange={(e) => this.tipAmountChanged(e)} 
                                        onEnterPress={() => this.onUpdatePressed()}
                                        errorMessage={this.state.tipErrorMessage} />                                    
                                </div>
                                <div className="fields-side-by-side">
                                    <button className="btn" onClick={() =>{ this.onCancelPressed() }}>Cancel</button>
                                    <button className="btn btn-primary" onClick={() => { this.onUpdatePressed() }}>Add Tip</button>
                                </div>                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );        
    }
}

const mapStateToProps = (state) => {
    var location = state.location.currentLocation;
    var currency = location ? location.Currency : null;
    var tipSettings = location ? location.Tipping : null;
    return {
        modalVisible: state.cart.modifyTip,
        tipAmount: state.cart.tipAmount,
        location: location,
        currencyCode: currency,
        tipSettings: tipSettings,
        preview: state.cart.preview
    }
};

const mapDispatchToProps = (dispatch) => ({
    closeModifyTipModal: () => {
        dispatch({type: 'CART_TIP_HIDE_MODIFY'});
    },
    setTip: (tipAmount) => {
        dispatch({type: 'CART_SET_TIP', tipAmount: tipAmount });
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ModifyTipModal);