import { Catalog, ModifierList, ItemModifierList } from './poppin_pay_api/index';

export default class SortService {

    private ORDINAL_DEFAULT: number = Number.MAX_VALUE;

    public static instance: SortService;

    public sortCategories(categories: any[], catalog: Catalog) : any[] {
        if (!catalog.SortByOrdinal) {
            return categories;
        }

        return this.sortByOrdinalThenName(categories);
    }

    public sortItems(items: any[], catalog: Catalog): any[] {
        if (!catalog.SortByOrdinal) {
            return items;
        }

        return this.sortByOrdinalThenName(items);
    }

    public sortVariations(variations: any[], catalog: Catalog): any[] {
        if (!catalog.SortByOrdinal) {
            return this.sortByName(variations);
        }

        return this.sortByOrdinalThenName(variations);
    }

    public sortItemModifierLists(itemModifierLists: ItemModifierList[], modifierListMap: Map<string, ModifierList>,  catalog: Catalog): ItemModifierList[] {
        if (!catalog.SortByOrdinal) {
            return this.sortByName(itemModifierLists, modifierListMap);
        }

        return this.sortByOrdinalThenName(itemModifierLists, modifierListMap);
    }

    public sortModifiers(modifiers: any[], catalog: Catalog): any[] {
        if (!catalog.SortByOrdinal) {
            return this.sortByName(modifiers);
        }
        return this.sortByOrdinalThenName(modifiers);
    }

    private sortByName(objects: any[], map: Map<string, any> | null = null): any[] {
        var list = [...objects];

        list.sort((a, b) => {
            if (map) {
                a = map.get(a.Id);
                b = map.get(b.Id);
            }
            return this.compareNames(a, b);
        });

        return list;
    }

    private compareNames(a: any, b: any): number {
        var alphaName = a.Name.toUpperCase();
        var betaName = b.Name.toUpperCase();
        if (alphaName < betaName) {
            return -1;
        } else if (alphaName > betaName) {
            return 1;
        }
        return 0;
    }

    private sortByOrdinalThenName(objects: any[], map:Map<string, any> | null = null) : any[] {
        var list = [...objects];

        list.sort((a, b) => {
            if (map) {
                a = map.get(a.Id);
                b = map.get(b.Id);
            }
            var x = this.ORDINAL_DEFAULT;
            var y = this.ORDINAL_DEFAULT;

            if (a.Ordinal !== null && a.Ordinal !== undefined) {
                x = a.Ordinal;
            }
            if (b.Ordinal !== null && b.Ordinal !== undefined) {
                y = b.Ordinal;
            }

            if (x === y) {
                return this.compareNames(a, b);
            } else {
                return x - y;
            }
        });

        return list;
    }
}

SortService.instance = new SortService();