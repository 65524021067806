import React from 'react';
import { default as TextField } from './textField';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default class CurrencyField extends React.Component {
    render() {
        var props = {
            ...this.props,
            type: "text"
        };

        const numberMask = createNumberMask({
            prefix: '',
            suffix: '',
            allowDecimal: true
        });        

        return (<TextField {...props} mask={numberMask} />);
    }
}